import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/home/index.vue";
import Roll from "@/views/roll/index.vue";
import Index from "@/views/index/index.vue";
import Lucky from "@/views/lucky/index.vue";
import RankingList from "@/views/rankingList/index.vue";
import Arena from "@/views/arena/index.vue";
import Dota from "@/views/dota/index.vue";
import dome from "../components/dome.vue";
import Openbox from "@/views/openBox/index.vue";
import LuckyRoom from "../components/LuckyRoom.vue";
import Doubt from "../components/Doubt.vue";
import LuckyRule from "../components/LuckyRule.vue";
import LuckyHistory from "../components/LuckyHistory.vue";
import Ornament from "@/views/ornament/index.vue";
import OrnamentOpen from "../components/OrnamentOpen.vue";
import Bill from "@/views/bill/index.vue";
import Spread from "@/views/spread/index.vue";
import Me from "@/views/me/index.vue";
import Inform from "../components/Inform.vue";
import Vip from "../components/Vip.vue";
import Payment from "@/views/payment/index.vue";
import ArenaRoom from "@/views/arenaRoom/index.vue";
import Abouts from "../components/Abouts.vue";
import Agreement from "../components/Agreement.vue";
import Privacy from "../components/Privacy.vue";
import Clause from "../components/Clause.vue";
import OrnamentHistory from "../components/OrnamentHistory.vue";
import OrnamentHistory2 from "../components/OrnamentHistory2.vue";
import OpenboxRule from "../components/OpenboxRule.vue";
import SpreadLonger from "../components/SpreadLonger.vue";
import store from '@/store'
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    redirect: "Index",
    children: [
      {
        path: "Index",
        name: "Index",
        component: Index,
        children: [],
      },
      {
        path: "Lucky",
        name: "Lucky",
        component: Lucky,
      },
      {
        path: "RankingList",
        name: "RankingList",
        component: RankingList,
      },
      {
        path: "activity",
        name: "activity",
        component: () => import('@/views/activity/activity.vue'),
      },
      {
        path: "Arena",
        name: "Arena",
        component: Arena,
      },
      {
        path: "/Roll",
        name: "Roll",
        component: Roll,
      },
      {
        path: "/Dota",
        name: "Dota",
        component: Dota,
      },
      {
        path: "/dome",
        name: "dome",
        component: dome,
      },
      {
        path: "Openbox",
        name: "Openbox",
        component: Openbox,
      },
      {
        path: "/LuckyRoom",
        name: "LuckyRoom",
        component: LuckyRoom,
      },
      {
        path: "/Doubt",
        name: "Doubt",
        component: Doubt,
      },
      {
        path: "/LuckyRule",
        name: "LuckyRule",
        component: LuckyRule,
      },
      {
        path: "/LuckyHistory",
        name: "LuckyHistory",
        component: LuckyHistory,
      },
      {
        path: "/Ornament",
        name: "Ornament",
        component: Ornament,
      },
      {
        path: "/OrnamentOpen",
        name: "OrnamentOpen",
        component: OrnamentOpen,
      },
      {
        path: "/Bill",
        name: "Bill",
        component: Bill,
      },
      {
        path: "/Spread",
        name: "Spread",
        component: Spread,
      },
      {
        path: "/Inform",
        name: "Inform",
        component: Inform,
      },
      {
        path: "/Vip",
        name: "Vip",
        component: Vip,
      },
      {
        path: "/Me",
        name: "Me",
        component: Me,
      },
      {
        path: "/Payment",
        name: "Payment",
        component: Payment,
      },
      {
        path: "/ArenaRoom",
        name: "ArenaRoom",
        component: ArenaRoom,
      },
      {
        path: "/Abouts",
        name: "Abouts",
        component: Abouts,
      },
      {
        path: "/Agreement",
        name: "Agreement",
        component: Agreement,
      },
      {
        path: "/Privacy",
        name: "Privacy",
        component: Privacy,
      },
      {
        path: "/Clause",
        name: "Clause",
        component: Clause,
      },
      {
        path: "/OrnamentHistory",
        name: "OrnamentHistory",
        component: OrnamentHistory,
      },
      {
        path: "/OrnamentHistory2",
        name: "OrnamentHistory2",
        component: OrnamentHistory2,
      },
      {
        path: "/OpenboxRule",
        name: "OpenboxRule",
        component: OpenboxRule,
      },
      {
        path: "/SpreadLonger",
        name: "SpreadLonger",
        component: SpreadLonger,
      },
      {
        path: "/task-center",
        name: "TaskCenter",
        component: () => import("@/views/task-center_v2/index.vue"),
      },
      {
        path: "/testPage",
        name: "testPage",
        component: () => import("@/views/testPage/index.vue"),
      },
    ],
  },
  //SpreadLonger
  /*{
    path: '/about',
    name: 'About',
    component: function () {
      return import('../views/About.vue')
    }
  }  */
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  const loginStatus = localStorage.getItem('userInfo')
  const { token, expire_time } = loginStatus ? JSON.parse(loginStatus) : {}
  const now = new Date().getTime()
  console.log(token, expire_time, now);
  if (token && now > expire_time) {
    localStorage.clear()
    store.commit("getId", { name: "", id: "", img: "", money: "" });
    store.commit("loginOut");
    location.reload();
    Message.error('登录失效了,请重新登录');
  }
  next()
})

export default router;
