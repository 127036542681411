<template>
  <div class="responsive-container arena">
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
    <div class="arena-warp">
      <TabCatgoryBar
        :value="activeName"
        :onChange="handleClick"
        :tabList="tabList"
      ></TabCatgoryBar>

      <div class="tabs">
        <el-row>
          <el-col
            :xs="24"
            :sm="24"
            :md="12"
            :lg="12"
            :xl="12"
            v-for="(item, index) in currentList"
            :key="index"
          >
            <RoomCard :roomDetails="item"></RoomCard>
          </el-col>
        </el-row>
      </div>
    </div>
    <myhomebot></myhomebot>
  </div>
</template>
  <script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import navheader from "@/components/navheader.vue";
import TabCatgoryBar from "@/components/tabCatgoryBar/index.vue";

import RoomCard from "./components/roomCard/index";

const ROOM_TYPES = {
  first: "first",
  second: "second",
};

const tabList = [
  {
    label: "所有记录",
    value: ROOM_TYPES.first,
  },
  {
    label: "我的对战记录",
    value: ROOM_TYPES.second,
  },
];

export default {
  components: { myhomebot, myinform, navheader, TabCatgoryBar, RoomCard },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      activeName: "first",
      list: [],
      list1: [],
      tabList,
      ROOM_TYPES,
    };
  },
  methods: {
    handleClick(tabName) {
      this.activeName = tabName;

      if (tabName == "first") {
        this.getList();
      } else {
        this.getMyList();
      }
    },
    //免费皮肤房间列表
    getList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 50,
      };
      _this.$axios
        .post("index/Free/freeRoomList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == 1) {
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              if (this.list[i].person_num == null) {
                this.list[i].person_num = 0;
              }
              if (this.list[i].count == null) {
                this.list[i].count = 0;
              }
              if (this.list[i].pool == null) {
                this.list[i].pool = 0;
              }
            }
          }
        });
    },
    //我参与的房间列表
    getMyList() {
      let _this = this;
      let param = {
        page: 1,
        pageSize: 10,
      };
      _this.$axios
        .post("index/Free/myFreeRoom", _this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == 1) {
            this.list1 = data.data.list;
            for (let i = 0; i < this.list1.length; i++) {
              if (this.list1[i].person_num == null) {
                this.list1[i].person_num = 0;
              }
              if (this.list1[i].count == null) {
                this.list1[i].count = 0;
              }
              if (this.list1[i].pool == null) {
                this.list1[i].pool = 0;
              }
            }
          }
        });
    },
    //加入房间
    jionRoom(id) {
      if (!this.$store.state.token) {
        this.$store.commit("getLogin", true);
        return;
      }
      this.$router.push({
        path: `/ArenaRoom`,
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {
    this.getList();
  },
  computed: {
    currentList() {
      switch (this.activeName) {
        case ROOM_TYPES.first:
          return this.list;
        case ROOM_TYPES.second:
          return this.list1;
      }

      return [];
    },
  },
};
</script>
  
  <style lang="less" scoped>
</style>
  