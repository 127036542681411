<template>
  <div class="hist">
    <div class="hist-warp">
      <div class="jewelry-btn">获得饰品记录</div>

      <div class="table__out-wrapper">
        <div class="table__wrapper">
          <table class="table">
            <tr>
              <th
                class="table__th"
                v-for="item in tableConfigList"
                :key="item.prop"
              >
                {{ item.label }}
              </th>
            </tr>
            <tr
              class="table__tr"
              v-for="(itemRank, itemRankIndex) in tableData"
              :key="itemRank.id"
            >
              <td
                v-for="item in tableConfigList"
                :key="item.prop"
                class="table__tr__td"
              >
                {{ itemRank[item.prop] }}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="roll-page">
        <el-pagination
          background
          :pager-count="5"
          layout="prev, pager, next"
          :total="totalSize"
          :page-size="pageSize"
          @current-change="currentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "qs";

const tableConfigList = [
  {
    label: "时间",
    prop: "time",
  },
  {
    label: "玩家",
    prop: "name",
  },
  {
    label: "饰品名称",
    prop: "skins",
  },
  {
    label: "价格",
    prop: "price",
  },
  {
    label: "选择概率",
    prop: "probability",
  },
  {
    label: "结果",
    prop: "status",
  },
];

export default {
  data() {
    return {
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      totalSize: 0,
      tableConfigList,
    };
  },
  mounted() {
    this.getluckyhist(this.page);
  },
  methods: {
    getluckyhist(page) {
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/Lucky/LuckyHistory2", this.$qs.stringify(param))
        .then((res) => {
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.totalSize = data.data.total;
            this.tableData = data.data.list;
          }
        });
    },
    //分页 所有记录
    currentChange(val) {
      this.getluckyhist(val);
    },

    goLucky() {
      this.$router.push({
        path: `/Ornament`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.hist {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .hist-warp {
    padding: 16px;

    .jewelry-btn {
      width: 110px;
      height: 40px;

      color: var(--yellow, #f8c544);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;

      text-align: center;

      background-image: url("~@/assets/img/btn-bg-2.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }

  //页数
  .roll-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 10px 0 0 -10px;
  }
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #e9b10e;
    color: #1a1c24;
  }
  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    color: #848492;
  }
}

.table__out-wrapper {
  overflow: hidden;

  padding: 12px;
  background-color: rgba(9, 14, 20, 0.5);

  .table__wrapper {
    overflow-x: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;

    border-radius: 4px;

    .table {
      border-collapse: collapse;
      background-color: rgba(13, 20, 26, 0.5);

      th,
      td {
        border: none;
        padding: 0;
        margin: 0;

        white-space: nowrap;
      }

      .table__th {
        padding: 11px 16px;
        background-color: rgba(31, 51, 73, 0.7);
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .table__tr {
        .table__tr__td {
          padding: 11px 16px;
          color: var(--gray4, #dde5e9);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>