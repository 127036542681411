<template>
  <div class="goods-list-wrapper">
    <div class="goods-list">
      <el-row >
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          v-for="(item, index) in goodsList"
        :key="item.id"
        >
      <div
        class="goods-list__item"

        @click="buy(item)"
      >
        <div class="item__out-wrapper">
          <div class="item__out-wrapper__inner-wrapper">
            <div
              class="item__out-wrapper__inner-wrapper__content"
              :class="{
                'item__out-wrapper__inner-wrapper__content--active':
                  selectGoodsIds[item.id],
              }"
            >
              <div class="content__title">
                <div class="content__title__text">{{ item.exteriorName }}</div>
              </div>
              <div class="content__product-img">
                <img class="img-cover" :src="item.imageUrl" alt="" />
              </div>
              <div class="text-over-one content__name">{{ item.itemName }}</div>
              <div class="content__footer">
                <PriceBar
                  priceIconType="masonry"
                  :price="item.price"
                ></PriceBar>
                <div class="content__footer__inventory">
                  库存 {{ item.stock }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
      </el-row>
    </div>

    <BigBtn
      :fixedBottom="fixedBottom"
      v-if="selectedGoodsList.length > 0"
      :onClick="showConfirmBuyDialog"
    >
      <template v-slot:labelPre>
        <div class="big-btn__pre-icon">
          <img
            class="img-cover"
            src="~@/assets/img/common/masonry.png"
            alt=""
          />
        </div>
      </template>
      {{ totalSelectedGoodsPrice }}
    </BigBtn>

    <ConfirmDialog
      v-if="confirmBuyDialogConfig"
      v-bind="confirmBuyDialogConfig"
    ></ConfirmDialog>
  </div>
</template>

<script>
import currency from "currency.js";
import Utils from "@/assets/js/util.js";

import PriceBar from "@/components/priceBar/index";
import BigBtn from "@/components/bigBtn/index.vue";
import ConfirmDialog from "@/components/confirmDialog/index.vue";

export default {
  name: "prizeRecordModule",
  components: {
    PriceBar,
    BigBtn,
    ConfirmDialog,
  },
  props: {
    fixedBottom: {
      type: Boolean,
      default: true,
    },
    goodsList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectGoodsIds: {},
      confirmBuyDialogConfig: null,
    };
  },
  methods: {
    buy(goodsDetails) {


      if(goodsDetails.stock === 0) {
        this.$message({
          message: "库存不足",
          type: "warning",
        });

        return;
      }
      
      this.$set(
        this.selectGoodsIds,
        goodsDetails.id.toString(),
        !this.selectGoodsIds[goodsDetails.id]
      );
    },
    showConfirmBuyDialog() {

      let selectedGoodsInfo = [];

      if(this.selectedGoodsList.length > 0) {

        selectedGoodsInfo = this.selectedGoodsList.map((item)=>{
          return {
            id: item.id,
            price: item.price,
          }
        })
      }

      this.confirmBuyDialogConfig = {
        title: "确定购买？",
        subTitle: "确定购买这些商品吗？",
        cancelBtnConfig: {},
        okBtnConfig: {
          onOK: async () => {
            try {
              //立即购买
              let param = {
                skin_info: selectedGoodsInfo,
              };
              const res = await this.$axios.post(
                "index/Store/buynew",
                this.$qs.stringify(param)
              );
              let data = res.data;
              if (data.status == 1) {
                this.$set(this, "selectGoodsIds", {});
                this.$store.commit("getMoney", data.data.total_amount);
                Utils.$emit("masonry", data.data.total_amount);

                this.$emit('refreshData');

                this.$message({
                  message: data.msg,
                  type: "success",
                });
              } else {
                let msg = res.data.msg;
                if (msg.indexOf("余额不足") != -1) {
                  throw String("余额不足，请先充值");
                }
                if (msg.indexOf("商品库存不足") != -1) {
                  throw String("商品库存不足");
                }
                if (msg.indexOf("请联系管理员") != -1) {
                  throw String("活动用户不支持购买饰品,请联系管理员");
                }

                this.$store.commit("getLogin", true);
                throw String("请先登录,请联系管理员");
              }
            } catch (error) {
              this.$message({
                message: error,
                type: "warning",
              });
            }
          },
        },
        onClosed: () => {
          this.confirmBuyDialogConfig = null;
        },
      };
    },
  },
  computed: {
    selectedGoodsList() {
      return this.goodsList.filter((item) => this.selectGoodsIds[item.id]);
    },
    totalSelectedGoodsPrice() {
      const { price } =
        this.selectedGoodsList.length > 0
          ? this.selectedGoodsList.reduce(
              (a, b) => {
                return {
                  price: a.price.add(b.price),
                };
              },
              {
                price: currency(0),
              }
            )
          : {
              price: currency(0),
            };

      return price;
    },
  },
};
</script>

<style lang="less" scoped>
.goods-list-wrapper {
  overflow: hidden;
  margin: 20px 0;

  .goods-list {
    margin-right: -15px;

    .goods-list__item {
      .item__out-wrapper {
        margin-right: 15px;
        margin-bottom: 15px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 114.63%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-image: url("~@/assets/img/roll/bg-1.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            color: var(--gray2, #4f7387);

            overflow: hidden;

            display: flex;
            flex-direction: column;

            &.item__out-wrapper__inner-wrapper__content--active {
              background-image: url("~@/assets/img/roll/bg-1--active.png");
            }

            .content__title {
              display: flex;
              align-items: center;

              margin: 12px;

              .content__title__text {
                padding: 0 6px;
                line-height: 16px;

                background-color: #f8c544;

                color: var(--black1, #0d141a);
                text-align: center;
                font-size: 11px;
                font-weight: 600;
              }
            }

            @keyframes scale {
              0%, 100% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
            }

            .content__product-img {
              width: 96px;
              height: 73px;
              margin: auto;
              animation: scale 2s infinite;  // 添加缩放动画
            }

            .content__name {
              margin: 10px;

              color: var(--gray4, #dde5e9);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .content__footer {
              display: flex;
              align-items: center;
              padding-bottom: 10px;
              margin: 0 12px;

              .content__footer__inventory {
                flex: 1;

                color: var(--gray2, #4f7387);
                font-family: "PingFang SC";
                font-size: 12px;
                font-weight: 400;

                text-align: right;
              }
            }
          }
        }
      }
    }
  }

  .big-btn__pre-icon {
    margin-right: 4px;
    width: 20px;
    height: 20px;
  }
}
</style>
