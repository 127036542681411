<template>
  <div class="slide">
    <!-- <el-carousel indicator-position="outside" height="86px"  :interval="5000">
      <el-carousel-item v-for="(item, index) in listSlide" :key="index" class="slide-item">
        <ul class="slide-ul">
          <li
            v-for="(item1, index1) in item"
            :key="index1"
            @click="getBox(item1.box_id)"
          >

            <div class="slide-warp">
              <div class="left">
                <img :src="item1.imageUrl" />
              </div>
              <div class="right">
                <h4 class="r-2" :style="{color:item1.color}">{{ item1.skin_name }}</h4>
                <h5>
                  打开 <span>{{ item1.box_name }}</span> 获得
                </h5>
                <h6>
                  <img :src="item1.player_img" />
                  <span style="color:#ADC8CB;">{{ item1.player_name }}</span>
                </h6>
              </div>
            </div>
            <span
              :style="{
                backgroundColor: item1.color,
              }"
            ></span>
            <span class="back"></span>
            <div class="ul-line"></div>
          </li>
        </ul>
      </el-carousel-item>
    </el-carousel> -->
    <!-- {{listSlide2[0]}} -->
    <vue-seamless-scroll
    :data="listSlide2[0]"
    :class-option="classOption"
    class="warp"
  > 
    <ul class="ul-item">
      <li class="li-item" v-for="(item, index2) in listSlide2[0]" :key="index2" @click="getBox(item.box_id)"           
        :style="{
          backgroundImage:'url(' + item.img + ')',
        }">
        <div class="info" >
          <div class="imgurl"><img :src="item.imageUrl"/></div>
          <div style="color:#ADC8CB;" class="player-name" :title="item.skin_name">
            <img :src="item.player_img" />
            {{ item.player_name }}
          </div>
        </div>
        <!-- <div class="info2">
          <p class="r-2" :style="{color:item.color}">{{ item.skin_name }}</p>
          <h5>打开 <span>{{ item.box_name }}</span> 获得</h5>
          <img :src="item.player_img" />
          <span style="color:#ADC8CB;">{{ item.player_name }}</span>
        </div> -->
        <!-- <div class="box-open">
          <p class="r-2">{{ item.skin_name }}</p>
          <p>打开{{ item.box_name }}获得</p>
        </div> -->
      </li>
    </ul>
  </vue-seamless-scroll>
    <div class="clear"></div>
  </div>
</template>

<script>
 import vueSeamlessScroll from 'vue-seamless-scroll'
// import Vue from 'vue/types/umd';
import Vue from 'vue';
export default {
    components: {
      vueSeamlessScroll
    },
  data() {
    return {
      listSlide: [[], [], []],
      listSlide2: [],
        listData: [1, 2, 3, 4, 5, 6],
        classOption: {
          limitMoveNum: 2,
          direction: 2,
        }
    };
  },
  computed: {
    // mylistSlideData: {
    //   get: function(){
    //     return this.listSlideTemp
    //   }
    // }
  },
  methods: {
    getList() {
      let param = {
        page: 1,
        pageSize: 18,
      };
      this.$axios
        .post("/index/Box/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            console.log(data.data.list,'111')
            this.listSlide2.push(data.data.list)
            console.log(this.listSlide2[0],'11')
            for (let i = 0; i < data.data.list.length; i++) {
              data.data.list[i].color = this.transferColorToRgb(
                data.data.list[i].color
              );
              data.data.list[i].color1 =
                "rgba" +
                data.data.list[i].color.substring(
                  3,
                  data.data.list[i].color.length - 1
                ) +
                ", 0)";

              data.data.list[i].color2 =
                "rgba" +
                data.data.list[i].color.substring(
                  3,
                  data.data.list[i].color.length - 1
                ) +
                ", 0.02)";

              data.data.list[i].color3 =
              "rgba" +
              data.data.list[i].color.substring(
                3,
                data.data.list[i].color.length - 1
              ) +
              ", 0.06)";

              data.data.list[i].color4 =
              "rgba" +
              data.data.list[i].color.substring(
                3,
                data.data.list[i].color.length - 1
              ) +
              ", 0.2)";

              if (i < 6) {
                this.listSlide[0].push(data.data.list[i]);
              } else if (i >= 6 && i < 12) {
                this.listSlide[1].push(data.data.list[i]);
              } else {
                this.listSlide[2].push(data.data.list[i]);
              }
            }
            // console.log(this.listSlide);
          }
        });
    },
    transferColorToRgb(color) {
      if (typeof color !== "string" && !(color instanceof String))
        return console.error("请输入16进制字符串形式的颜色值");
      color = color.charAt(0) === "#" ? color.substring(1) : color;
      if (color.length !== 6 && color.length !== 3)
        return console.error("请输入正确的颜色值");
      if (color.length === 3) {
        color = color.replace(/(\w)(\w)(\w)/, "$1$1$2$2$3$3");
      }
      var reg = /\w{2}/g;
      var colors = color.match(reg);
      for (var i = 0; i < colors.length; i++) {
        colors[i] = parseInt(colors[i], 16).toString();
      }
      return "rgb(" + colors.join() + ")";
    },
    getBox(box_id) {
        this.$parent.getBoxInfo(box_id)
    },
  },
  mounted() {
    this.getList();
  },
};
</script>

<style lang="less" scoped>
  .warp {
    // width: 130px * 4;
    // height: 120px;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      &.ul-item {
        display: flex;
        .li-item {
          display: flex;
          align-items: center;
          justify-content: center;
          // position: relative;
          width: 176px;
          height: 92px;
          margin-right: 10px;
          // line-height: 214px;
          background-repeat: no-repeat;
          background-size: 100% 100%;
          .box-open{
            display: none;
            position: absolute;
            text-align: center;
            line-height: 22px;
            p{
              color: #fff;
              font-size: 14px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          .info2{
            font-size: 12px;
            margin-left: 5px;
            line-height: 20px;
            .r-2{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 90px;
            }
            p{
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            h5 {
              color: #848492;
              white-space: nowrap;
              font-weight: 200;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
             span {
              color: #ADC8CB;
              font-size: 12px;
            }
            }
            img{
              height: 20px;
              width: 20px;
            }
          }
          .info{
             height: 100%;
             .imgurl{
              // max-width: 120px;
              height: 56px;
              margin-bottom: 10px;
              img{
                width: 100%;
                height: 100%;
              }
             }
            .player-name{
              color: #fff!important;
              font-size: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              align-items: center;
              justify-content: center;
              img{
                width: 20px;
                height: 20px;
                border-radius: 50%;
                padding-right: 2px;
              }
            }
          }
        }
        // .li-item:nth-of-type(odd){
        //   background-image: url('https://hztupian.oss-cn-hangzhou.aliyuncs.com/files/box/eb6afa7022f5ff0d612c4bb5863efa33.png');
        // }
        // .li-item:nth-of-type(even){
        //   background-image: url('https://hztupian.oss-cn-hangzhou.aliyuncs.com/files/box/805d954af09636b3ccbde728c96c6a00.png');
        // }
        // .li-item:nth-child(3){
        //  background-image: url('https://hztupian.oss-cn-hangzhou.aliyuncs.com/files/box/c1c563f09af1efc689b0e6158491313b.png');
        // }
        // .li-item:nth-last-child(3) {
        //   background-image: url('https://hztupian.oss-cn-hangzhou.aliyuncs.com/files/box/c1c563f09af1efc689b0e6158491313b.png');
        // }
        // .li-item.nth-child(6) {
        //   background-image: url('https://hztupian.oss-cn-hangzhou.aliyuncs.com/files/box/c1c563f09af1efc689b0e6158491313b.png');
        // }
        .li-item:hover{
          .box-open{
            display: inline-block;
          }
        }
      }
    }
  }
.slide /deep/ .el-carousel__indicators--outside {
  display: none;
}
@keyframes rolling {
  from{
    transform: translateX(0);
  } to {
    transform: translateX(-50%);
  }
}
.slide {
  margin-top: -30px;
  margin-bottom: 1.56vw;
   /deep/ .el-carousel{
  //  background-color: red;
  }
  .slide-box{
    width: 800px;
    height: 100px;
    overflow: hidden;
    margin: 0 auto;
    position: relative;
    .item-list{
      animation: rolling  50s linear infinite;
      position: absolute;
      display: flex;
      img{
        float: left;
        height: 100px;
        margin-right: 10x;

      }
    }

  }
  .slide-ul {
    display: flex;
    li { 
      // background-color: red;
      width: 16.66%;
      float: left;
      display: flex;
      justify-content: center;
      position: relative;
      // background-image: linear-gradient(
      //   rgba(43, 44, 55, 0.5),
      //   rgba(173, 200, 203, 0.5)
      // );
      .line {
        position: absolute;
        bottom: 4px;
        width: 100%;
        height: 2px;
        background-color: #acc7ca;
      }
      .line1 {
        background-color: #b868b3;
      }
      .line2 {
        background-color: #f1a921;
      }
      // background-color: rgba(65, 105, 161, 0.4);
      // border-bottom: 2px solid #fff;
      .slide-warp {
        display: flex;
        align-items: center;
        padding: 5px 10px;
        overflow: hidden;
        background-image: url('https://hztupian.oss-cn-hangzhou.aliyuncs.com/files/box/eb6afa7022f5ff0d612c4bb5863efa33.png');
        background-repeat: no-repeat;
        .left {
          margin-right: 10px;
          //flex: 1 1 auto;
          img {
            height: 70px;
            width: auto;
          }
        }
        .right {
          //flex: 2 1 auto;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            color: #ADC8CB;
            font-weight: 200;
            font-size: 13px;
          }
          h5 {
            color: #848492;
            white-space: nowrap;
            font-weight: 200;
            font-size: 12px;
            span {
              color: #ADC8CB;
              font-size: 12px;
            }
          }
          h6 {
            display: flex;
            align-items: center;

            img {
              width: 20px;
              height: 20px;
              border-radius: 50%;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              margin-left: 5px;
              color: #848492;
              font-size: 12px;
            }
          }
        }
      }
    }
    .li1 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(185, 105, 212, 0.5)
      );
    }
    .li2 {
      background-image: linear-gradient(
        rgba(43, 44, 55, 0.5),
        rgba(241, 169, 32, 0.5)
      );
    }
  }
  .slide-ul:hover {
    
  }
}
.ul-line{
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  z-index: -1;
}

/deep/ .el-carousel__item.is-animating{
  transition: transform .4s ease-in-out;
}
@media screen and (max-width: 767px) {
  .warp {
    ul {
      &.ul-item {
        .li-item {
          width: 140px;
          height: 70px;
           .info{
             .imgurl{
              width: 56px;
              height: 44px;
              margin-bottom: 2px;
             }
           }
        }
      }
   }
  }
}
</style>