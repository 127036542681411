<template>
  <ModuleWrapper
    :values="roomDataRefact"
    title="参与人数"
    :titleSubInfoList="titleSubInfoList"
  >
    <div class="goods-list-wrapper">
      <div class="goods-list">
        <el-row>
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
            v-for="(item, index) in roomData.player_skin"
            :key="index"
          >
            <div class="goods-list__item">
              <div class="item__out-wrapper">
                <div class="item__out-wrapper__inner-wrapper">
                  <div class="item__out-wrapper__inner-wrapper__content">
                    <div class="content__product-img__wrapper">
                      <div class="content__product-img">
                        <img class="img-cover" :src="item.skin_img" alt="" />
                      </div>
                    </div>
                    <div class="content__player-info">
                      <div class="content__player-info__header-img">
                        <img class="img-cover" :src="item.player_img" alt="" />
                      </div>
                      <div class="content__player-info__name">
                        {{ item.player_name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </ModuleWrapper>
</template>

<script>
import ModuleWrapper from "../moduleWrapper/index.vue";
import currency from "currency.js";

const titleSubInfoList = [
  {
    label: "已送出",
    prop: "$playerSkinCount",
    state: "件",
  },
  {
    label: "价值",
    prop: "$playerSkinPrice",
    state: "",
  },
];

export default {
  name: "winningRecordModule",
  components: {
    ModuleWrapper,
  },
  props: {
    roomData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleSubInfoList,
    };
  },
  created() {},
  methods: {},
  computed: {
    roomDataRefact() {
      const { player_skin } = this.roomData;

      const { price } =
        player_skin.length > 0
          ? player_skin.reduce(
              (a, b) => {
                return {
                  price: a.price.add(b.price),
                };
              },
              {
                price: currency(0),
              }
            )
          : {
              price: currency(0),
            };

      return {
        ...this.roomData,
        $playerSkinCount: player_skin.length,
        $playerSkinPrice: price,
      };
    },
  },
};
</script>

<style lang='less' scoped>
.goods-list-wrapper {
  overflow: hidden;
  .goods-list {
    margin-right: -15px;

    .goods-list__item {
      .item__out-wrapper {
        margin-right: 15px;
        margin-bottom: 15px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 102.44%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/room/prize-bg-2.png");
            color: var(--gray2, #4f7387);

            .content__product-img__wrapper {
              width: 60%;
              height: 73/96 * 60%;
              margin: 10% auto;

              .content__product-img {
                width: 100%;
                height: 100%;
              }
            }

            .content__player-info {
              position: absolute;
              right: 0;
              bottom: 0;
              left: 0;

              .content__player-info__header-img {
                margin: auto;

                width: 40px;
                height: 40px;
                border-radius: 40px;
                border: 1px solid var(--blue, #599be4);

                overflow: hidden;
              }
              .content__player-info__name {
                padding-top: 8px;
                padding-bottom: 24px;

                color: var(--gary3, #889aa7);
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
            }
          }
        }
      }
    }
  }
}
</style>
