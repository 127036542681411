<template>
  <DrawerPop
    slideForm="center"
    :visable="visableDrawer"
    :onClosed="onClosed"
    :changeVisable="(events) => (visableDrawer = !visableDrawer)"
  >
    <div class="password-drawer">
      <div class="password-drawer__title">房间口令</div>
      <div class="password-drawer__content">
        <el-input
          type="password"
          v-model="password"
          placeholder="请输入房间口令"
        />
        <div class="password-drawer__content__submit-btn">
          <el-button @click="passAddRoom">确定</el-button>
        </div>
      </div>
    </div>
  </DrawerPop>
</template>
  
<script>
import DrawerPop from "@/components/drawerPop/index";

export default {
  name: "inputRoomPasswordDrawer",
  components: {
    DrawerPop,
  },
  props: {
    id: {
      type: [Number, String],
      required: true,
    },
    onClosed: {
      type: Function,
      required: true,
    },
    onOK: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      drawerList: [],
      visableDrawer: false,
      password: "",
      passAddRoomLoading: false,
    };
  },
  mounted() {
    this.visableDrawer = true;
  },
  methods: {
    async passAddRoom() {
      try {
        let param = {
          free_room_id: this.id,
          password: this.password,
        };
        this.passAddRoomLoading = true;
        const { data } = await this.$axios.post(
          "index/Free/addFreeRoom",
          this.$qs.stringify(param)
        );

        if (data.status == "1") {
          this.$message({
            message: data.msg,
            type: "success",
          });
          this.onOK();
          this.visableDrawer = false;
        } else {
          throw data.msg;
        }
      } catch (error) {
        this.$message({
          message: error,
          type: "error",
        });
      } finally {
        this.passAddRoomLoading = false;
      }
    },
  },
};
</script>
  
<style lang='less' scoped>
.password-drawer {
  width: 80vw;
  padding: 40px;

  // fill: linear-gradient(0deg, #141d26 1.04%, rgba(20, 29, 38, 0) 17.74%);
  // box-shadow: 0px 3.513px 36.006px 0px  inset;

  background-color: #141d26;

  .password-drawer__title {
    margin-bottom: 10px;

    font-size: 20px;
    color: #fff;
  }

  .password-drawer__content {
    display: flex;
    align-items: flex-start;

    .password-drawer__content__submit-btn {
      margin-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
