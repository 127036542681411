<template>
  <div class="responsive-mobile-container spread">
    <div>
      <div class="spread__module-name">推广中心</div>
      <div class="spread__link__wrapper">
        <!-- <div class="spread__link__wrapper__bg">
          <img
            class="img-cover"
            src="~@/assets/img/common/module-bg--343-240.png"
            alt=""
          />
        </div> -->
        <div class="spread__link">
          <div class="spread__link__item">
            <div class="spread__link__item__title">您的推广链接</div>
            <div class="spread__link__item__content">
              <div class="text-over-one spread__link__item__content__left">
                {{ valueUrl }}
              </div>
              <div
                class="spread__link__item__content__copy-btn"
                @click="copyText(valueUrl)"
              >
                复制
              </div>
            </div>
          </div>
          <div class="spread__link__item">
            <div class="spread__link__item__title">您的推广码</div>
            <div class="spread__link__item__content">
              <div class="text-over-one spread__link__item__content__left">
                {{ valueCode }}
              </div>
              <div
                class="spread__link__item__content__copy-btn"
                @click="copyText(valueCode)"
              >
                复制
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div>
      <div class="spread__module-name">推广统计</div>
      <div class="spread__statistics__wrapper">
        <div class="spread__statistics__wrapper__bg">
          <img
            class="img-cover"
            src="~@/assets/img/common/module-bg--343-120.png"
            alt=""
          />
        </div>
        <div class="spread__statistics__info-list">
          <div
            v-for="item in infoList"
            :key="item.prop"
            class="info-list__item"
          >
            <div
              class="info-list__item__title-bar"
              @click="item.onClick && item.onClick()"
            >
              <div class="info-list__item__title-bar__value">
                {{
                  item.prop in spreadDetails ? spreadDetails[item.prop] : "--"
                }}
              </div>
              <div
                v-if="item.showToDetails"
                class="info-list__item__title-bar__detail-text"
              >
                详情
              </div>
            </div>
            <div class="info-list__item__label">{{ item.label }}</div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="underline-table__out-wrapper">
      <div class="underline-table__wrapper">
        <table class="underline-table">
          <tr>
            <th
              class="underline-table__th"
              v-for="item in tableConfigList"
              :key="item.prop"
            >
              {{ item.label }}
            </th>
          </tr>
          <tr
            class="underline-table__tr"
            v-for="(itemRank, itemRankIndex) in tableData1"
            :key="itemRank.id"
          >
            <td
              v-for="item in tableConfigList"
              :key="item.prop"
              class="underline-table__tr__td"
            >
              {{ itemRank[item.prop] }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import navheader from "@/components/navheader.vue";

const tableConfigList = [
  {
    label: "用户名",
    prop: "name",
  },
  {
    label: "充值金额",
    prop: "recharge",
  },
  {
    label: "佣金比例",
    prop: "ratio",
  },
  {
    label: "获得充值佣金",
    prop: "money",
  },
  {
    label: "充值时间",
    prop: "time",
  },
];

export default {
  components: { navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],

  data() {
    return {
      valueUrl: "https://www.standoffudi.store/invite/M2nKD64beM",
      valueCode: "TRtqaetA",
      tableData1: [],
      dateList: [{ name: "推广统计", state: false, val: 0 }],
      payInfoData: {
        flag: true,
        type: "",
        checked2: false,
        checked1: false,
      }, // 充值提示
      infoList: [
        {
          label: "我的下线",
          prop: "people_num",
          onClick: () => {
            this.goLonger();
          },
          showToDetails: true,
        },
        {
          label: "已获得总佣金",
          prop: "invite_commission",
          onClick: () => {},
        },
        {
          label: "佣金比例",
          prop: "ratio",
          onClick: () => {},
        },
      ],
      spreadDetails: {},
      tableConfigList,
    };
  },
  mounted() {
    this.getList();
    this.getList1(7);
  },
  methods: {
    //挑战推广详情
    goLonger() {
      this.$router.push({
        path: `/SpreadLonger`,
      });
    },
    //我的推广链接和邀请码
    getList() {
      let param = {};
      this.$axios
        .post("index/Invite/inviteInfo", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.valueUrl = data.data.invite_url;
            this.valueCode = data.data.invite_code;
          }
        });
    },
    //我的下线7天/30天/今天/全部
    getList1(days) {
      let param = {
        days: days,
      };
      this.$axios
        .post("index/Invite/offline", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            //console.log(data.data.people_num)

            this.spreadDetails = data.data;
            this.tableData1 = data.data.list;
            for (let i = 0; i < this.tableData1.length; i++) {
              if (this.tableData1[i].status == 1) {
                this.tableData1[i].state = "已结算";
              } else {
                this.tableData1[i].state = "未结算";
              }
            }
          }
        });
    },

    //复制
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },

    //我的下线 详情
    getLonger() {
      let param = {};
      this.$axios
        .post("index/Invite/offlineList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          console.log(data);
          if (data.status == 1) {
            this.tableData1 = data.data.list;
          }
        });
    },
    getState(name, val) {
      for (let i = 0; i < this.dateList.length; i++) {
        if (name == this.dateList[i].name) {
          this.dateList[i].state = true;
        } else {
          this.dateList[i].state = false;
        }
      }
      this.getList1(val);
    },
    checkPayInfo() {
      if (this.payInfoData.checked1 && this.payInfoData.checked2) {
        // if (this.payInfoData.type) {
        this.payInfoData.flag = false;
        // }
      } else {
        this.$message.warning("请阅读并勾选所有协议");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.spread {
  padding: 16px;

  .spread__module-name {
    padding: 16px 0;

    color: var(--gary3, #889aa7);
    font-family: "PingFang SC";
    font-size: 16px;
    font-weight: 500;
  }

  .spread__link__wrapper {
    position: relative;
    // padding-bottom: 69.97%;
    height: 280px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;

    background-image: url("~@/assets/img/common/module-bg--343-240.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .spread__link__wrapper__bg {
        display: flex;
        flex-direction: column;

    }

    .spread__link {
      display: flex;
      flex-direction: column;

      .spread__link__item {
        margin-bottom: 20px;

        .spread__link__item__title {
          margin-bottom: 20px;

          color: var(--gray4, #dde5e9);
          font-family: "PingFang SC";
          font-size: 14px;
          font-weight: 600;
        }
        .spread__link__item__content {
          display: flex;
          align-items: center;

          .spread__link__item__content__left {
            flex: 1;
            overflow: hidden;

            line-height: 44px;

            border: 0.1px solid rgba(89, 155, 228, 0.6);
            background: rgba(13, 20, 26, 0.5);

            padding: 0 14px;

            color: var(--gray2, #4f7387);
            font-family: "PingFang SC";
            font-size: 12px;
            font-weight: 400;
          }
          .spread__link__item__content__copy-btn {
            width: 80px;
            line-height: 40px;

            margin-left: 14px;

            background-image: url("~@/assets/img/common/btn-bg--yellow--80-40.png");
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            color: var(--black1, #0d141a);
            text-align: center;
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  }

  .spread__statistics__wrapper {
    position: relative;
    padding-bottom: 34.985%;

    .spread__statistics__wrapper__bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
    .spread__statistics__info-list {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      display: flex;
      align-items: center;
      .info-list__item {
        width: 33.33%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .info-list__item__title-bar {
          display: flex;
          align-items: center;

          .info-list__item__title-bar__value {
            // 样式
            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .info-list__item__title-bar__detail-text {
            margin-left: 4px;

            color: var(--blue, #599be4);
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-decoration-line: underline;
            // 样式
          }
        }
        .info-list__item__label {
          margin-top: 8px;

          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 13px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          // 样式
        }
      }
    }
  }

  .underline-table__out-wrapper {
    overflow: hidden;

    margin-top: 20px;

    .underline-table__wrapper {
      overflow-x: auto;
      margin-bottom: -20px;
      padding-bottom: 20px;

      .underline-table {
        width: 100%;
        
        border-collapse: collapse;
        background-color: rgba(13, 20, 26, 0.5);

        th,
        td {
          border: none;
          padding: 0;
          margin: 0;

          white-space: nowrap;
        }

        .underline-table__th {
          padding: 11px 16px;
          background-color: rgba(31, 51, 73, 0.7);
          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .underline-table__tr {
          .underline-table__tr__td {
            padding: 11px 16px;
            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
