<template>
  <div class="goods-module">
    <div class="goods-module__title">{{ goodsModule.type_name }}</div>
    <div class="goods-module__list">
      <el-row>
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          v-for="item in goodsModule.box_list"
          :key="item.id"
        >
          <div class="list__item" @click="openbox(item.id)">
            <div class="item_round">{{ item.show_round }} 回合</div>
            <div class="list__item__img-wrapper">
              <img class="img-item" :src="item.img_main" alt="" />
              <div class="list__item__img-wrapper__active-img">
                <img class="img-item" :src="item.img_active" alt="" />
              </div>
            </div>
            <div class="list__item__name">{{ item.name }}</div>
            <div class="list__item__footer">
              <div class="footer__price-icon">
                <img
                  class="footer__price-icon__img"
                  src="~@/assets/img/money.png"
                  alt=""
                />
              </div>
              <div class="footer__price">{{ item.price }}</div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "goodsModule",
  props: {
    goodsModule: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    openbox(box_id) {
      this.$router.push({
        path: `/Openbox`,
        query: {
          box_id: box_id,
        },
      });
    },
  },
};
</script>

<style lang='less' scoped>
.goods-module {
  margin-top: 30px;
  .goods-module__title {
    margin-bottom: 10px;

    width: 237px;
    height: 52px;

    background-image: url("~@/assets/img/home/Group92323.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    margin: 0 auto;

    text-align: center;

    line-height: 52px;
    color: var(--blue, #599be4);
    font-family: YouSheBiaoTiHei;
    font-size: 26px;
    font-style: normal;
    font-weight: 400;
  }
  .goods-module__list {
    .list__item {
      margin-bottom: 10px;
      // position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;

      @keyframes flip {
        0%, 100% { 
          transform: rotateY(0deg) scale(1); 
        }
        50% { 
          transform: rotateY(180deg) scale(1.1); 
        }
      }

      .item_round{
        color: #599be4;
        font-size: 12px;
        width: 140px;
        text-align: right;
        margin-top: 10px;
      }

    .list__item__img-wrapper {
      position: relative;
      width: 140px;
      height: 140px;
      margin: 0 auto;
      animation: flip 2s 3 3s;

      

      .img-item {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
            
        @keyframes jump {
        0%, 100% { 
          transform: translateY(0) scale(1); 
        }
        50% { 
          transform: translateY(-20px) scale(1.1); 
        }
      }

      .list__item__img-wrapper__active-img {
        position: absolute;
        left: 20px;
        right: 20px;
        bottom: 20px;
        top: 20px;
        z-index: 1;
        animation: jump 2.5s infinite;

        .img-item {
          /* Your .img-item styles */
        }
      }
      }
      .list__item__name {
        color: var(--gray4, #dde5e9);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;

        // margin-top: -20px;
        margin-bottom: 10px;
      }
      .list__item__footer {
        background-image: url("~@/assets/img/home/Group92309.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        width: 120px;
        height: 36px;

        margin: auto;

        display: flex;
        align-items: center;
        justify-content: center;

        .footer__price-icon {
          width: 16px;
          height: 16px;

          margin-right: 4px;

          .footer__price-icon__img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .footer__price {
          color: var(--gray4, #dde5e9);
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
  }
}
</style>
