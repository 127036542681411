<template>
  <div class="responsive-mobile-container header-card__wrapper">
    <div class="header-card__wrapper__content">
      <div class="header-card">
        <div class="header-card__header">
          <div class="header-card__header__left">
            <div class="header-card__header__left__img">
              <img class="img-cover" :src="roomData.img" alt="" />
              <div class="left__img__footer-icon">
                <img
                  class="img-cover"
                  src="@/assets/img/room/office.png"
                  alt=""
                />
              </div>
            </div>
            <div class="header-card__header__left__info">
              <div class="text-over-one info__title">
                {{
                  roomData.room_name
                }}
              </div>
              <div class="info__sub-title">{{ roomData.desc }}</div>
            </div>
          </div>
          <div
            v-if="roomData.room_num"
            class="header-card__header__right-room-code"
          >
            房间编号: {{ roomData.room_num }}
          </div>
        </div>
        <div class="header-card__header__footer">
          <div class="header-card__header__footer__left-label">
            截止时间：{{ roomData.run_lottery_time }}
          </div>
          <RoomStatusTag :roomDetails="roomData" :addRoom="addRoom"></RoomStatusTag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomStatusTag from "@/components/roomStatusTag/index";

export default {
  name: "headerCard",
  components: {
    RoomStatusTag,
  },
  props: {
    roomData: {
      type: Object,
      required: true,
    },
    addRoom: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.header-card__wrapper {
  margin: 20px;

  .header-card__wrapper__content {
    position: relative;
    padding-bottom: 52.478%;

    background-image: url("~@/assets/img/room/room-card-bg-2.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    .header-card {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      overflow: hidden;

      .header-card__header {
        display: flex;
        align-items: center;

        overflow: hidden;

        padding: 0 20px;

        height: 60%;

        .header-card__header__left {
          position: relative;
          z-index: 1;

          display: flex;
          align-items: center;

          flex: 1;
          flex-shrink: 0;

          overflow-x: hidden;
          padding-bottom: 10px;

          &::after {
            position: absolute;
            z-index: -1;
            left: 35px;
            top: 0;
            bottom: 0;

            content: "";
            display: block;

            margin: auto;

            opacity: 0.3;
            background: linear-gradient(
              90deg,
              #599be4 7.58%,
              rgba(89, 155, 228, 0) 91.21%
            );
          }

          .header-card__header__left__img {
            position: relative;
            width: 68px;
            height: 68px;

            border-radius: 68px;
            border: 1px solid var(--blue, #599be4);

            flex-shrink: 0;

            .left__img__footer-icon {
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              margin: auto;

              transform: translate3d(0, 50%, 0);

              width: 28px;
              height: 14px;
              flex-shrink: 0;
            }
          }
          .header-card__header__left__info {
            flex: 1;
            flex-shrink: 0;

            margin-left: 12px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            overflow: hidden;

            .info__title {
              text-align: left;
              color: var(--gray4, #dde5e9);
              font-family: "PingFang SC";
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .info__sub-title {
              margin-top: 6px;
              color: var(--gary3, #889aa7);
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
        }
        .header-card__header__right-room-code {
          color: var(--blue, #599be4);
          font-family: "PingFang SC";
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .header-card__header__footer {
        position: absolute;
        left: 15px;
        right: 15px;
        bottom: 0;

        display: flex;
        align-items: center;
        height: 40%;

        .header-card__header__footer__left-label {
          flex: 1;

          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
