<template>
  <div class="goods-list-wrapper">
    <div class="goods-list">
      <el-row>
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <div class="goods-list__item">
            <div class="item__out-wrapper">
              <div class="item__out-wrapper__inner-wrapper">
                <div
                  class="item__out-wrapper__inner-wrapper__content"
                  :class="{
                    'item__out-wrapper__inner-wrapper__content--active':
                      item.state,
                  }"
                  @click="$emit('zhifuMoney', index)"
                >
                  <div class="content__price">
                    <div class="content__price__icon">
                      <img
                        class="img-cover"
                        src="~@/assets/img/charge/icon-jinbi.png"
                        alt=""
                      />
                    </div>
                    <div class="content__price__label">{{ item.money }}</div>
                  </div>
                  <div class="content__rmb">≈ ￥ {{ item.rmb }}</div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  
  <script>
import PriceBar from "@/components/priceBar/index";

export default {
  name: "goodsList",
  components: {
    PriceBar,
  },
  props: {
    goodsList: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goOrnamentOpen(item) {
      console.log(item);
      this.$router.push({
        path: `/OrnamentOpen`,
        query: {
          skin_id: item.id,
          // item: JSON.stringify(item),
        },
      });
    },
  },
};
</script>
  
  <style lang='less' scoped>
.goods-list-wrapper {
  overflow: hidden;
  margin: 20px 0;

  .goods-list {
    margin-right: -15px;

    .goods-list__item {
      .item__out-wrapper {
        margin-right: 15px;
        margin-bottom: 15px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 120/164 * 100%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/charge/module-bg--164-120--blue.png");
            color: var(--gray2, #4f7387);

            overflow: hidden;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &.item__out-wrapper__inner-wrapper__content--active {
              background-image: url("~@/assets/img/charge/module-bg--164-120--yellow.png");
            }

            .content__price {
              display: flex;
              align-items: center;

              .content__price__icon {
                margin-right: 6px;

                width: 20px;
                height: 20px;
              }
              .content__price__label {
                color: var(--yellow, #f8c544);
                text-align: center;
                font-family: "PingFang SC";
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
            .content__rmb {
              margin-top: 8px;

              color: var(--gray2, #4f7387);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>
  