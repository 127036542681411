<template>
  <div
    class="responsive-container ranking-list"
    v-loading="fetchRankListLoading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <div class="top-content">
      <div class="top-content__bg">
        <img
          class="img-cover"
          src="~@/assets/img/blindBox/rank-bg.png"
          alt=""
        />
      </div>
      <div class="top-content__body">
        <div class="category-btn-wrapper">
          <div class="category-btn-list">
            <div
              class="btn-list__item"
              v-for="(item, index) in rankCategoryOptions"
              :key="index"
              :class="
                currentSelectRank === item.value && 'btn-list__item--active'
              "
              @click="item.onClick(item)"
            >
              {{ item.label }}
            </div>
          </div>
        </div>
        <div v-if="showAllList" class="top-rank">
          <div
            v-for="(item, index) in topRankList"
            :key="index"
            class="top-rank__item"
            :style="topRankOptions[index].itemWrapperStyle"
          >
            <template v-if="item">
              <div class="top-rank__item__user-info">
                <div class="user-info__header-img">
                  <img class="img-cover" :src="item.img" alt="" />
                </div>
                <div class="user-info__name">{{ item.name }}</div>
              </div>
              <div class="top-rank__item__prize">
                <div class="top-rank__item__prize__img">
                  <img class="img-cover" :src="item.skin_img" alt="" />
                </div>
                <div class="top-rank__item__prize__name">
                  {{ item.skin_name }}
                </div>
                <div class="top-rank__item__prize__footer-bar">
                  <div class="footer-bar__pre-icon">
                    <img
                      class="img-cover"
                      src="@/assets/img/blindBox/zuanShi.png"
                      alt=""
                    />
                  </div>
                  <div class="footer-bar__name">{{ item.total_consume }}</div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="rankList.length > 0 && showAllList.show"
      class="rank-table__wrapper"
    >
      <table class="rank-table">
        <tr>
          <th
            class="rank-table__th"
            v-for="item in tableConfigList"
            :key="item.prop"
          >
            {{ item.label }}
          </th>
        </tr>

        <tr v-for="(itemRank, itemRankIndex) in rankList" :key="itemRank.id">
          <td class="rank-table__td">
            <div class="rank-img">
              <img
                v-if="rankImgUrlList[itemRankIndex]"
                class="img-cover"
                :src="rankImgUrlList[itemRankIndex]"
                alt=""
              />
            </div>
          </td>
          <td class="rank-table__td">
            <div class="user">
              <div class="user__img">
                <img class="img-cover" :src="itemRank.img" alt="" />
              </div>
              <div class="user__name">{{ itemRank.name }}</div>
            </div>
          </td>
          <td class="rank-table__td">
            <div class="skin">
              <div class="skin__img">
                <img class="img-cover" :src="itemRank.skin_img" alt="" />
              </div>
              <div class="skin__name">{{ itemRank.skin_name }}</div>
            </div>
          </td>
          <td class="rank-table__td">
            <div class="consume">
              <div class="consume__icon">
                <img
                  class="img-cover"
                  src="@/assets/img/blindBox/zuanShi.png"
                  alt=""
                />
              </div>
              <div class="consume__label">
                {{ itemRank.total_consume }}
              </div>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
const topRankOptions = [
  {
    itemWrapperStyle: {
      paddingTop: "80px",
    },
  },
  {
    itemWrapperStyle: {
      paddingTop: "30px",
    },
  },
  {
    itemWrapperStyle: {
      paddingTop: "60px",
    },
  },
];

const tableConfigList = [
  {
    label: "昨天排名",
    prop: "",
  },
  {
    label: "用户",
    prop: "name",
  },
  {
    label: "出金饰品",
    prop: "skin_name",
    slotName: "skinName",
  },
  {
    label: "奖励",
    prop: "total_consume",
  },
];

// "id": 7,
//                 "name": "132****4979",
//                 "img": "\/images\/headerImage\/6c9ac4f6ed170e6e02549dcce7dc5848.png",
//                 "skin_name": "P90 | 冷血无情 (略有磨损)",
//                 "skin_img": "https:\/\/game-aesy.c5game.com\/steam\/730\/f8768cfd972262eccb7bdd46a34bc4f0.png?x-oss-process=image\/resize,w_480,h_360",
//                 "total_consume": 9471.1

const rankImgUrlList = [
  require("@/assets/img/blindBox/rank-icon-1.png"),
  require("@/assets/img/blindBox/rank-icon-2.png"),
  require("@/assets/img/blindBox/rank-icon-3.png"),
];

export default {
  name: "rankingList",
  data() {
    const rankCategoryOptions = [
      {
        label: "活跃榜",
        value: 1,
        onClick: (current) => {
          this.showAllList.show = true;
          this.currentSelectRank = current.value;
        },
      },
      {
        label: "出金王",
        value: 2,
        onClick: (current) => {
          this.showAllList.show = false;
          this.currentSelectRank = current.value;
        },
      },
    ];
    return {
      rankCategoryOptions,
      currentSelectRank: rankCategoryOptions[0].value,
      showAllList: {
        show: true,
      },
      topRankOptions,
      tableConfigList,
      rankList: [],
      rankImgUrlList,
      fetchRankListLoading: false,
      starMember: null,
    };
  },
  created() {
    this.getRankList();
  },
  methods: {
    switchRankListSortWay() {},
    //对战排行
    async getRankList() {
      try {
        this.fetchRankListLoading = true;
        const { data } = await this.$axios.post("/index/Battle/ranking");

        console.info("data", data);

        if (data.status == "1") {
          const rankList = data.data.rank;

          this.rankList = rankList;
          this.starMember = data.data.star;
        }
      } catch (error) {
        this.$message({
          message: error,
          type: "error",
        });
      } finally {
        this.fetchRankListLoading = false;
      }
    },
  },
  computed: {
    topRankList() {
      const { rankList } = this;

      const topRankListCopy = JSON.parse(
        JSON.stringify(rankList.slice(0, topRankOptions.length))
      );
      const allTopRankList = topRankListCopy
        .splice(1, 1)
        .concat(topRankListCopy);

      const topRankList = this.showAllList.show
        ? allTopRankList
        : ["", this.starMember];

      console.info("topRankList", topRankList);

      return topRankList;
    },
  },
};
</script>

<style lang='less' scoped>
.ranking-list {
  position: relative;
  z-index: 1;

  .top-content {
    position: relative;
    padding-bottom: 100%;

    .top-content__bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }
    .top-content__body {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }

  .category-btn-list {
    padding-top: 20px;

    display: flex;
    align-items: center;
    justify-content: center;

    .btn-list__item {
      margin: 0 10px;

      width: 97px;
      line-height: 38px;

      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      color: var(--gray2, #dde5e9);

      background-image: url("~@/assets/img/blindBox/rank-btn.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &.btn-list__item--active {
        background-image: url("~@/assets/img/blindBox/rank-btn--active.png");
        color: var(--gray4, #0d141a);
      }
    }
  }

  .top-rank {
    display: flex;
    @keyframes jumpScale {
    0%, 100% {
      transform: scale(1) translateY(0);
    }
    25% {
      transform: translateY(0px);
    }
    50% {
      transform: scale(1.1) translateY(-30px);
    }
    75% {
      transform: scale(1) translateY(0px);
    }
  }
    .top-rank__item {
      width: 33.33%;
      animation: jumpScale 3s infinite;
      

      .top-rank__item__user-info {
        .user-info__header-img {
          margin: auto;

          width: 32px;
          height: 32px;
          border-radius: 32px;
          border: 1px solid var(--blue, #599be4);

          overflow: hidden;
        }
        .user-info__name {
          margin-top: 10px;

          color: var(--gray4, #dde5e9);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }
      .top-rank__item__prize {
        margin-top: 30px;

        .top-rank__item__prize__img {
          width: 42px;
          height: 32px;
          margin: auto;
        }
        .top-rank__item__prize__name {
          margin: 6px 0;

          color: var(--gray4, #dde5e9);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 10px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .top-rank__item__prize__footer-bar {
          display: flex;
          align-items: center;
          justify-content: center;

          .footer-bar__pre-icon {
            margin-right: 4px;
            width: 12px;
            height: 12px;
          }
          .footer-bar__name {
            color: var(--yellow, #f8c544);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }

  .rank-table__wrapper {
    padding: 12px;
    .rank-table {
      width: 100%;
      border-collapse: collapse;

      th,
      td {
        border: none;
        padding: 0;
        margin: 0;
      }

      .rank-table__th {
        padding: 11px 16px;
        background-color: rgba(31, 51, 73, 0.7);
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .rank-table__td {
        padding: 10px 0;

        .rank-img {
          margin: auto;

          width: 24px;
          height: 32px;
        }
        .user {
          .user__img {
            border-radius: 32px;
            border: 1px solid var(--blue, #599be4);
            overflow: hidden;

            width: 32px;
            height: 32px;
            margin: auto;
          }
          .user__name {
            margin-top: 4px;

            color: var(--gray4, #dde5e9);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
        .skin {
          padding: 0 10px;
          .skin__img {
            width: 42px;
            height: 32px;
            margin: auto;
          }
          .skin__name {
            margin-top: 4px;
            color: var(--gray4, #dde5e9);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .consume {
          display: flex;
          align-items: center;
          justify-content: center;

          .consume__icon {
            margin-right: 4px;

            width: 12px;
            height: 12px;
            overflow: hidden;
          }
          .consume__label {
            color: var(--yellow, #f8c544);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>
