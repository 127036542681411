<template>
  <div
    class="big-btn__wrapper"
    :class="{ 
      'big-btn__wrapper--fixed-bottom': fixedBottom
    }">
    <div class="big-btn" @click="onClick">
      <slot name="labelPre"></slot>
      <slot name="default"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "bigBtn",
  props: {
    fixedBottom: {
      type: Boolean,
      default: false,
    },
    onClick: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.big-btn__wrapper {
  padding: 20px 0;

  &.big-btn__wrapper--fixed-bottom {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 999;
  }
  .big-btn {
    width: 256px;
    height: 48px;

    background-image: url("~@/assets/img/common/big-btn-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;

    text-align: center;
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;


  }
}
</style>
