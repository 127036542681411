<template>
  <DrawerPop
    slideForm="center"
    :visable="visableDrawer"
    :onClosed="onClosed"
    :changeVisable="(events) => (visableDrawer = !visableDrawer)"
  >
    <div class="animate__animated animate__bounceIn responsive-container res-dialog">
      <div class="res-dialog__header">
        <img
          class="img-cover"
          src="@/assets/img/newUserPackage/banner-congratulations.png"
          alt=""
        />
      </div>
      <div class="res-dialog__body">
        <div class="body__title">获得赠品</div>
        <div class="body__goods-list__wrapper">
          <div class="body__goods-list">
            <div
              v-for="(item, index) in winList.skins_info"
              :key="index"
              class="body__goods-list__item"
            >
              <div class="item__main-img">
                <img class="img-cover" :src="item.img" alt="" />
              </div>
              <div class="text-over-one item__name">
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="res-dialog__footer">
        <div class="footer__btn footer__btn--blue" @click="winexchange('99')">
          <div class="footer__btn__pre-icon">
            <img
              class="img-cover"
              src="@/assets/img/common/masonry.png"
              alt=""
            />
          </div>
          <div class="footer__btn__label">
            {{ `${exchangeLoading ? "兑换中..." : "兑换" + total_price}` }}
          </div>
        </div>
        <div class="footer__btn footer__btn--yellow" @click="winget">
          <div class="footer__btn__label">放入背包</div>
        </div>
      </div>
    </div>
  </DrawerPop>
</template>

<script>
import Utils from "@/assets/js/util.js";
import DrawerPop from "@/components/drawerPop/index";

export default {
  name: "winRetDialog",
  props: {
    winList: {
      type: Object,
      required: true,
    },
    onClosed: {
      type: Function,
      required: true,
    },
  },
  components: {
    DrawerPop,
  },
  data() {
    return {
      //winState: true,
      list4: [],
      total_price: 0,
      windowWidth: document.body.clientWidth,
      showNav: true,
      visableDrawer: false,
      exchangeLoading: false,
    };
  },
  methods: {
    //兑换
    winexchange(index) {
      if (this.exchangeLoading) return;

      var param = {
        player_skins_ids: this.winList.player_skins_ids,
      };
      console.log(index);
      if (index != "99") {
        param = {
          player_skins_ids: [this.winList.player_skins_ids[index]],
        };
      } else {
        this.$emit("winexchange", false);
      }

      this.exchangeLoading = true;

      this.$axios
        .post("/index/User/exchangeToMoneynew", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              //message: data.msg,
              message: "兑换成功",
              type: "success",
            });

            if (index != "99") {
              this.winList.skins_info = this.winList.skins_info.filter(
                (item, indexs) => indexs != index
              );
              var a = this.winList.player_skins_ids.splice(
                this.winList.player_skins_ids.indexOf(
                  this.winList.player_skins_ids[index]
                ),
                1
              );
              this.total_price = 0;
              for (let i = 0; i < this.winList.skins_info.length; i++) {
                this.total_price += parseFloat(
                  this.winList.skins_info[i].price
                );
              }
              this.total_price = this.total_price.toFixed(2);
            }

            this.closeDialog();
          } else {
            this.$message({
              //message: data.msg,
              message: "兑换失败",
              type: "warning",
            });
          }
        })
        .finally(() => {
          this.exchangeLoading = false;
        });
    },
    //放入背包
    winget() {
      this.$emit("winget", false);
      this.closeDialog();
    },
    //x掉
    winX() {
      this.$emit("winX", false);
    },
    closeDialog() {
      this.visableDrawer = false;
    },
  },
  mounted() {
    this.visableDrawer = true;
    // console.log(this.winList);
    for (let i = 0; i < this.winList.skins_info.length; i++) {
      this.total_price += Number(this.winList.skins_info[i].price);
    }
    this.total_price = this.total_price.toFixed(2);
    if (this.windowWidth < 1024) {
      this.showNav = false;
    }
  },
};
</script>

  
<style lang="less" scoped>
.res-dialog {

  .res-dialog__header {
    margin: auto;

    width: 315px;
    height: 92px;
  }

  .res-dialog__body {
    .body__title {
      text-align: center;
      font-family: "Alibaba PuHuiTi";
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    @goodsItemWidth: 164px;

    .body__goods-list__wrapper {
      overflow: hidden;
      // transform: translate3d(calc((100% - @goodsItemWidth) / 2), 0, 0);

      .body__goods-list {
        display: flex;
        align-items: center;

        overflow-x: auto;
        margin-bottom: -20px;
        padding-bottom: 20px;

        // width: calc((100% - @goodsItemWidth) / 2 + @goodsItemWidth);

        padding-left: calc((100% - @goodsItemWidth) / 2);

        .body__goods-list__item {
          width: @goodsItemWidth;
          height: 188px;
          margin-right: 10px;

          background-image: url("~@/assets/img/common/bg-card--yellow-164-188.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          .item__main-img {
            width: 96px;
            height: 73px;

            margin: 30px auto;
          }
          .item__name {
            padding: 0 20px;

            color: var(--gray4, #dde5e9);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 12px;
            font-weight: 600;
          }
        }
      }
    }
  }
  .res-dialog__footer {
    margin-top: 40px;

    display: flex;
    align-items: center;
    justify-content: center;

    .footer__btn {
      display: flex;
      align-items: center;

      justify-content: center;

      width: 148px;
      height: 44px;

      margin: 6px;

      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      .footer__btn__pre-icon {
        width: 20px;
        height: 20px;
      }
      .footer__btn__label {
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      &.footer__btn--blue {
        color: var(--white, #fff);
        background-image: url("~@/assets/img/common/btn-bg--blue-148-44.png");
      }
      &.footer__btn--yellow {
        color: var(--white, #0d141a);
        background-image: url("~@/assets/img/common/btn-bg--yellow-148-44.png");
      }
    }

    // 如果有多个 .footer__btn，可以继续添加样式
  }
}
</style>
  