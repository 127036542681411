<template>
  <transition name="fade">
    <div class="prize-dialog">
      <!-- // 特殊操作：禁止页面滚透 -->
      <div
        class="prize-dialog__mask"
        @touchstart.prevent.stop=""
        @touchend="maskCloseAble && hideDialog()"
        @mouseup="maskCloseAble && hideDialog()"
      ></div>
      <transition
        :name="`drawer-slide--${slideForm}`"
        @after-leave="onClosed()"
      >
        <div
          v-if="visable"
          class="prize-dialog__content"
          :class="`prize-dialog__content--${slideForm}`"
        >
          <slot></slot>
        </div>
      </transition>
    </div>
  </transition>
</template>
    
<script>
export default {
  name: "drawerPop",
  props: {
    /**
    * @feat < 滑出方向 >
        可选值： bottom / left / top / right / center
    */
    slideForm: {
      type: String,
      default: "bottom",
    },
    maskCloseAble: {
      type: Boolean,
      default: true,
    },
    onClosed: {
      type: Function,
      required: true,
    },
    changeVisable: {
      type: Function,
      required: true,
    },
    visable: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      drawerList: [],
    };
  },

  methods: {
    hideDialog() {
      this.changeVisable()
    },
  },
};
</script>
    
<style lang='less' scoped>
// 从底部滑出
.drawer-slide--bottom-enter-active,
.drawer-slide--bottom-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.drawer-slide--bottom-enter,
.drawer-slide--bottom-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
// 从顶部滑出
.drawer-slide--top-enter-active,
.drawer-slide--top-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.drawer-slide--top-enter,
.drawer-slide--top-leave-to {
  transform: translateY(-100%);
  opacity: 0;
}
// 从右边
.drawer-slide--right-enter-active,
.drawer-slide--right-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.drawer-slide--right-enter,
.drawer-slide--right-leave-to {
  transform: translateX(100%);
  opacity: 0;
}
// 从左边
.drawer-slide--left-enter-active,
.drawer-slide--left-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.drawer-slide--left-enter,
.drawer-slide--left-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

// 从中间
.drawer-slide--center-enter-active,
.drawer-slide--center-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}
.drawer-slide--center-enter 
.drawer-slide--center-leave-to {
  opacity: 0;
}

// 淡入淡出
.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.prize-dialog {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.9);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .prize-dialog__mask {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
  }

  .prize-dialog__content {
    position: absolute;

    &.prize-dialog__content--top {
      top: 0;
      left: 0;
      right: 0;
    }
    &.prize-dialog__content--bottom {
      left: 0;
      right: 0;
      bottom: 0;
    }
    &.prize-dialog__content--right {
      right: 0;
      top: 0;
      bottom: 0;
    }
    &.prize-dialog__content--left {
      left: 0;
      top: 0;
      bottom: 0;
    }
    &.prize-dialog__content--center {
      left: 50%;
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    }
  }
}
</style>
    