<template>
  <div class="steam-link-card">
    <div class="card__wrapper">
      <div class="card">
        <div class="card__link">
          <div class="card__link__item">
            <div class="card__link__item__title">输入您的充值码</div>
            <div class="card__link__item__content">
              <div class="text-over-one content__input-wrapper">
                <input
                  :value="cdk"
                  class="content__input-wrapper__input"
                  type="text"
                  placeholder="您的充值码"
                  @change="onCDKChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "steamLinkCard",
  props: {
    cdk: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    onCDKChange(e) {
      this.$emit("CDKChange", e.target.value);
    },
  },
};
</script>
  
  <style lang="less" scoped>
.steam-link-card {
  .card__wrapper {
    position: relative;
    padding-bottom: 136/343 * 100%;

    background-image: url("~@/assets/img/common/module-bg--343-136.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    margin-top: 10px;

    .card {
      position: absolute;
      left: 18px;
      top: 10%;
      right: 18px;
      bottom: 18px;

      .card__link {
        margin-top: 20px;
        margin-bottom: 25px;

        .card__link__item {
          margin-bottom: 20px;

          .card__link__item__title {
            margin-bottom: 20px;

            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 600;
          }
          .card__link__item__content {
            display: flex;
            align-items: center;

            .content__input-wrapper {
              flex: 1;
              overflow: hidden;

              border: 0.1px solid rgba(89, 155, 228, 0.6);
              background: rgba(13, 20, 26, 0.5);

              padding: 0 14px;

              .content__input-wrapper__input {
                width: 100%;

                line-height: 44px;

                background: none;
                border: none;
                outline: none;
                border-radius: 0;

                color: var(--gray2, #4f7387);
                font-family: "PingFang SC";
                font-size: 12px;
                font-weight: 400;
              }
            }
            .card__link__item__content__copy-btn {
              width: 80px;
              line-height: 40px;

              margin-left: 14px;

              background-image: url("~@/assets/img/common/btn-bg--yellow--80-40.png");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;

              color: var(--black1, #0d141a);
              text-align: center;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }
    }
  }
}
</style>
  