<template>
  <input
    v-if="showInput"
    class="input-ref"
    type="file"
    ref="uploadInput"
    @change="handleFileChange"
    :accept="accept"
  />
</template>
  
<script>
export default {
  name: "UploadInput",
  props: {
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      file: null,
      previewUrl: null,
      showInput: true,
    };
  },
  mounted() {
    this.$emit("triggerUpload", ({ onSelectedFile }) => {
      this.$refs.uploadInput.click();
      this.onSelectedFile = onSelectedFile;
    });
  },
  methods: {
    handleFileChange(e) {
      const files = e.target.files;
      if (files && files[0]) {
        const file = files[0]

        this.onSelectedFile && this.onSelectedFile(file);
      }

      this.showInput = null;
      this.$nextTick(() => this.showInput = true);
    },
  },
};
</script>
  
  <style lang="less" scoped>
.input-ref {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 0;
  height: 0;
}
</style>