<template>
  <div class="responsive-container">
    <div v-if="roomData">
      <HeaderCard :roomData="roomData" :addRoom="addRoom"></HeaderCard>
      <WinningRecordModule :roomData="roomData"></WinningRecordModule>
      <PrizeRecordModule :roomData="roomData"></PrizeRecordModule>
      <PlayerModule :roomData="roomData"></PlayerModule>
    </div>
    <InputRoomPasswordDrawer
      v-if="inputRoomPasswordDrawerConfig"
      v-bind="inputRoomPasswordDrawerConfig"
    ></InputRoomPasswordDrawer>
  </div>
</template>

<script>
import HeaderCard from "./components/headerCard/index";
import WinningRecordModule from "./components/winningRecordModule/index";
import PrizeRecordModule from "./components/prizeRecordModule/index";
import PlayerModule from "./components/playerModule/index";
import InputRoomPasswordDrawer from "./components/inputRoomPasswordDrawer/index";

export default {
  name: "arenaRoom",
  components: {
    HeaderCard,
    WinningRecordModule,
    PrizeRecordModule,
    PlayerModule,
    InputRoomPasswordDrawer,
  },
  data() {
    return {
      id: "",
      roomData: null,
      inputRoomPasswordDrawerConfig: null,
    };
  },
  mounted() {
    // console.log(this.$route.query.id);
    this.id = this.$route.query.id;
    this.$nextTick(()=>{

      this.getRoomList();
    })
  },
  methods: {
    //加入房间
    async addRoom() {
      if (
        this.roomData.condition_type == 1 ||
        this.roomData.condition_type == 3
      ) {
        this.inputRoomPasswordDrawerConfig = {
          id: this.id,
          onClosed: () => (this.inputRoomPasswordDrawerConfig = null),
          onOK: () => this.getRoomList(),
        };
        return;
      } else {
        var param = {
          free_room_id: this.id,
        };
      }

      this.$axios
        .post("index/Free/addFreeRoom", this.$qs.stringify(param))
        .then((res) => {
          //console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.getRoomList();
          } else {
            this.$message({
              message: data.msg,
              type: "error",
            });
          }
        });
    },
    //房间数据
    getRoomList() {
      let param = {
        free_room_id: this.id,
      };
      this.$axios
        .post("index/Free/freeRoomDetail", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.roomData = data.data;
          }
        });
    },
  },
};
</script>

<style lang='less' scoped>
</style>
