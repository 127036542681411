export function generateMainNavList($this) {
    return [
        {
          name: "商城",
          image: require("@/assets/img/home/mall.png"),
          onTap: (current) => {
            $this.$router.push({ path: "/Roll" });
          },
        },
        {
          name: "roll房",
          image: require("@/assets/img/home/roll.png"),
          onTap: (current) => {
            $this.$router.push({ path: "/Arena" });
          },
        },
        {
          name: "CD-KEY",
          image: require("@/assets/img/home/cdk.png"),
          onTap: (current) => {
            $this.$router.push({ path: "/Payment?type=cdk" });
          },
        },
        {
          name: "客服",
          image: require("@/assets/img/home/kefu.png"),
          type: 2,
          onTap() {
            window.open(
              "http://kefu.duomifreight.com/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=6&groupid=0&special=11&theme=7571f9"
            );
          },
        },
        {
          name: "取回助手",
          image: require("@/assets/img/home/quhui.png"),
          onTap: (current) => {
            $this.$emit("backAction", true);
          },
        },
        {
          name: "盲盒对战",
          image: require("@/assets/img/home/box.png"),
          onTap: () => {
            $this.$router.push({ path: "/Lucky" });
          },
        },
        {
          name: "幸运饰品",
          image: require("@/assets/img/home/shipin.png"),
          onTap: () => {
            $this.$router.push({ path: "/Ornament" });
          },
        },
        {
          name: "活动推广",
          image: require("@/assets/img/home/tuiguang.png"),
          onTap: () => {
            $this.$router.push({ path: "/Spread" });
          },
        },
        {
          name: "饰品背包",
          image: require("@/assets/img/home/bag.png"),
          onTap: () => {
            $this.$router.push({ path: "/Dota" });
          },
        },
      ]
}