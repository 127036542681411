<template>
  <div class="responsive-container dota">
    <div class="module-title">饰品背包</div>
    <div class="module-desc">
      <div class="module-desc__title">取回注意事项：</div>
      <div class="module-desc__text">
        拒绝收货超过3次，账户会被冻结12小时，取回后需要快速发货，联系客服
      </div>
    </div>
    <div class="dota__btn-list">
      <div class="dota__btn-list__left">
        <div
          class="dota__btn-list__left__btn"
          @click="selectAllGoods"
          :class="{
            'dota__btn-list__left__btn--active':
              selectedGoodsList.length === list.length,
          }"
        >
          选择当前页
        </div>
        <div
          v-if="selectedGoodsList.length > 0"
          class="dota__btn-list__left__btn dota__btn-list__left__btn--active"
          @click="clearAllSelectedGoods"
        >
          取消选择
        </div>
      </div>
      <div class="dota__btn-list__right">
        <div
          class="dota__btn-list__right__btn"
          :class="{
            'dota__btn-list__right__btn--active':
              !isExchangeGoodsLoading && selectedGoodsList.length > 0,
          }"
          @click="coniAll"
        >
          {{ isExchangeGoodsLoading ? "兑换中" : "兑换" }}
        </div>
      </div>
    </div>
    <GoodsList
      :goodsList="list"
      :selectedGoodsIds="selectedGoodsIds"
      :onSelectGoods="onSelectGoods"
      :random="random"
      :getList="getList"
      :randomString="randomString"
    ></GoodsList>
    <div class="roll-page">
      <el-pagination
        background
        :pager-count="5"
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        @current-change="currentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import currency from "currency.js";
import Utils from "@/assets/js/util.js";

import navheader from "@/components/navheader.vue";
import GoodsList from "./goodsList/index";

export default {
  name: "dota",
  components: { navheader, GoodsList },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      isExchangeGoodsLoading: false,
      total: 0,
      page: 1,
      pageSize: 20,
      list: [],
      exchangeHint: "",
      checkedPrice: 0,
      random: "",
      selectedGoodsIds: {},
    };
  },
  methods: {
    selectAllGoods() {
      const selectedGoodsIds = {};

      this.list.forEach((item) => {
        selectedGoodsIds[item.id] = true;
      });

      this.$set(this, "selectedGoodsIds", selectedGoodsIds);

      console.info("selectedGoodsIds", this.selectedGoodsIds);
    },
    clearAllSelectedGoods() {
      this.$set(this, "selectedGoodsIds", {});
    },
    onSelectGoods(goodsId) {
      console.info("goodsId", goodsId);
      this.$set(
        this.selectedGoodsIds,
        goodsId,
        !this.selectedGoodsIds[goodsId]
      );
      console.info("this.selectedGoodsIds", this.selectedGoodsIds);
    },
    goRule(pathName) {
      this.$router.push({
        name: pathName,
      });
    },
    //兑换多个
    async coniAll() {
      try {
        if (this.isExchangeGoodsLoading) return;

        this.isExchangeGoodsLoading = true;

        let param = {
          player_skins_ids: this.selectedGoodsList.map((item) => item.id),
        };
        const res = await this.$axios.post(
          "/index/User/exchangeToMoneynew",
          this.$qs.stringify(param)
        );
        var data = res.data;

        if (data.status == "1") {
          this.$store.commit("getMoney", res.data.data.total_amount);
          Utils.$emit("masonry", res.data.data.total_amount);

          this.getList(1);
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
        } else {
          throw data.msg;
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error,
          type: "error",
        });
      } finally {
        this.isExchangeGoodsLoading = false;
      }
    },
    //分页
    currentChange(val) {
      this.getList(val);
      this.checkedPrice = 0;
    },
    //背包数据
    getList(page) {
      this.page = page;
      let param = {
        page: page,
        pageSize: this.pageSize,
      };
      this.$axios
        .post("/index/User/packageListnew", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.total = data.data.total;
            this.list = data.data.skinList;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
              this.list[i].state1 = false;
              this.list[i].loading1 = false;
              this.list[i].loading2 = false;
            }
            if (data.data.skinList.length == 0) {
              let param = {
                page: this.page - 1,
                pageSize: this.pageSize,
              };
              this.$axios
                .post("/index/User/packageList", this.$qs.stringify(param))
                .then((res) => {
                  // console.log(res.data);
                  var data = res.data;
                  if (data.status == "1") {
                    this.total = data.data.total;
                    this.list = data.data.skinList;
                    for (let i = 0; i < this.list.length; i++) {
                      this.list[i].state = false;
                      this.list[i].state1 = false;
                      this.list[i].loading1 = false;
                      this.list[i].loading2 = false;
                    }
                  }
                });
            }
          } else {
            this.total = 0;
            this.list = [];
          }
        });
    },
    //点击兑换
    getExchange(event, id) {
      event.stopPropagation();
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].state1) {
          this.$message({
            message: "正在取回中，稍后重试",
            type: "warning",
          });
          return;
        }
      }
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].loading1 = true;
          this.list[i].loading2 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_skins_ids: [id],
      };
      this.$axios
        .post("/index/User/exchangeToMoneynew", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$store.commit("getMoney", res.data.data.total_amount);
            Utils.$emit("masonry", res.data.data.total_amount);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
              duration: 800,
            });
          } else {
            for (let i = 0; i < this.list.length; i++) {
              if (id == this.list[i].id) {
                this.list[i].loading1 = false;
                this.list[i].loading2 = false;
              }
            }
            this.$forceUpdate();
            this.$message({
              showClose: true,
              message: "兑换失败，此类饰品不可兑换",
              type: "warning",
              duration: 800,
            });
          }
        });
    },
    //点击取回
    getPull(event, id, steamId) {
      event.stopPropagation();
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].loading1 = true;
          this.list[i].loading2 = true;
          this.list[i].state1 = true;
        }
      }
      this.$forceUpdate();
      let param = {
        player_skins_id: id,
        itemId: steamId,
        random: this.random,
      };
      this.$axios
        .post("/index/User/skinToSteam1new", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.getList(this.page);
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
          } else {
            var hint = "";
            if (data.msg.indexOf("余额不足") != -1) {
              hint = "取回错误，请联系客服";
            } else {
              hint = data.msg;
            }
            this.$message({
              showClose: true,
              message: hint,
              type: "warning",
            });
            for (let i = 0; i < this.list.length; i++) {
              if (id == this.list[i].id) {
                this.list[i].loading1 = false;
                this.list[i].loading2 = false;
                this.list[i].state1 = false;
              }
            }
            this.$forceUpdate();
          }
          this.randomString();
        });
    },
    randomString(e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      this.random = n;
    },
  },
  mounted() {
    this.getList(this.page);
    this.randomString();
  },
  computed: {
    selectedGoodsList() {
      return (this.list || []).filter((item) => this.selectedGoodsIds[item.id]);
    },
    totalSelectedGoodsPrice() {
      const { price } =
        this.selectedGoodsList.length > 0
          ? this.selectedGoodsList.reduce(
              (a, b) => {
                return {
                  price: a.price.add(b.price),
                };
              },
              {
                price: currency(0),
              }
            )
          : {
              price: currency(0),
            };

      return price;
    },
  },
};
</script>

<style lang="less" scoped>
.dota {
  padding: 16px;
  .module-title {
    padding-bottom: 10px;

    color: var(--gary3, #889aa7);
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .module-desc {
    padding: 12px;
    background: rgb(13, 20, 26, 0.5);

    .module-desc__title {
      color: var(--gary3, #889aa7);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .module-desc__text {
      color: var(--gary3, #889aa7);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 166.667% */
    }
  }

  .dota__btn-list {
    display: flex;
    align-items: center;

    margin: 16px 0;

    .dota__btn-list__left {
      display: flex;
      align-items: center;

      flex: 1;

      .dota__btn-list__left__btn {
        margin-right: 10px;

        width: 100px;
        line-height: 40px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        background-image: url("~@/assets/img/com-btn-bg--blue.png");

        color: var(--gray2, #4f7387);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;

        &.dota__btn-list__left__btn--active {
          background-image: url("~@/assets/img/com-btn-bg--yellow.png");

          color: var(--gray4, #dde5e9);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 14px;
          font-weight: 600;
        }
      }
    }
    .dota__btn-list__right {
      display: flex;
      align-items: center;

      .dota__btn-list__right__btn {
        width: 80px;
        line-height: 40px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        text-align: center;

        color: var(--gray2, #4f7387);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        background-image: url("~@/assets/img/common/btn-bg--gray--80-40.png");

        &.dota__btn-list__right__btn--active {
          background-image: url("~@/assets/img/common/btn-bg--yellow--80-40.png");

          color: var(--black1, #0d141a);
        }
      }
    }
  }
}

//分页
.roll-page {
  margin: 10px 0 0px -10px;
  display: flex;
  justify-content: center;
}
.roll-page
  /deep/
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #e9b10e;
  color: #1a1c24;
}
.roll-page /deep/ .el-pagination.is-background .btn-next,
/deep/ .el-pagination.is-background .btn-prev,
/deep/ .el-pagination.is-background .el-pager li {
  background-color: #333542;
  color: #848492;
}
</style>
