<template>
  <div class="price-bar">
    <div class="price-bar__money-icon">
      <img v-if="priceIconType === 'masonry'" class="img-cover" src="~@/assets/img/common/masonry.png" alt="" />
      <img v-else-if="priceIconType === 'goidCoin'" class="img-cover" src="~@/assets/img/home/jinbi.png" alt="" />
    </div>
    <div class="price-bar__price">
      {{ price }}
    </div>
  </div>
</template>

<script>

export default {
  name: "priceBar",
  props: {
    // 可以选  砖石： masonry |  金币： goidCoin
    priceIconType: {
      type: String,
      default: 'goidCoin',
    },
    price: {
      type: [String, Number],
      required: true,
    },
    center: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.price-bar {
  display: flex;
  align-items: center;
  justify-content: center;

  .price-bar__money-icon {
    width: 16px;
    height: 16px;
  }
  .price-bar__price {
    margin-left: 4px;

    color: var(--gray4, #dde5e9);
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
}
</style>
