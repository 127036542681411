<template>
  <div
    v-loading="fetchGoodsLoading"
    element-loading-background="rgba(0, 0, 0, 0)"
    class="responsive-container open-box"
  >
    <div class="responsive-mobile-container">
      <div class="open-box__header__wrapper">
        <div class="open-box__header">
          <div class="open-box__header__bg">
            <img
              class="img-cover"
              src="~@/assets/img/newUserPackage/bg-page-header.png"
              alt=""
            />
          </div>
          <template v-if="boxInfo">
            <div class="header__title">{{ boxInfo.name }}</div>
            <div class="header__main-img">
              <img class="img-cover" :src="boxInfo.img_active" alt="" />
            </div>
            <div class="header__count-tab-list">
              <div
                v-for="item in kaiBoxNum"
                :key="item.num"
                class="count-tab-list__item"
                :class="item.state ? 'count-tab-list__item--active' : ''"
                @click="chooseNum(item.num)"
              >
                {{ item.num }}x
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>

    <div v-if="boxInfo" class="open-box__buy-btn" @click="buyBox()">
      <template v-if="!buyLoading">
        <template v-if="!isNewUser">
          <div class="buy-btn__icon">
            <img class="img-cover" src="@/assets/img/home/jinbi.png" alt="" />
          </div>
          <div class="buy-btn__label">
            {{ (price * kaiBox).toFixed(2) }}购买
          </div>
        </template>
        <div v-else class="buy-btn__label">新用户免费开箱</div>
      </template>
      <div v-else class="buy-btn__label">购买中...</div>
    </div>
    <div v-if="boxInfo" class="open-box__category-bar">
      <div
        v-for="item in categoryList"
        :key="item.value"
        class="category-bar__item"
        :class="{
          'category-bar__item--active': item.value === activeCategory,
        }"
        @click="activeCategory = item.value"
      >
        {{ item.label }}
      </div>
    </div>
    <div v-if="boxInfo" class="open-box__main-content">
      <component
        :is="currentActiveTab.componentName"
        :boxId="boxId"
        :goodsList="boxInfo.box_skins"
        :goodsTypeList="boxInfo.skins_types"
      ></component>
    </div>

    <!-- 分享-->
    <el-dialog
      title="分享链接"
      :visible.sync="dialogFormVisible"
      width="200px"
      class="share-hide"
    >
      <div class="share-btn">
        <el-input v-model="url" autocomplete="off"></el-input>
        <el-button class="btn" type="warning" @click="copyUrl()"
          >复制</el-button
        >
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogFormVisible = false"
          >确 定</el-button
        >
      </div>
    </el-dialog>

    <PrizeAnimated
      v-if="prizeAnimateDialog"
      v-bind="prizeAnimateDialog"
    ></PrizeAnimated>

    <WinRetDialog
      v-if="winRetDialogConfig"
      v-bind="winRetDialogConfig"
      @winexchange="winexchange($event)"
      @winget="winget($event)"
      @winX="winX($event)"
    ></WinRetDialog>
  </div>
</template>

<script>
import myslide from "@/components/my_slide_page.vue";
import mywin from "@/components/my_win.vue";
import Utils from "@/assets/js/util.js";
import RecentlyFalling from "./components/recentlyFalling/index";
import GoodsList from "./components/goodsList/index";
import WinRetDialog from "@/views/openBox/components/winRetDialog/index.vue";

import PrizeAnimated from "@/views/openBox/components/prizeAnimated/index";

const CATEGORY_TYPES = {
  goodsList: "goodsList",
  recentlyFalling: "RecentlyFalling",
};

const categoryList = [
  {
    label: "包含以下皮肤",
    componentName: "GoodsList",
    value: CATEGORY_TYPES.goodsList,
  },
  {
    label: "最近掉落",
    componentName: "RecentlyFalling",
    value: CATEGORY_TYPES.recentlyFalling,
  },
];

export default {
  components: {
    myslide,
    mywin,
    RecentlyFalling,
    GoodsList,
    WinRetDialog,
    PrizeAnimated,
  },
  computed: {
    isNewUser() {
      return this.boxId == "62";
    },
    currentActiveTab() {
      return this.categoryList.find(
        (item) => item.value === this.activeCategory
      );
    },
    boxId() {
      return this.$route.query.box_id;
    },
  },
  props: ["showNav"],
  data() {
    return {
      winRetDialogConfig: null,
      activeCategory: CATEGORY_TYPES.goodsList,
      categoryList,
      openPhone: true,
      img: "",
      img1: require("@/assets/img/1mdpi.png"),
      cartState: false,
      skin_list1: [],
      winBoxState: false,
      buyLoading: false,
      url: window.location.href,
      dialogFormVisible: false,
      funState: true,
      winState: false,
      openBoxState: false,
      box_id: this.$route.query.box_id,
      box_name: "",
      box_obj: {},
      price: 0,
      totalPrice: 0,
      skin_list: [],
      skins_types: [],
      value: true,
      kaiBox: 1,
      kaiBox1: 1,
      kaiBoxNum: [
        {
          num: 1,
          state: true,
        },
        {
          num: 2,
          state: false,
        },
        {
          num: 3,
          state: false,
        },
        {
          num: 4,
          state: false,
        },
        {
          num: 5,
          state: false,
        },
      ],
      listBox: [],
      imgList: [],
      imgList1: [],
      loadFlag: true, // 页面加载Loading标识

      active_type: 0,
      boxInfo: null,
      fetchGoodsLoading: false,
      prizeAnimateDialog: null,
    };
  },
  watch: {
    kaiBox(val) {
      let _this = this;
      _this.totalPrice = (this.price * val).toFixed(2);
    },
    skin_list: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.caculateCardHeight();
          });
        }
      },
      deep: true,
    },
    skin_list1: {
      handler(val) {
        if (val) {
          this.$nextTick(() => {
            this.caculateCardHeight();
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    refreshMoney() {
      this.$parent.selfLogin();
    },
    async getBoxInfo() {
      try {
        let param = {
          box_id: this.boxId,
        };
        this.setFetchBoxInfoLoading = true;
        this.fetchGoodsLoading = true;

        const res = await this.$axios.post(
          "/index/Box/boxInfo",
          this.$qs.stringify(param)
        );

        if (res.data) {
          console.log(res.data);
          const boxInfo = res.data.data;

          if (boxInfo) {
            this.price = this.totalPrice = boxInfo.price;
            this.boxInfo = boxInfo;
          }
        }
      } catch (error) {
        this.$message({
          message: error,
          type: "warning",
        });
      } finally {
        this.fetchGoodsLoading = false;
      }
    },
    caculateCardHeight() {
      const els = document.querySelectorAll(".card");
      if (els[0]) {
        const width = window.getComputedStyle(els[0]).getPropertyValue("width");
        els.forEach((el) => {
          el.style.height = (238 / 154) * width.split("px")[0] + "px";
        });
      }
    },
    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    //音乐 过程
    playAlarm() {
      this.$refs.notify.currentTime = 0;
      this.$refs.notify.play();
    },
    //音乐 过程关闭
    playAlarmStop() {
      this.$refs.notify.pause();
    },
    //音乐 结果
    playAlarm1() {
      this.$refs.notify1.play();
    },
    //音乐 结果暂停
    playAlarm2() {
      this.$refs.notify1.pause();
    },

    //最近掉落
    getWinPeo() {
      this.winBoxState = true;
      let param = {
        page: 1,
        pageSize: 20,
        box_id: this.box_id,
        type: this.active_type,
      };
      this.skin_list1 = [];
      this.$axios
        .post("/index/Box/lately", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.skin_list1 = data.data.list;
          }
        });
    },
    //分享复制
    copyUrl() {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = window.location.href; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
      this.dialogFormVisible = false;
    },
    //购买盲盒
    async buyBox() {
      try {
        this.buyLoading = true;

        let _this = this;
        let param = {
          box_id: _this.$route.query.box_id,
          num: _this.kaiBox,

          cartState: this.cartState,
        };
        const res = await _this.$axios.post(
          "/index/Box/buyBox",
          _this.$qs.stringify(param)
        );
        if (res.data.status == 1) {
          //console.log(res.data);
          this.$store.commit(
            "getMoney",
            Number(res.data.data.total_amount).toFixed(2)
          );
          console.log(res.data.data.total_amount, "钱");
          Utils.$emit("money", res.data.data.total_amount);

          this.buyBoxObj = res;

          this.prizeAnimateDialog = {
            showNav: this.showNav,
            skin_list1: this.skin_list1,
            buyBoxObj: this.buyBoxObj,
            kaiBox: this.kaiBox,
            onAniatendEnd: () => {
              this.prizeAnimateDialog = null;

              openPrizeRetDialog();
            },
          };

          const openPrizeRetDialog = () => {
            const winList = res.data.data;
            this.winRetDialogConfig = {
              winList,
              onClosed: () => {
                this.winRetDialogConfig = null;
              },
            };
          };
          // const winList = res.data.data
          // this.winRetDialogConfig = {
          //   winList,
          //   onClosed: () => {
          //     this.winRetDialogConfig = null;
          //   },
          // };
        } else {
          throw res.data.msg;
        }
      } catch (error) {
        console.error("ererer", error);
        this.$message({
          message: error,
          type: "warning",
        });
      } finally {
        console.info("1111");
        this.buyLoading = false;
      }
    },

    //开盒规则
    goOpenboxRule() {
      this.$router.push({
        path: `/OpenboxRule`,
      });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    chooseNum(num) {
      // 默认只为一个 不做修改了
      this.kaiBox = num;

      for (let i = 0; i < this.kaiBoxNum.length; i++) {
        if (this.kaiBoxNum[i].num <= num) {
          this.kaiBoxNum[i].state = true;
        } else {
          this.kaiBoxNum[i].state = false;
        }
      }
    },
    //子组件修改父组件
    winexchange(msg) {
      this.winState = msg;
    },
    winget(msg) {
      this.winState = msg;
    },
    winX(msg) {
      this.winState = msg;
    },
    setStyle(index, num) {
      let style = document.createElement("style");
      style.setAttribute("type", "text/css");
      document.head.appendChild(style);
      let sheet = style.sheet;
      console.log(index);
      if (index == 1) {
        // let random = Math.floor(Math.random() * 190) + 11905;
        let random = 12000;
        // console.log(random);
        sheet.insertRule(
          `@keyframes run` +
            index +
            `{
                              0% {
                                left: 0;
                              }
                              100% {
                                left: -` +
            random +
            `px
                              }
                          }`,
          0
        );
      } else if (index == 2) {
        for (var i = 0; i < num; i++) {
          if (this.showNav) {
            var random = 8000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 4800;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          console.log(random);
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
                                0% {
                                  left: 0;
                                }
                                100% {
                                  left: -` +
              random +
              `px
                                }
                            }`,
            0
          );
        }
      } else if (index == 3) {
        console.log(num);
        for (var i = 0; i < num; i++) {
          if (this.showNav) {
            var random = 12000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          console.log(random);
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
                              0% {
                                top: 0;
                              }
                              100% {
                                top: -` +
              random +
              `px
                              }
                          }`,
            0
          );
        }
      } else if (index == 4) {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 8000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
                              0% {
                                top: 0;
                              }
                              100% {
                                top: -` +
              random +
              `px
                              }
                          }`,
            0
          );
        }
      } else if (index == 5) {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 6000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
                              0% {
                                top: 0;
                              }
                              100% {
                                top: -` +
              random +
              `px
                              }
                          }`,
            0
          );
        }
      } else {
        for (var i = 0; i < num; i++) {
          if (this.openPhone == true) {
            var random = 6000;
            // var random = Math.floor(Math.random() * 190) + 9905;
          } else {
            //屏幕小于600的宽度
            var random = 6000;
            // var random = Math.floor(Math.random() * 90) + 5955;
          }
          sheet.insertRule(
            `@keyframes run-li` +
              i +
              `{
                              0% {
                                top: 0;
                              }
                              100% {
                                top: -` +
              random +
              `px
                              }
                          }`,
            0
          );
        }
      }
    },
  },
  mounted() {
    //判断屏幕宽度
    // console.log(document.body.clientWidth);
    if (document.body.clientWidth < 600) {
      this.openPhone = false;
    }

    let _this = this;
    _this.getBack();
    // _this.setStyle(5,5)

    //计算卡片高度
    window.addEventListener("resize", () => {
      this.caculateCardHeight();
    });

    this.getBoxInfo();
  },
};
</script>
<style lang="less" scoped>
.open-box {
  .open-box__header__wrapper {
    position: relative;
    padding-bottom: 415 / 375 * 100%;
    z-index: 1;

    .open-box__header {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;


      .open-box__header__bg {
        position: absolute;
        z-index: -1;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
      }

      .header__title {
        margin-top: 8%;

        color: #fff;
        text-align: center;
        font-family: "Alibaba PuHuiTi";
        font-size: 18px;
        font-weight: 400;
      }
      @keyframes jump {
        0%, 100% { 
          transform: translateY(0) scale(1); 
        }
        50% { 
          transform: translateY(-20px) scale(1.1); 
        }
      }
      .header__main-img {
        margin: 12% auto 22%;

        width: 145px;
        height: 110px;
        animation: jump 2s infinite;
      }
      .header__count-tab-list {
        display: flex;
        align-items: center;
        justify-content: center;

        .count-tab-list__item {
          margin: 0 12px;

          width: 44px;
          height: 44px;

          background-image: url("~@/assets/img/newUserPackage/bg-item--blue.png");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          color: #fff;
          text-align: center;
          font-family: "PingFang SC";
          font-size: 16px;
          font-weight: 600;

          &.count-tab-list__item--active {
            background-image: url("~@/assets/img/newUserPackage/bg-item--yellow.png");
          }
        }
      }
    }
  }

  .open-box__buy-btn {
    position: relative;
    z-index: 1;

    width: 148px;
    height: 44px;

    background-image: url("~@/assets/img/common/btn-bg--yellow-148-44.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    align-items: center;
    justify-content: center;

    margin: auto;
    margin-bottom: 20px;
    margin-top: -10%;

    .buy-btn__icon {
      margin-right: 6px;

      width: 20px;
      height: 20px;
    }
    .buy-btn__label {
      color: var(--black1, #0d141a);
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: 600;
    }
  }

  .open-box__category-bar {
    position: relative;
    z-index: 1;
    display: flex;
    align-items: center;

    padding: 0 12px;
    .category-bar__item {
      background-image: url("~@/assets/img/newUserPackage/bg-tab--deep-blue.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      width: 140px;
      height: 40px;

      display: flex;
      align-items: center;
      justify-content: center;

      color: var(--gray2, #4f7387);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-weight: 600;

      // 样式
      &.category-bar__item--active {
        background-image: url("~@/assets/img/newUserPackage/bg-tab--blue.png");

        color: var(--white, #fff);
      }
    }
  }

  .open-box__main-content {
    margin: 0 12px;
    background: #090e14;
    padding: 12px;
  }
}
</style>
  

  