import { render, staticRenderFns } from "./LuckyHistory.vue?vue&type=template&id=47d97ed0&scoped=true"
import script from "./LuckyHistory.vue?vue&type=script&lang=js"
export * from "./LuckyHistory.vue?vue&type=script&lang=js"
import style0 from "./LuckyHistory.vue?vue&type=style&index=0&id=47d97ed0&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47d97ed0",
  null
  
)

export default component.exports