export function battle() {
  // const wsuri = 'wss://api.dmcs-go.com/wss/' // 正式环境
  const wsuri = 'ws://47.128.254.205:8091/ws/'
  return wsuri
}
export function openBox() {
  // const wsuri = 'wss://api.dmcs-go.com/wss/' // 正式环境
  const wsuri = 'ws://47.128.254.205:8091/ws/'
  return wsuri
}
