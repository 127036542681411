<template>
  <DrawerPop
    slideForm="center"
    :visable="visableDrawer"
    :onClosed="onClosed"
    :changeVisable="(events) => (visableDrawer = !visableDrawer)"
  >
    <div class="confirm-drawer">
      <div class="confirm-drawer__close-btn" @click="visableDrawer = false">
        <img class="img-cover" src="~@/assets/img/common/close-btn.png" alt="" />
      </div>
      <div class="confirm-drawer__title">
        {{ title }}
      </div>
      <div class="confirm-drawer__sub-title">{{ subTitle }}</div>
      <div class="confirm-drawer__footer">
        <div
          v-if="cancelBtnConfig"
          class="confirm-drawer__footer__btn"
          @click="onCancel"
        >
          {{ cancelBtnConfig.label || "取消" }}
        </div>
        <div
          v-if="okBtnConfig"
          class="confirm-drawer__footer__btn confirm-drawer__footer__btn--ok"
          @click="onOK"
        >
          {{ okBtnConfig.label || "确定" }}{{ isLoading ? '...' : '' }}
        </div>
      </div>
    </div>
  </DrawerPop>
</template>

<script>
import DrawerPop from "@/components/drawerPop/index";

export default {
  name: "confirmDialog",
  components: {
    DrawerPop,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      default: "",
    },
    /**
    * @feat <>
        cancelBtnConfig: {
            label?: string
            onCancel?: void | Promise<void>
        }
    */
    cancelBtnConfig: {
      type: Object,
    },
    /**
    * @feat <>
        okBtnConfig: {
            label?: string
            onOK: void | Promise<void>
        }
    */
    okBtnConfig: {
      type: Object,
    },
    onClosed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      visableDrawer: false,
      passAddRoomLoading: false,
      isLoading: false,
    };
  },
  mounted() {
    this.visableDrawer = true;
  },
  methods: {
    async onCancel() {
      try {
        if (this.cancelBtnConfig && this.cancelBtnConfig.onCancel) {
            await this.cancelBtnConfig.onCancel();
        }
        this.visableDrawer = false;
      } catch (error) {
        throw error;
      }
    },
    async onOK() {
      try {
        this.isLoading = true;

        if (this.okBtnConfig && this.okBtnConfig.onOK) {
          await this.okBtnConfig.onOK();
        }
        this.visableDrawer = false;
      } catch (error) {
        throw error;
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.confirm-drawer {
  position: relative;
  background-color: #141d26;

  background-image: url("~@/assets/img/common/confirm-dialog-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  width: 316px;
  height: 204px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .confirm-drawer__close-btn {
    position: absolute;
    right: 0;
    top: 0;
    width: 36px;
    height: 36px;
  }

  .confirm-drawer__title {
    margin-top: 24px;
    margin-bottom: 24px;

    color: var(--gray4, #dde5e9);
    font-family: "Alibaba PuHuiTi";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
  }

  .confirm-drawer__sub-title {
    text-align: center;

    color: var(--gray4, #dde5e9);
    font-family: "PingFang SC";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .confirm-drawer__footer {
    margin-top: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    padding: 36px 0;

    .confirm-drawer__footer__btn {
      margin: 0 10px;

      width: 120px;
      line-height: 44px;

      color: var(--white, #fff);
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      background-image: url("~@/assets/img/common/btn-bg--gray.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      &.confirm-drawer__footer__btn--ok {
        color: #0d141a;

        background-image: url("~@/assets/img/common/btn-bg--yellow.png");
      }
    }
  }
}
</style>
