<template>
  <div class="history-card__wrapper">
    <div class="history-card" @click.prevent.stop="jionRoom(roomDetails)">
      <div class="history-card__content">
        <img class="img-cover" src="@/assets/img/room/card-bg.png" alt="" />
      </div>
      <div class="history-card__content">
        <div class="history-card__content__header-bar">
          <div class="header-bar__user-img">
            <img class="img-cover" :src="roomDetails.img" alt="" />
          </div>
          <div class="user-name">{{ roomDetails.room_name }}</div>
          <div v-if="roomDetails.type === 1" class="user—id-icon">
            <img class="img-cover" src="@/assets/img/room/office.png" alt="" />
          </div>
        </div>
        <div class="history-card__content__body">
          <div class="prize-list__wrapper">
            <div class="prize-list">
              <div
                v-for="(item1, index1) in roomDetails.skin_list"
                :key="index1"
                class="prize-list__item-wrapper"
              >
                <div class="prize-list__item-wrapper__inner">
                  <div class="inner__item">
                    <div class="inner__item__content">
                      <div class="inner__item__content__main-img">
                        <img class="img-cover" :src="item1.imageUrl" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info-bar">
            <div class="info-bar__item">
              <div class="info-bar__item__label">奖池:</div>
              <div class="info-bar__item__value">{{ roomDetails.pool }}</div>
            </div>
            <div class="info-bar__item">
              <div class="info-bar__item__label">件数:</div>
              <div class="info-bar__item__value">{{ roomDetails.count }}</div>
            </div>
            <div class="info-bar__item">
              <div class="info-bar__item__label">人数:</div>
              <div class="info-bar__item__value">{{ roomDetails.person_num }}</div>
            </div>
          </div>
        </div>

        <div class="footer-bar">
          <div class="footer-bar__label">
            开奖时间: {{ roomDetails.run_lottery_time }}
          </div>
          <RoomStatusTag :roomDetails="roomDetails"></RoomStatusTag>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RoomStatusTag from '@/components/roomStatusTag/index'

export default {
  name: "roomCard",
  components: {
    RoomStatusTag
  },
  props: {
    roomDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    jionRoom(room) {
      console.log(room.id)
      if (!this.$store.state.token) {
        this.$store.commit("getLogin", true);
        return;
      }
      this.$router.push({
        path: `/ArenaRoom`,
        query: {
          id: room.id,
        },
      });
    },
  },
};
</script>

<style lang='less' scoped>
.history-card__wrapper {
  margin: 10px 20px;
  .history-card {
    position: relative;
    padding-bottom: 74.05%;

    .history-card__content {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      .history-card__content__header-bar {
        position: relative;
        z-index: 1;

        display: flex;
        align-items: center;

        margin: 4% 14px;

        &::after {
          position: absolute;
          z-index: -1;
          left: 35px;
          top: 0;
          bottom: 0;

          content: "";
          display: block;

          margin: auto;

          width: 160px;
          height: 28px;

          opacity: 0.3;
          background: linear-gradient(
            90deg,
            #599be4 7.58%,
            rgba(89, 155, 228, 0) 91.21%
          );
        }

        .header-bar__user-img {
          width: 40px;
          height: 40px;
          flex-shrink: 0;

          border-radius: 40px;
          border: 1px solid var(--blue, #599be4);
          background-color: #599be4;
          overflow: hidden;
        }
        .user-name {
          margin-left: 8px;
          margin-right: 10px;

          color: var(--gray4, #dde5e9);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
        .user—id-icon {
          width: 28px;
          height: 14px;
        }
      }
      .history-card__content__body {
        margin: 0 10px;

        .prize-list__wrapper {
          overflow: hidden;

          .prize-list {
            display: flex;
            align-items: center;
            margin-right: -8px;

            .prize-list__item-wrapper {
              width: 33.33%;

              .prize-list__item-wrapper__inner {
                margin-right: 8px;

                .inner__item {
                  position: relative;
                  padding-bottom: 70.8%;

                  .inner__item__content {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    background-image: url("~@/assets/img/room/prize-item-bg.png");
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: cover;

                    .inner__item__content__main-img {
                      width: 74px;
                      height: 56px;
                    }
                  }
                }
              }
            }
          }
        }

        .info-bar {
          display: flex;
          align-items: center;

          margin-top: 3.5%;

          .info-bar__item {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            .info-bar__item__label {
              color: var(--gary3, #889aa7);
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
            .info-bar__item__value {
              color: var(--yellow, #f8c544);
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }

      .footer-bar {
        position: absolute;
        left: 14px;
        right: 20px;
        bottom: 0;

        height: 24%;

        display: flex;
        align-items: center;

        .footer-bar__label {
          flex: 1;

          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
       
      }
    }
  }
}
</style>
