var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"openbox"},[_c('div',{staticClass:"kai-box"},[(_vm.openBoxState && _vm.kaiBox == 1)?_c('div',{class:[_vm.showNav == false ? 'kai-box-open2m' : 'kai-box-open2']},[_c('div',{staticClass:"kai-warp11"},[_c('img',{staticClass:"dingbu",attrs:{"src":require("@/assets/img/dingbu.png"),"alt":""}}),_c('img',{staticClass:"kaibox-line",attrs:{"src":require("@/assets/img/shuxian.png"),"alt":""}}),_c('ul',_vm._l((_vm.imgList),function(item,index){return _c('li',{key:index,style:({
                backgroundImage: 'url(' + item.background + ')',
              })},[_c('img',{attrs:{"src":item.img}})])}),0),_c('img',{staticClass:"dibu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}})])]):_vm._e(),(_vm.openBoxState && _vm.kaiBox == 2)?_c('div',{class:[_vm.showNav == false ? 'kai-box-open22m' : 'kai-box-open22']},[_c('div',{staticClass:"kai-warp11"},[_c('img',{staticClass:"dingbu",attrs:{"src":require("@/assets/img/dingbu.png"),"alt":""}}),_c('img',{staticClass:"kaibox-line",attrs:{"src":require("@/assets/img/shuxian.png"),"alt":""}}),_c('div',{class:{
              'kaibox-warp kaibox-warp2 ': _vm.kaiBox == 2,
            }},[_c('ul',{staticClass:"kaibox-ul"},_vm._l((_vm.imgList1),function(item,index){return _c('li',{key:index,class:['kaibox-li', 'li' + _vm.kaiBox]},[_c('ul',_vm._l((item),function(item1,index1){return _c('li',{key:index1,style:({
                      backgroundImage: 'url(' + item1.background + ')',
                    }),attrs:{"data":item1.name}},[_c('img',{class:{
                        'kaibox-img2': _vm.kaiBox == 2,
                      },attrs:{"src":item1.img}})])}),0)])}),0)]),_c('img',{staticClass:"dibu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}})])]):_vm._e(),(_vm.openBoxState && _vm.kaiBox == 3)?_c('div',{class:[_vm.showNav == false ? 'kai-box-open3m' : 'kai-box-open3']},[_c('div',{staticClass:"kai-warp1"},[_c('img',{staticClass:"dingbu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}}),_c('img',{staticClass:"kaibox-line",attrs:{"src":require("@/assets/img/shuxian.png"),"alt":""}}),_c('div',{class:{
              'kaibox-warp kaibox-warp3': _vm.kaiBox == 3,
            }},[_c('ul',{staticClass:"kaibox-ul"},_vm._l((_vm.imgList1),function(item,index){return _c('li',{key:index,staticClass:"kaibox-li",style:({
                  animation: 'run-li' + 1 + ' 7.5s',
                })},[_c('ul',_vm._l((item),function(item1,index1){return _c('li',{key:index1,style:({
                      backgroundImage: 'url(' + item1.background + ')',
                    }),attrs:{"data":item1.name}},[_c('img',{class:{
                        'kaibox-img3': _vm.kaiBox == 3,
                      },attrs:{"src":item1.img}})])}),0)])}),0)]),_c('img',{staticClass:"dibu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}})])]):_vm._e(),(_vm.openBoxState && _vm.kaiBox == 4)?_c('div',{class:[_vm.showNav == false ? 'kai-box-open4m' : 'kai-box-open4']},[_c('div',{staticClass:"kai-warp1"},[_c('img',{staticClass:"dingbu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}}),_c('img',{staticClass:"kaibox-line",attrs:{"src":require("@/assets/img/shuxian.png"),"alt":""}}),_c('div',{class:{
              'kaibox-warp kaibox-warp4': _vm.kaiBox == 4,
            }},[_c('ul',{staticClass:"kaibox-ul"},_vm._l((_vm.imgList1),function(item,index){return _c('li',{key:index,staticClass:"kaibox-li",style:({
                  animation: 'run-li' + 1 + ' 7.5s',
                })},[_c('ul',_vm._l((item),function(item1,index1){return _c('li',{key:index1,style:({
                      backgroundImage: 'url(' + item1.background + ')',
                    }),attrs:{"data":item1.name}},[_c('img',{class:{
                        'kaibox-img4': _vm.kaiBox == 4,
                      },attrs:{"src":item1.img}})])}),0)])}),0)]),_c('img',{staticClass:"dibu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}})])]):_vm._e(),(_vm.openBoxState && _vm.kaiBox == 5)?_c('div',{class:[_vm.showNav == false ? 'kai-box-open5m' : 'kai-box-open5']},[_c('div',{staticClass:"kai-warp1"},[_c('img',{staticClass:"dingbu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}}),_c('img',{staticClass:"kaibox-line",attrs:{"src":require("@/assets/img/shuxian.png"),"alt":""}}),_c('div',{class:{
              'kaibox-warp kaibox-warp5': _vm.kaiBox == 5,
            }},[_c('ul',{staticClass:"kaibox-ul"},_vm._l((_vm.imgList1),function(item,index){return _c('li',{key:index,staticClass:"kaibox-li",style:({
                  animation: 'run-li' + 1 + ' 7.5s',
                })},[_c('ul',_vm._l((item),function(item1,index1){return _c('li',{key:index1,style:({
                      backgroundImage: 'url(' + item1.background + ')',
                    }),attrs:{"data":item1.name}},[_c('img',{class:{
                        'kaibox-img5': _vm.kaiBox == 5,
                      },attrs:{"src":item1.img}})])}),0)])}),0)]),_c('img',{staticClass:"dibu",attrs:{"src":require("@/assets/img/dibu.png"),"alt":""}})])]):_vm._e()]),_c('audio',{ref:"notify",staticClass:"audio",staticStyle:{"display":"none"},attrs:{"controls":""}},[_c('source',{attrs:{"src":require("@/assets/audio/open_box_scroll.7134f.mp3")}})]),_c('audio',{ref:"notify1",staticClass:"audio",staticStyle:{"display":"none"},attrs:{"controls":""}},[_c('source',{attrs:{"src":require("@/assets/audio/14131.mp3")}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }