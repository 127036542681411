<template>
  <DrawerPop
    slideForm="center"
    :visable="visableDrawer"
    :onClosed="onClosed"
    :changeVisable="(events) => (visableDrawer = !visableDrawer)"
  >
    <div style="width: 300px;" class="animate__animated animate__bounceIn">
      <div class="redPackage-dialog__wrapper">
        <div class="redPackage-dialog__wrapper__content">
          <div class="close-btn" @click="visableDrawer = false">
            <img
              class="img-cover"
              src="~@/assets/img/common/btn-close--red.png"
              alt=""
            />
          </div>
          <div class="redPackage-dialog">
            <div class="redPackage-dialog__small-title">
              <div class="redPackage-dialog__small-title__label">加QQ群：</div>
              <div class="redPackage-dialog__small-title__value">313602570</div>
              <div class="redPackage-dialog__small-title__label">
                领更多福利
              </div>
            </div>
            <div class="redPackage-dialog__input-code">
              <input
                v-model="redPackageCode"
                class="redPackage-dialog__input-code__input"
                type="text"
                placeholder="请输入红包口令"
              />
            </div>
            <div
              class="redPackage-dialog__submit-btn"
              @click="receiveRedPackage"
            >
              {{ receiveRedPackageLoading ? "领取中..." : "领取红包" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </DrawerPop>
</template>
    
  <script>
import DrawerPop from "@/components/drawerPop/index";

export default {
  name: "receiveRedPackageDialog",
  components: {
    DrawerPop,
  },
  props: {
    onClosed: {
      type: Function,
      required: true,
    },
    onReceiveRedPackage: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      visableDrawer: false,
      redPackageCode: "",
      receiveRedPackageLoading: false,
    };
  },
  mounted() {
    this.visableDrawer = true;
  },
  methods: {
    async receiveRedPackage() {
      try {
        if (this.receiveRedPackageLoading) return;
        this.receiveRedPackageLoading = true;

        const redPackageCode = this.redPackageCode.trim();

        if (!redPackageCode) throw String("请输入红包口令");

        await this.onReceiveRedPackage(redPackageCode);

        this.visableDrawer = false;
      } catch (error) {
        this.$message({
          message: error,
          type: "warning",
        });
        throw error;
      } finally {
        this.receiveRedPackageLoading = false;
      }
    },
  },
};
</script>
    
<style lang='less' scoped>
.redPackage-dialog__wrapper {
  position: relative;
  padding-bottom: 444 / 310 * 100%;

  background-image: url("~@/assets/img/lucky/bg-redPackage.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .redPackage-dialog__wrapper__content {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    
    .close-btn {
      position: absolute;
      right: -6px;
      top: -6px;

      width: 36px;
      height: 36px;
    }

    .redPackage-dialog {
      padding: 0 40px;

      .redPackage-dialog__small-title {
        display: flex;
        align-items: center;
        padding-top: 90%;
        padding-bottom: 4%;

        .redPackage-dialog__small-title__label {
          color: #fff;
          font-family: "PingFang SC";
          font-size: 14px;
          font-weight: 600;
          // 样式
        }
        .redPackage-dialog__small-title__value {
          margin-right: 10px;

          color: var(--yellow, #f8c544);
          font-family: "PingFang SC";
          font-size: 14px;
          font-weight: 600;
        }
      }

      .redPackage-dialog__input-code {
        background: #9d3d14;
        padding: 0 12px;
        color: var(--white, #fff);

        .redPackage-dialog__input-code__input {
          width: 100%;

          line-height: 56px;

          background: none;
          border: none;
          outline: none;
          border-radius: 0;

          color: var(--white, #fff);
          font-family: "PingFang SC";
          font-size: 14px;
          font-weight: 400;

          &.redPackage-dialog__input-code__input::placeholder {
            color: rgba(255, 255, 255, 0.5);
          }
        }
      }

      .redPackage-dialog__submit-btn {
        margin-top: 35px;

        line-height: 44px;

        background: var(--yellow, #f8c544);

        color: var(--black1, #0d141a);
        text-align: center;
        font-family: "PingFang SC";
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
}
</style>
  