<template>
  <div class="responsive-container hist">
    <div class="hist-warp">
      <div class="btn-list-wrapper">
        <div class="btn-list">
          <div
            class="btn-list__item"
            v-for="(item, index) in tabList"
            :key="item.value"
          >
            <div class="item__out-wrapper">
              <div class="item__out-wrapper__inner-wrapper">
                <div
                  class="item__out-wrapper__inner-wrapper__content"
                  :class="
                    index == activeIndex &&
                    'item__out-wrapper__inner-wrapper__content--active'
                  "
                  @click="onChange(index)"
                >
                  {{ item.label }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="table__out-wrapper">
        <div
          class="table__wrapper"
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)"
        >
          <el-table
            v-if="activeIndex == 0"
            :data="tableData"
            style="width: 100%"
            class="table"
          >
            <el-table-column prop="create_time" label="日期"></el-table-column>
            <el-table-column prop="mode" label="充值方式">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.mode | filterMode }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="money" label="充值金额">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.money }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="status" label="充值结果">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.status | filterStatus }}
                </div>
              </template>
            </el-table-column>
          </el-table>
          <el-table
            v-if="activeIndex == 1"
            :data="tableData"
            style="width: 100%"
            class="table"
          >
            <el-table-column prop="create_time" label="日期"></el-table-column>
            <el-table-column prop="amount" label="类型">
              <template slot-scope="scope">
                <div v-if="scope.row.amount >= 0" :style="{ color: '#EB5757' }">
                  收入
                </div>
                <div v-else :style="{ color: '#68E459' }">支出</div>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="描述">
              <template slot-scope="scope">
                <div>
                  {{ scope.row.type | filterType }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="amount" label="数量"></el-table-column>
            <el-table-column prop="total_amount" label="余额"></el-table-column>
          </el-table>
          <el-table
            v-if="activeIndex == 2"
            :data="tableData"
            style="width: 100%"
            class="table"
          >
            <el-table-column prop="create_time" label="日期"></el-table-column>
            <el-table-column prop="price" label="支付"></el-table-column>
            <el-table-column prop="img" label="购买物品">
              <template slot-scope="scope">
                <img class="goods_img" :src="scope.row.img" />
              </template>
            </el-table-column>
          </el-table>
          <!-- <table class="table">
            <tr>
              <th
                class="table__th"
                v-for="item in currentTabConfig.tableConfigList"
                :key="item.prop"
              >
                {{ item.label }}
              </th>
            </tr>
            <tr class="table__tr" v-for="row in tableDatasource" :key="row.id">
              <td
                v-for="item in currentTabConfig.tableConfigList"
                :key="item.prop"
                class="table__tr__td"
                :style="item.getCellStyle && item.getCellStyle(row)"
              >
                <div
                  v-if="item.slotName === 'goodsImg'"
                  class="table__tr__td__img"
                >
                  <img class="img-cover" :src="row[item.prop]" alt="" />
                </div>
                <div v-else>
                  {{ row[item.prop] }}
                </div>
              </td>
            </tr>
          </table> -->
        </div>
      </div>

      <div class="roll-page" v-if="tableData.length > 0">
        <el-pagination
          background
          layout="prev, pager, next"
          :pager-count="5"
          @size-change="sizeChange"
          @current-change="currentChange"
          :page-size="Pager.pageSize"
          :current-page="Pager.page"
          :total="Pager.totalSize"
        ></el-pagination>
        <!-- <el-pagination
          background
          :pager-count="5"
          layout="prev, pager, next"
          :total="totalSize"
          :page-size="pageSize"
          @current-change="onTabChange(currentTabConfig)"
        >
        </el-pagination> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabList: [
        {
          label: "充值流水",
          value: "one",
        },
        {
          label: "余额流水",
          value: "three",
        },
        {
          label: "饰品商城",
          value: "two",
        },
      ],
      tableData: [],
      Pager: {
        page: 1,
        pageSize: 10,
        totalSize: 0,
      },
      activeName: "one",
      page: 1,
      pageSize: 10,
      totalSize: 0,
      tableDatasource: [],
      activeIndex: 0,
      loading: false,
    };
  },
  mounted() {
    // this.onTabChange(this.currentTabConfig);

    this.init(this.activeIndex);
  },
  filters: {
    filterType(type) {
      let value = "";
      if (type == 1) {
        value = "皮肤兑换Z币";
      } else if (type == 2) {
        value = "福利赠送";
      } else if (type == 3) {
        value = "充值";
      } else if (type == 4) {
        value = "对战失败";
      } else if (type == 5) {
        value = "对战存在多个平局赢家平分输家的钱";
      } else if (type == 7) {
        value = "幸运饰品失败";
      } else if (type == 8) {
        value = "cdk兑换";
      } else if (type == -1) {
        value = "购买盲盒";
      } else if (type == -2) {
        value = "加入对战房间";
      } else if (type == -3) {
        value = "购买幸运饰品";
      } else if (type == -4) {
        value = "商城购买饰品";
      }

      return value;
    },
    filterMode(mode) {
      let value = "";
      if (mode == "zhifubao") {
        value = "支付宝充值";
      } else if(mode == "wechat"){
        value = "微信充值";
      } else {
        value = "CDK充值";
      }

      return value;
    },
    filterStatus(status) {
      let value = "";
      if (status == 1) {
        value = "未支付";
      } else if (status == 2) {
        value = "代支付";
      } else if (status == 3) {
        value = "支付成功";
      } else {
        value = "支付失败";
      }

      return value;
    },
  },
  methods: {
    init(index) {
      this.loading = true;
      if (index == 0) {
        this.getRecharge();
      } else if (index == 1) {
        this.getBalanceDetail();
      } else {
        this.getSkinBoughtskinBought();
      }
    },
    onChange(index) {
      console.log(index);
      this.activeIndex = index;
      this.Pager.page = 1;
      this.init(this.activeIndex);
    },
    getSkinBoughtskinBought() {
      let param = {
        page: this.Pager.page,
        pageSize: this.Pager.pageSize,
      };
      this.tableData = [];

      this.$axios
        .post("index/User/skinBought", this.$qs.stringify(param))
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.status == 1) {
            console.log("skinBought");
            if (data.data.list && data.data.list.length > 0) {
              this.tableData = data.data.list;
              console.log(this.tableData);
              this.Pager.totalSize = data.data.total;
            }
          }
        });
    },
    /**
     * 获取余额流水
     */
    getBalanceDetail() {
      let param = {
        page: this.Pager.page,
        pageSize: this.Pager.pageSize,
      };
      this.tableData = [];
      this.$axios
        .post("index/User/balanceDetail", this.$qs.stringify(param))
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.status == 1) {
            console.log("balanceDetail");
            if (data.data.list && data.data.list.length > 0) {
              this.tableData = data.data.list;
              console.log(this.tableData);
              this.Pager.totalSize = data.data.total;
            }
          }
        });
    },
    /**
     * 充值流水
     */
    getRecharge() {
      let param = {
        page: this.Pager.page,
        pageSize: this.Pager.pageSize,
      };
      this.tableData = [];
      this.$axios
        .post("index/User/recharge", this.$qs.stringify(param))
        .then((res) => {
          this.loading = false;
          let data = res.data;
          if (data.status == 1) {
            console.log("recharge");
            if (data.data.list && data.data.list.length > 0) {
              this.tableData = data.data.list;
              console.log(this.tableData);
              this.Pager.totalSize = data.data.total;
            }
          }
        });
    },
    currentChange(page) {
      // console.log(page);
      this.Pager.page = page;
      this.init(this.activeIndex);
    },
    sizeChange() {},
    async onTabChange(tabConfig) {
      try {
        this.activeName = tabConfig.value;
        this.tableDatasource = [];

        const requestKey = this.requestKey
          ? (this.requestKey += 1)
          : (this.requestKey = 1);

        await this.$nextTick();
        const res = await this.currentTabConfig.onPaginationChange(1);

        if (!res) {
          throw String("数据出错");
        }

        if (requestKey !== this.requestKey) {
          console.info("数据过期");
          return;
        }

        this.tableDatasource = res.list;
        this.totalSize = res.total;
      } catch (error) {
        this.$message({
          message: error,
          type: "error",
        });
      }
    },
    async getTopupList(page) {
      try {
        let param = {
          page: page,
          pageSize: this.pageSize,
        };

        const res = await this.$axios.post(
          "index/User/recharge",
          this.$qs.stringify(param)
        );
        //console.log(res.data);
        var data = res.data;

        this.tableData = data.list;

        if (!(data.status == "1" && data.data != null)) {
          throw String(data.msg);
        }
        const totalSize = data.data.total;
        const statusNameMap = {
          1: {
            label: "未支付",
          },
          2: {
            label: "待支付",
          },
          3: {
            label: "支付成功",
            color: "#599BE4",
          },
        };
        const tableData = data.data.list.map((item) => {
          const currentStatus = statusNameMap[item.status];

          return {
            ...item,
            modeName: item.mode == "zhifubao" ? "支付宝" : "微信",
            statusName: currentStatus ? currentStatus.label : "支付失败",
            statusNameColor: currentStatus ? currentStatus.color : "#EB5757",
          };
        });

        return {
          list: tableData,
          total: totalSize,
        };
      } catch (error) {
        console.error("getTopupList", error);
        throw error;
      }
    },

    async getShoppList(page) {
      try {
        let param = {
          page: page,
          pageSize: this.pageSize,
        };
        this.tableData1 = [];

        const res = await this.$axios.post(
          "index/User/skinBought",
          this.$qs.stringify(param)
        );
        console.info("skinBought", res.data);
        var data = res.data;
        if (!(data.status == "1" && data.data != null)) {
          throw data.msg;
        }

        return {
          list: data.data.list,
          total: data.data.total,
        };
      } catch (error) {
        console.error("getShoppList", error);
        throw error;
      }
    },

    async getBalanceList(page) {
      try {
        let param = {
          page: this.Pager.page,
          pageSize: this.Pager.size,
        };
        const res = await this.$axios.post(
          "index/User/balanceDetail",
          this.$qs.stringify(param)
        );
        //console.log(res.data);
        var data = res.data;
        if (!(data.status == "1" && data.data != null)) {
          throw String(data.msg);
        }

        this.Pager.total = data.data.total;
        const tableData2 = data.data.list;

        for (let i = 0; i < tableData2.length; i++) {
          if (Number(tableData2[i].amount) >= 0) {
            tableData2[i].pay = "收入";
          } else {
            tableData2[i].pay = "支出";
          }

          if (tableData2[i].type == 1) {
            tableData2[i].state = "皮肤兑换Z币";
          } else if (tableData2[i].type == 2) {
            tableData2[i].state = "福利赠送";
          } else if (tableData2[i].type == 3) {
            tableData2[i].state = "充值";
          } else if (tableData2[i].type == 4) {
            tableData2[i].state = "对战失败";
          } else if (tableData2[i].type == 5) {
            tableData2[i].state = "对战存在多个平局赢家平分输家的钱";
          } else if (tableData2[i].type == 7) {
            tableData2[i].state = "幸运饰品失败";
          } else if (tableData2[i].type == 8) {
            tableData2[i].state = "cdk兑换";
          } else if (tableData2[i].type == -1) {
            tableData2[i].state = "购买盲盒";
          } else if (tableData2[i].type == -2) {
            tableData2[i].state = "加入对战房间";
          } else if (tableData2[i].type == -3) {
            tableData2[i].state = "购买幸运饰品";
          } else if (tableData2[i].type == -4) {
            tableData2[i].state = "商城购买饰品";
          }
        }
        return {
          list: tableData2,
          total: this.Pager.total,
        };
      } catch (error) {
        console.error("getBalanceList", error);
        throw error;
      }
    },

    // //充值流水 页数
    // currentChange(val) {
    //   this.getTopupList(val);
    // },

    // //饰品商城 分页
    // currentChange1(val) {
    //   this.getShoppList(val);
    // },

    // //余额流水 分页
    // currentChange2(val) {
    //   this.getBalanceList(val);
    // },

    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
  },
  computed: {
    // currentTabConfig() {
    //   return this.tabList.find((item) => item.value === this.activeName);
    // },
    // tabList() {
    //   return
    // },
  },
};
</script>
<style lang="less" scoped>
.hist {
  background-color: #1a1c24;
  height: 100%;

  .hist-warp {
    width: calc(100% - 32px);
    height: calc(100% - 32px);
    padding: 16px;
  }

  //页数
  .roll-page {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin: 10px 0 0 -10px;
  }
  .roll-page
    /deep/
    .el-pagination.is-background
    .el-pager
    li:not(.disabled).active {
    background-color: #e9b10e;
    color: #1a1c24;
  }
  .roll-page /deep/ .el-pagination.is-background .btn-next,
  /deep/ .el-pagination.is-background .btn-prev,
  /deep/ .el-pagination.is-background .el-pager li {
    background-color: #333542;
    color: #848492;
  }
}

.btn-list-wrapper {
  overflow: hidden;

  .btn-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-right: -6.5px;

    .btn-list__item {
      width: 33.33%;
      .item__out-wrapper {
        margin-right: 6.5px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 36.36%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/common/btn-bg-2--blue.png");
            color: var(--gray2, #4f7387);

            &.item__out-wrapper__inner-wrapper__content--active {
              background-image: url("~@/assets/img/common/btn-bg-2--yellow.png");
              color: var(--gray4, #dde5e9);
            }
          }
        }
      }
    }
  }
}

.table__out-wrapper {
  overflow: hidden;
  height: calc(100% - 133px);
  padding: 12px;
  background-color: rgba(9, 14, 20, 0.5);

  .table__wrapper {
    height: 100%;

    ::v-deep .el-table tbody tr:hover>td {
      background-color: rgba(0, 0, 0, 0);
    }

    ::v-deep .el-table th,
    ::v-deep .el-table tr {
      background-color: rgba(0, 0, 0, 0);
      .goods_img {
        width: 100%;
      }
    }

    ::v-deep .el-table td,
    ::v-deep .el-table th.is-leaf {
      border-bottom: none;
    }

    ::v-deep .el-table::before {
      background-color: rgba(0, 0, 0, 0);
    }

    .table {
      min-width: 100%;
      border-collapse: collapse;
      background-color: rgba(13, 20, 26, 0.5);
      height: 100%;
      ::v-deep .el-table__body-wrapper {
        height: calc(100% - 50px);
        overflow: auto;
      }
      th,
      td {
        border: none;
        padding: 0;
        margin: 0;

        white-space: nowrap;
        background-color: rgba(0, 0, 0, 0) !important;
      }

      .table__th {
        padding: 11px 16px;
        background-color: rgba(31, 51, 73, 0.7);
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .table__tr {
        .table__tr__td {
          padding: 11px 16px;
          color: var(--gray4, #dde5e9);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          .table__tr__td__img {
            width: 50px;
            height: 50px;
          }
        }
      }
    }
  }
}
</style>
