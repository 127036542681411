<template>
  <div class="responsive-mobile-container me-page">
    <template v-if="Data">
      <UserInfoCard :userInfo="Data" :editName="getName"></UserInfoCard>
      <SteamLinkCard :userInfo="Data"></SteamLinkCard>
      <div class="invite-module">
        <div class="module-title">邀请</div>
        <div class="card__wrapper">
          <div class="card">
            <div
              v-if="Data.myInviter && Data.myInviter.name"
              class="card__invited"
            >
              <div class="card__invited__img">
                <img class="img-cover" :src="Data.myInviter.img" alt="" />
              </div>
              <div class="card__invited__label">{{ Data.myInviter.name }}</div>
            </div>
            <div v-else class="card__can-invite">
              <div class="card__can-invite__left-label">
                新用户3天内可以绑定上级
              </div>
              <div class="card__can-invite__right-bind-btn" @click="openTop">
                绑定
              </div>
            </div>
          </div>
        </div>
      </div>
      <AccountManageCard
        :userInfo="Data"
        @openPass="openPass"
        @openEmail="openEmail"
      ></AccountManageCard>
      <SettingCard :userInfo="Data"></SettingCard>
    </template>
    <div
      v-else
      v-loading="getUserInfoLoading"
      element-loading-background="rgba(0, 0, 0, 0)"
      class="me-page__loading"
    ></div>
  </div>
</template>
  
  <script>
import UserInfoCard from "./components/userInfoCard/index";
import SteamLinkCard from "./components/steamLinkCard/index";
import AccountManageCard from "./components/accountManageCard/index";
import SettingCard from "./components/settingCard/index";

import Utils from "@/assets/js/util.js";
import navheader from "@/components/navheader.vue";
export default {
  inject: ["reload"],
  components: {
    navheader,
    UserInfoCard,
    SteamLinkCard,
    AccountManageCard,
    SettingCard,
  },
  props: ["yidongmenu", "showNav"],
  data() {
    return {
      inviteImg: "",
      inviteName: "",
      Data: null,
      site: "",
      time: "",
      url: "",
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
      urlState: true,
      checked1: false,
      checked2: false,
      urlHint: "",
      getUserInfoLoading: false,
    };
  },
  mounted() {
    //console.log(this.$store.state.id)
    this.getList();
  },
  methods: {
    handleChange(file, fileList) {
      //  console.log(file);
      //  console.log(fileList);
      // var reader = new FileReader();
      // reader.readAsDataURL(file.raw);
      // var URL = URL || webkitURL;
      // var blob = URL.createObjectURL(file.raw);

      // //获取最后一个.的位置
      // var index = file.raw.name.lastIndexOf(".");
      // //获取后缀
      // var ext  = file.raw.name.substr(index+1);

      // // 构造新File对象
      // var newFile = new File([blob ], "test."+ext);
      // console.log(newFile);

      let _this = this;
      let formData = new FormData();
      formData.append("player_id", _this.$store.state.token);
      formData.append("file", file.raw);
      // formData.append('file', _this.$refs.uploadImage.uploadFiles[0]);

      //--------------
      _this
        .$axios({
          url: "/index/User/editHeadImage",
          method: "post",
          dataType: "json",
          data: formData,
          // processData: false,
          // contentType: false,
          headers: { "Content-Type": "multipart/form-data" },
          // headers:{'Content-Type':'application/x-www-form-urlencoded'}
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 1) {
            _this.Data.img = res.data.data;
            Utils.$emit("img", _this.Data.img);
            _this.reload();
          }
        });
      // this.fileList = fileList.slice(-3);
    },
    //跟换头像
    getImg(response, file, fileList) {
      return;
      //console.log(file, fileList);
      let _this = this;
      let formData = new FormData();
      formData.append("player_id", _this.$store.state.token);
      formData.append("file", file.raw);
      // formData.append('file', _this.$refs.uploadImage.uploadFiles[0]);

      //--------------
      _this
        .$axios({
          url: "/index/User/editHeadImage",
          method: "post",
          dataType: "json",
          data: formData,
          // processData: false,
          // contentType: false,
          headers: { "Content-Type": "multipart/form-data" },
          // headers:{'Content-Type':'application/x-www-form-urlencoded'}
        })
        .then((res) => {
          // console.log(res);
          if (res.data.status == 1) {
            _this.Data.img = res.data.data;
            _this.reload();
          }
        });
    },
    //更换昵称
    getName() {
      this.$prompt("请输入昵称", "昵称修改", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            name: value,
          };
          this.$axios
            .post("/index/User/editNickname", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              // console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: "修改失败",
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //邮箱绑定
    openEmail() {
      this.$prompt("请输入邮箱", "邮箱绑定", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern:
          /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputErrorMessage: "邮箱格式不正确",
      })
        .then(({ value }) => {
          // console.log(value);
          let param = {
            email: value,
          };
          this.$axios
            .post("/index/User/bindEmail", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              // console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "绑定失败",
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },

    //修改密码
    openPass() {
      this.$prompt("请输入密码", "密码修改", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            password: value,
          };
          this.$axios
            .post("/index/User/editPass", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              // console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: "修改失败",
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //绑定上级
    openTop() {
      this.$prompt("请输入邀请码", "绑定上级", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          let param = {
            invite_code: value,
          };
          this.$axios
            .post("index/User/bindInviter", this.$qs.stringify(param))
            .then((res) => {
              var data = res.data;
              console.log(data);
              if (data.status == "1") {
                this.$message({
                  message: "绑定成功",
                  type: "success",
                });
                this.reload();
              } else {
                this.$message({
                  message: "绑定失败，" + data.msg,
                  type: "info",
                });
              }
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消输入",
          });
        });
    },
    //个人中心信息
    getList() {
      let param = {};

      this.getUserInfoLoading = true;

      this.$axios
        .post("/index/User/playerInfo", this.$qs.stringify(param))
        .then((res) => {
          console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.Data = data.data;
            this.url = this.Data.tradeUrl;
            this.site = this.Data.last_login_info.position;
            this.time = this.Data.last_login_info.time;
            if (data.data.myInviter) {
              this.inviteImg = data.data.myInviter.img;
              this.inviteName = data.data.myInviter.name;
            }
          }
        })
        .finally(() => {
          this.getUserInfoLoading = false;
        });
    },
    //复制用户ID 和 steamid
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    //steam教程
    goUrl(url) {
      window.open(url, "_blank");
    },
    edit() {
      this.urlState = false;
    },
    off() {
      this.urlState = true;
    },
    confirm() {
      //console.log(this.url);
      let param = {
        tradeUrl: this.url,
      };
      this.$axios
        .post("/index/User/bindSteamnew", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1") {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "success",
            });
            this.urlState = true;
          } else {
            this.$message({
              showClose: true,
              message: data.msg,
              type: "error",
            });
            this.urlState = true;
            // this.url = '';
          }
        });
    },
  },
};
</script>
  
<style lang="less" scoped>
.page-loading {
}
.me-page {
  padding: 16px;


  .me-page__loading{
    min-height: 50vh;
  }

  .invite-module {
    .card__wrapper {
      // position: relative;
      display: flex;
      flex-direction: column;
      height: 80px;
      width: calc(100% - 40px);
      padding: 20px;
      background-image: url("~@/assets/img/my/link-bg-2.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;

      .card {
       display: flex;
       flex-direction: column;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;

        .card__invited {
          display: flex;
          align-items: center;
          .card__invited__img {
            width: 40px;
            height: 40px;
            overflow: hidden;
            border-radius: 50%;
          }
          .card__invited__label {
            font-size: 14px;
            margin-left: 10px;
            color: #fff;
          }
        }

        .card__can-invite {
          display: flex;
          align-items: center;

          .card__can-invite__left-label {
            flex: 1;

            color: var(--gary3, #889aa7);
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          .card__can-invite__right-bind-btn {
            padding: 15px 18px;
            color: var(--yellow, #f8c544);
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }
      }
    }
  }
}
</style>