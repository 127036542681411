<template>
  <DrawerPop
    slideForm="right"
    :visable="visableDrawer"
    :onClosed="onClosed"
    :changeVisable="(events) => (visableDrawer = !visableDrawer)"
  >
    <div class="drawer-content">
      <div class="drawer-content__header-bar">
        <div
          class="header-bar__item header-bar__item--blue"
          @click="goExchangeMoney"
        >
          钻石兑换金币
        </div>
        <div
          class="header-bar__item header-bar__item--yellow"
          @click="goPayment"
        >
          充值
        </div>
      </div>
      <div class="drawer-content__menu-list">
        <div
          v-for="item in menuList"
          :key="item.label"
          class="menu-list__item"
          @click="item.onClick()"
        >
          <div class="menu-list__item__pre-icon">
            <img class="img-cover" :src="item.preIcon" alt="" />
          </div>
          <div class="menu-list__item__label">{{ item.label }}</div>
        </div>
      </div>
      <div class="drawer-content__footer-login-out" @click="nextLogin">
        退出登录
      </div>
    </div>
  </DrawerPop>
</template>
  
<script>
import DrawerPop from "@/components/drawerPop/index";

export default {
  name: "menuDrawer",
  components: {
    DrawerPop,
  },
  props: {
    goExchangeMoney: {
      type: Function,
      required: true,
    },
    onClosed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      drawerList: [],
      visableDrawer: false,
      menuList: [
        {
          label: "饰品背包",
          preIcon: require("@/assets/img/home/bag.png"),
          onClick: () => {
            this.openToPage("/Dota");
          },
        },
        {
          label: "我的账单",
          preIcon: require("@/assets/img/home/zhangdan.png"),
          onClick: () => {
            this.openToPage("/Bill");
          },
        },
        {
          label: "活动推广",
          preIcon: require("@/assets/img/home/tuiguang.png"),
          onClick: () => {
            this.openToPage("/Spread");
          },
        },
        {
          label: "个人中心",
          preIcon: require("@/assets/img/home/my-center.png"),
          onClick: () => {
            this.openToPage("/Me");
          },
        },
        {
          label: "取回记录",
          preIcon: require("@/assets/img/home/pull-record.png"),
          onClick: () => {
            this.openToPage("/Inform");
          },
        },
        {
          label: "VIP福利",
          preIcon: require("@/assets/img/home/vip.png"),
          onClick: () => {
            this.openToPage("/Vip");
          },
        },
      ],
    };
  },
  mounted() {
    this.visableDrawer = true;
  },
  methods: {
    //退出登录
    nextLogin() {
      this.$store.commit("getId", { name: "", id: "", img: "", money: "" });
      this.$store.commit("loginOut");

      this.openToPage("/Index");
    },
    openToPage(url) {
      this.visableDrawer = false;
      this.$router.push(url);
    },

    goPayment() {
      this.openToPage("/Payment");
    },
  },
};
</script>
  
<style lang='less' scoped>
.drawer-content {
  padding: 48px 20px;
  overflow: hidden;
  background: var(--black2, #141d26);
  height: 100%;

  .drawer-content__header-bar {
    display: flex;
    align-items: center;
    margin-right: -16px;

    .header-bar__item {
      margin-right: 16px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      width: 120px;
      line-height: 44px;

      &.header-bar__item--blue {
        color: var(--white, #fff);
        background-image: url("~@/assets/img/common/btn-bg--blue-120-44.png");
      }
      &.header-bar__item--yellow {
        color: var(--black1, #0d141a);
        background-image: url("~@/assets/img/common/btn-bg--yellow-120-44.png");
      }
    }
  }

  .drawer-content__menu-list {
    padding-top: 20px;

    .menu-list__item {
      display: flex;
      align-items: center;

      padding: 14px 0;

      .menu-list__item__pre-icon {
        margin-right: 10px;

        width: 40px;
        height: 46px;
      }
      .menu-list__item__label {
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  .drawer-content__footer-login-out {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    
    padding: 20px;
    text-align: center;

    background-color: #24252f;
    padding: 12px px 0;
    font-size: 14px;
    font-weight: 600;
    color: #848492;
  }
}
</style>
  