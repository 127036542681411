<template>
  <div class="steam-link-card">
    <div class="module-title">账号管理</div>
    <div class="card__wrapper">
      <div class="card">
        <div class="card__form">
          <div class="card__form__item">
            <div class="card__form__item__label">手机：{{ userInfo.mobile }}</div>
            <div class="card__form__item__action-btn">
              <img
                class="img-cover"
                src="~@/assets/img/common/icon-edit.png"
                alt=""
              />
            </div>
          </div>
          <div class="card__form__item">
            <div class="card__form__item__label">邮箱绑定: {{ userInfo.email }}</div>
            <div class="card__form__item__action-btn--text" @click="$emit('openEmail')">绑定</div>
          </div>
          <div class="card__form__item">
            <div class="card__form__item__label">修改密码</div>
            <div class="card__form__item__action-btn" @click="$emit('openPass')">
              <img
                class="img-cover"
                src="~@/assets/img/common/icon-edit.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "accountManageCard",
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.steam-link-card {
  .card__wrapper {
    display: flex;
    flex-direction: column;
    height: 210px;
    padding: 20px;
    width: calc(100% - 40px);

    background-image: url("~@/assets/img/my/link-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;

    .card {
      display: flex;
      flex-direction: column;

      .card__form {
        .card__form__item {
          display: flex;
          align-items: center;
          margin: 10px 0;

          .card__form__item__label {
            flex: 1;

            color: var(--gary3, #889aa7);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
          }
          .card__form__item__action-btn {
            padding: 10px;
            width: 24px;
            height: 24px;
          }
          .card__form__item__action-btn--text {
            padding: 10px;

            color: var(--yellow, #f8c544);
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
          }
        }
      }
    }
  }
}
</style>
