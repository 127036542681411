<template>
  <div class="swiper-module">
    <div class="swiper-module__list__wrapper">
      <div class="swiper-module__list">
        <div
          v-for="(item, index) in boxList"
          :key="index"
          class="list__item"
          @click="getBot(item.name, item.id)"
        >
          <div class="list__item__img">
            <img class="list__item__img__content" :src="item.img_main" alt="" />
          </div>
          <div class="list__item__price-bar">
            <div class="list__item__price-bar__pre-icon">
              <img
                class="list__item__price-bar__pre-icon__content"
                src="~@/assets/img/money.png"
                alt=""
              />
            </div>
            <div class="list__item__price-bar__price">
              {{ item.price }}
            </div>
          </div>
          <div class="list__item__label">{{ item.name }}</div>
        </div>
      </div>
    </div>
    <PrizeDialog
      v-if="prizeDialogConfigDialog"
      v-bind="prizeDialogConfigDialog"
    ></PrizeDialog>
  </div>
</template>

<script>
import PrizeDialog from "./components/prizeDialog/index";

export default {
  name: "swiper-module",
  props: {
    boxList: {
      type: Array,
      required: true,
    },
  },
  components: {
    PrizeDialog,
  },
  data() {
    return {
      drawerBot: false,
      prizeDialogConfigDialog: null,
    };
  },
  created() {},
  methods: {
    getBot(name, id) {
      this.prizeDialogConfigDialog = {
        id,
        drawerName: name,
        onClosed: () => {
          this.prizeDialogConfigDialog = null;
        },
      };
    },
  },
};
</script>

<style lang='less' scoped>
.swiper-module {
  overflow-y: hidden;
  margin-top: 20px;
  margin-bottom: 10px;
  .swiper-module__list__wrapper {
    overflow-x: scroll;
    padding-bottom: 20px;
    margin-bottom: -20px;

    .swiper-module__list {
      display: flex;

      .list__item {
        flex-shrink: 0;

        background-image: url("~@/assets/img/blindBox/category-item-bg.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        margin-right: 10px;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        width: 100px;
        height: 140px;

        .list__item__img {
          width: 78px;
          height: 60px;

          .list__item__img__content {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .list__item__price-bar {
          display: flex;
          align-items: center;

          .list__item__price-bar__pre-icon {
            width: 16px;
            height: 16px;
            margin-right: 4px;

            .list__item__price-bar__pre-icon__content {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .list__item__price-bar__price {
            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .list__item__label {
          margin-top: 10px;
          color: var(--gray4, #dde5e9);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: normal;
          // 类样式
        }
      }
    }
  }
}
</style>
