<template>
  <div class="module-wrapper">
    <div class="module-wrapper__title">{{ title }}</div>
    <div class="module-wrapper__title-sub-info">
      <div
        v-for="(item, index) in titleSubInfoList"
        :key="index"
        class="title-sub-info__item"
      >
        <div class="title-sub-info__item__label">{{ item.label }}</div>
        <div class="title-sub-info__item__value">{{ values[item.prop] }}</div>
        <div class="title-sub-info__item__label">{{ item.state }}</div>
      </div>
    </div>
    <div>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "moduleWrapper",
  props: {
    title: {
      type: String,
      required: true,
    },
    titleSubInfoList: {
      type: Array,
      required: true,
    },
    values: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.module-wrapper {
  padding: 16px;

  .module-wrapper__title {
    padding: 8px 0;

    color: var(--blue, #599be4);
    font-family: "PingFang SC";
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .module-wrapper__title-sub-info {
    background: var(--black1, rgb(13, 20, 26, 0.5));
    padding: 16px 12px;

    margin-bottom: 16px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .title-sub-info__item {
      display: flex;
      align-items: center;

      margin-right: 30px;

      .title-sub-info__item__label {
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .title-sub-info__item__value {
        margin: 0 10px;

        color: var(--yellow, #f8c544);
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }
    }
  }
}
</style>
