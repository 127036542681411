<template>
  <div class="goods-list-wrapper">
      <el-row class="goods-list">
        <el-col
          :xs="12"
          :sm="12"
          :md="8"
          :lg="6"
          :xl="6"
          v-for="(item, index) in goodsList"
          :key="index"
        >
          <div class="goods-list__item">
            <div class="item__out-wrapper">
              <div class="item__out-wrapper__inner-wrapper">
                <div
                  class="item__out-wrapper__inner-wrapper__content"
                  :class="{
                    'item__out-wrapper__inner-wrapper__content--active':
                      selectedGoodsIds[item.id],
                  }"
                  @click="onSelectGoods(item.id)"
                >
                  <div class="content__product-img">
                    <img class="img-cover" :src="item.img" alt="" />
                  </div>
                  <div class="text-over-one content__name">{{ item.name }}</div>
                  <div class="content__price-bar">
                    <PriceBar
                      :price="item.price"
                      center
                      priceIconType="masonry"
                    ></PriceBar>
                  </div>
                  <div class="content__footer-btns">
                    <div
                      class="content__footer-btns__btn"
                      @click.prevent.stop="getExchange(item.id)"
                    >
                      {{ recyclingGoodsIds[item.id] ? "回收中" : "回收" }}
                    </div>
                    <div
                      class="content__footer-btns__btn content__footer-btns__btn--active"
                      @click.prevent.stop="getPull(item.id, item.itemId)"
                    >
                      {{ pullingGoodsIds[item.id] ? "取回中" : "取回" }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
  </div>
</template>

<script>
import Utils from "@/assets/js/util.js";
import PriceBar from "@/components/priceBar/index";

export default {
  name: "goodsList",
  components: {
    PriceBar,
  },
  props: {
    goodsList: {
      type: Array,
      required: true,
    },
    selectedGoodsIds: {
      type: Object,
      required: true,
    },
    onSelectGoods: {
      type: Function,
      required: true,
    },
    random: {
      type: [Number, String],
      required: true,
    },
    getList: {
      type: Function,
      required: true,
    },
    randomString: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      recyclingGoodsIds: {},
      pullingGoodsIds: {},
    };
  },
  methods: {
    async getExchange(id) {
      try {
        if (this.recyclingGoodsIds[id]) return;

        this.$set(this.recyclingGoodsIds, id, true);

        let param = {
          player_skins_ids: [id],
        };

        const res = await this.$axios.post(
          "/index/User/exchangeToMoneynew",
          this.$qs.stringify(param)
        );

        console.log(res.data);

        var data = res.data;
        if (data.status == "1") {
          this.getList(this.page);
          this.$store.commit("getMoney", res.data.data.total_amount);

          Utils.$emit("masonry", res.data.data.total_amount);

          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
            duration: 800,
          });
        } else {
          throw String("兑换失败，此类饰品不可兑换");
        }
      } catch (error) {
        this.$message({
          showClose: true,
          message: error,
          type: "warning",
          duration: 800,
        });
        throw error;
      } finally {
        delete this.recyclingGoodsIds[id];
      }
    },
    //点击取回
    async getPull(id, steamId) {
      try {

        this.$message({
          showClose: true,
          message: '暂无商品可取回',
          type: "warning",
          duration: 800,
        });

        return;

        
        if (this.pullingGoodsIds[id]) return;

        this.$set(this.pullingGoodsIds, id, true);

        let param = {
          player_skins_id: id,
          itemId: steamId,
          random: this.random,
        };
        const res = await this.$axios.post(
          "/index/User/skinToSteam1new",
          this.$qs.stringify(param)
        );

        console.log(res.data);
        var data = res.data;

        if (data.status == "1") {
          this.getList(this.page);
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });
        } else {
          if (data.msg.indexOf("余额不足") != -1) {
            throw String("取回错误，请联系客服");
          } else {
            throw String(data.msg);
          }
        }
        this.randomString();
      } catch (error) {
        this.$message({
          showClose: true,
          message: error,
          type: "warning",
          duration: 800,
        });
        throw error;
      } finally {
        this.$set(this.pullingGoodsIds, id, false);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.goods-list-wrapper {
  overflow: hidden;
  margin: 20px 0;

  .goods-list {
    margin-right: -15px;

    .goods-list__item {

      .item__out-wrapper {
        margin-right: 15px;
        margin-bottom: 15px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 139.04%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/common/module-bg--164-228.png");
            color: var(--gray2, #4f7387);

            overflow: hidden;

            display: flex;
            flex-direction: column;

            &.item__out-wrapper__inner-wrapper__content--active {
              background-image: url("~@/assets/img/common/module-bg--164-228--active.png");
            }

            @keyframes scale {
              0%, 100% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
            }
            .content__product-img {
              width: 127px;
              height: 97px;
              animation: scale 2s infinite;
              margin: auto;
            }

            .content__name {
              margin: 0 10px;

              color: var(--gray4, #dde5e9);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .content__price-bar {
              padding-bottom: 10px;
            }

            .content__footer-btns {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;

              padding-bottom: 20px;

              .content__footer-btns__btn {
                padding: 4px 16px;

                margin: 0 6px;

                justify-content: center;
                align-items: center;

                border: 1px solid rgba(248, 197, 68, 0.5);

                color: var(--yellow, #f8c544);
                text-align: center;
                font-family: "PingFang SC";
                font-size: 12px;
                font-weight: 400;

                &.content__footer-btns__btn--active {
                  border: 1px solid #f8c544;
                  background: var(--yellow, #f8c544);

                  color: var(--black1, #0d141a);
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
