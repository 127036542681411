<template>
  <div>
    <div
      v-if="roomDetails.status == 1"
      class="status-tag"
      :class="
        roomDetails.condition_type == 1 ||
        (roomDetails.condition_type == 3 && 'status-tag--disabled')
      "
      @click="addRoom && addRoom()"
    >
      加入房间
    </div>
    <div
      v-if="roomDetails.status == 2"
      class="status-tag status-tag--disabled"
    >
      已结束
    </div>
  </div>
</template>

<script>
export default {
  name: "roomStatusTag",
  props: {
    roomDetails: {
      type: Object,
      required: true,
    },
    addRoom: {
      type: Function,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.status-tag {
  flex-shrink: 0;

  padding: 0 13px;

  font-family: "PingFang SC";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;

  background: var(--yellow, #f8c544);
  color: #000;

  &.status-tag--disabled {
    background-color: #4f7387;
    color: var(--gary3, #889aa7);
  }
}
</style>
