<template>
  <div class="hidden-sm-and-up entry-module__wrapper__out-wrapper">
    <div class="entry-module__wrapper">
      <div class="entry-module">
        <div
          class="entry-module__item"
          v-for="(item, index) in centerContent"
          :key="index"
          @click="item.onTap && item.onTap(item)"
        >
          <div class="entry-module__item__icon-wrapper">
            <img
              class="entry-module__item__icon-wrapper__icon"
              :src="item.image"
              alt=""
            />
          </div>
          <div class="entry-module__item__label">{{ item.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { generateMainNavList } from '@/assets/js/generateMainNavList.js'

export default {
  name: "entryModule",
  data() {
    return {
      centerContent: generateMainNavList(this),
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='less' scoped>
.entry-module__wrapper__out-wrapper {
  margin: 20px;
  .entry-module__wrapper {
    position: relative;
    padding-bottom: 220/343 * 100%;

    .entry-module {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      background-image: url("~@/assets/img/home/Vector2490.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      display: flex;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;

      .entry-module__item {
        margin: 12px 0;
        width: 20%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @keyframes flipAndScale {
        0%, 100% { 
          transform: rotateY(0deg) scale(1); 
        }
        50% { 
          transform: rotateY(180deg) scale(1.1); 
        }
      }

      @keyframes breathe {
        0%, 100% { 
          opacity: 1; 
        }
        50% { 
          opacity: 0.5; 
        }
      }

      .entry-module__item__icon-wrapper {
        width: 40px;
        height: 46px;
        animation: flipAndScale 2s 10s, breathe 2s infinite ease-in-out; /* 应用动画 */
        box-shadow: 0 0 10px #7c7c7c; /* 添加外发光效果 */

        .entry-module__item__icon-wrapper__icon {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
        .entry-module__item__label {
          margin-top: 6px;

          color: var(--gary3, #889aa7);
          text-align: center;
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
