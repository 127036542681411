<template>
  <div class="steam-link-card">
    <div class="module-title">偏好设置</div>
    <div class="card__wrapper">
      <div class="card">
        <div class="card__form">
          <div class="card__form__item">
            <div class="card__form__item__label">开启声音</div>
            <div class="card__form__item__action-btn">
              <el-switch v-model="checked1"></el-switch>
            </div>
          </div>
          <div class="card__form__item">
            <div class="card__form__item__label">活动结果提醒（参与活动结束后结果会通过浏览器通知告知）</div>
            <div class="card__form__item__action-btn">
              <el-switch v-model="checked2"></el-switch>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "settingCard",
  data() {
    return {
      checked1: false,
      checked2: false,
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.steam-link-card {
  .card__wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: calc(100% - 40px);
    height: 120px;

    background-image: url("~@/assets/img/my/setting-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;

    .card {
      display: flex;
      flex-direction: column;

      .card__form {
        .card__form__item {
          display: flex;
          align-items: center;
          margin: 20px 0;

          .card__form__item__label {
            flex: 1;

            color: var(--gary3, #889aa7);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 400;
          }
          .card__form__item__action-btn {
          }
        }
      }
    }
  }
}
</style>
