<template>
  <ModuleWrapper
    :values="roomDataRefact"
    title="奖池奖品"
    :titleSubInfoList="titleSubInfoList"
  >
    <div class="goods-list-wrapper">
      <div class="goods-list">
        <el-row>
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
            v-for="(item, index) in roomData.player_skin"
            :key="index"
          >
            <div class="goods-list__item">
              <div class="item__out-wrapper">
                <div class="item__out-wrapper__inner-wrapper">
                  <div class="item__out-wrapper__inner-wrapper__content">
                    <div class="content__product-img">
                      <img class="img-cover" :src="item.skin_img" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </ModuleWrapper>
</template>

<script>
import ModuleWrapper from "../moduleWrapper/index";

const titleSubInfoList = [
  {
    label: "已送出",
    prop: "$skinCount",
    state: "件",
  },
  {
    label: "价值",
    prop: "pool",
    state: "",
  },
];

export default {
  name: "prizeRecordModule",
  components: {
    ModuleWrapper,
  },
  props: {
    roomData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleSubInfoList,
    };
  },
  created() {},
  methods: {},
  computed: {
    roomDataRefact() {
      return {
        ...this.roomData,
        $skinCount: this.roomData.skin_list.length,
      };
    },
  },
};
</script>

<style lang='less' scoped>
.goods-list-wrapper {
  overflow: hidden;

  .goods-list {
    .goods-list__item {
      .item__out-wrapper {
        margin-right: 15px;
        margin-bottom: 15px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 71.95%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/room/prize-bg-3.png");
            color: var(--gray2, #4f7387);

            .content__product-img {
              width: 60%;
              height: 73/96 * 60%;

              margin: auto;
            }
          }
        }
      }
    }
  }
}
</style>
