<template>
  <div>
    <TabCatgoryBar
      :value="activeCategory"
      :onChange="onCategoryChange"
      :tabList="tabList"
    ></TabCatgoryBar>

    <div
      v-loading="fetchSkinLoading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
      class="table__out-wrapper"
    >
      <div class="table__wrapper">
        <table class="table">
          <tr>
            <th
              class="table__th"
              v-for="item in tableConfigList"
              :key="item.prop"
            >
              {{ item.label }}
            </th>
          </tr>
          <tr
            class="table__tr"
            v-for="itemRank in tableData"
            :key="itemRank.id"
          >
            <td
              v-for="item in tableConfigList"
              :key="item.prop"
              class="table__tr__td"
            >
              {{ itemRank[item.prop] }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import TabCatgoryBar from "@/components/tabCatgoryBar/index.vue";

const tabList = [
  {
    label: "会员",
    value: 0,
  },
  {
    label: "主播",
    value: 1,
  },
];

const tableConfigList = [
  {
    label: "用户名",
    prop: "player_name",
  },
  {
    label: "获得饰品详情",
    prop: "skin_name",
  },
];

export default {
  name: "recentlyFalling",
  components: {
    TabCatgoryBar,
  },
  props: {
    boxId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      activeCategory: 0,
      tabList,
      tableConfigList,
      tableData: [],
      fetchSkinLoading: false,
    };
  },
  created() {},
  mounted() {
    this.getSetSkinList();
  },
  methods: {
    onCategoryChange(activeCategory) {
      this.activeCategory = activeCategory;
      this.getSetSkinList();
    },
    async getSetSkinList() {
      try {
        this.fetchSkinLoading = true;
        const activeCategory = this.activeCategory;

        let param = {
          page: 1,
          pageSize: 20,
          box_id: this.boxId,
          type: this.activeCategory,
        };

        const res = await this.$axios.post(
          "/index/Box/lately",
          this.$qs.stringify(param)
        );

        if (activeCategory !== this.activeCategory) {
          console.info("数据过期");
          return;
        }

        const data = res.data;

        if (data.status == 1) {
          const tableData = data.data.list;

          if (tableData && tableData.length > 0) {
            this.tableData = tableData;
          }
        } else {
          throw String(data.msg);
        }
      } catch (error) {
        this.$message({
          message: error,
          type: "warning",
        });
      } finally {
        this.fetchSkinLoading = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.table__out-wrapper {
  overflow: hidden;

  padding: 12px;
  background-color: rgba(9, 14, 20, 0.5);

  .table__wrapper {
    overflow-x: auto;
    margin-bottom: -20px;
    padding-bottom: 20px;

    border-radius: 4px;

    .table {
      min-width: 100%;

      border-collapse: collapse;
      background-color: rgba(13, 20, 26, 0.5);

      th,
      td {
        border: none;
        padding: 0;
        margin: 0;

        white-space: nowrap;
      }

      .table__th {
        padding: 11px 16px;
        background-color: rgba(31, 51, 73, 0.7);
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .table__tr {
        .table__tr__td {
          padding: 11px 16px;
          color: var(--gray4, #dde5e9);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          text-align: center;
        }
      }
    }
  }
}
</style>
