<template>
  <div
    class="base-slider-pc"
    :class="{
      'small-screen': small_screen,
    }"
    :style="{
      right: getSliderRight,
    }"
  >
    <!-- <div class="item new_container" @click="$emit('newManChest')">
      <div class="new"></div>
      <span>新人宝箱</span>
    </div> -->

    <div class="item arrive_container" @click="gotTo('/task-center')">
      <div class="qiandao"></div>
      签到
    </div>

    <!-- <div class="item lucky-money_container" @click="$emit('hongbao')">
      <div class="lucky-money"></div>
      <span>红包</span>
    </div> -->
    <div class="item chat-group_container" @click="qqGroup()">
      <div class="chat-group"></div>
      <span>群聊</span>
    </div>
    <div class="item helper_container" @click="gotTo('/Payment')">
      <div class="helper"></div>
      <span>充值</span>
    </div>
    <div class="item back_container" @click="$emit('retrieveTheAssistant')">
      <div class="back"></div>
      <span>取回助手</span>
    </div>

    <div class="item back-top_container" @click="backTop">
      <div class="back-top"></div>
      <span>顶置</span>
    </div>
    <div class="switch" @click="changeSlider">
      <i :class="[status ? 'el-icon-arrow-left' : 'el-icon-arrow-right']"></i>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 小屏
      small_screen: false,
      // 状态 0展开 1折叠
      status: 0,
    };
  },
  computed: {
    getSliderRight() {
      if (this.small_screen) {
        if (this.status) {
          return "-65px !important";
        } else {
          return "-10px !important";
        }
      } else {
        if (this.status) {
          return "-70px !important";
        } else {
          return "0px !important";
        }
      }
    },
  },
  created() {
    this.checkScreen();
    window.addEventListener("resize", () => {
      this.checkScreen();
    });
  },
  methods: {
    changeSlider() {
      this.status = this.status ? 0 : 1;
    },
    checkScreen() {
      if (window.innerWidth < 600) {
        this.small_screen = true;
        return;
      }
      this.small_screen = false;
    },
    backTop() {
      window.scrollTo({
        left: 0,
        top: 0,
        behavior: "smooth",
      });
    },
    gotTo(path) {
      this.$router.push({
        path,
      });
    },
    qqGroup() {
      window.open(
        "http://kefu.duomifreight.com/index/index/home?visiter_id=&visiter_name=&avatar=&business_id=6&groupid=0&special=11&theme=7571f9"
      );
    },
    openHongbao() {
      this.$emit("openHongbao");
    },
  },
};
</script>

<style lang="less" scoped>
div {
  -webkit-tab-highlight-color: transparent;
}
.small-screen {
  transform: scale(0.8) translateY(50%) !important;
  right: -10px !important;
}
.base-slider-pc {
  position: fixed;
  right: 0;
  bottom: calc(50vh);
  width: 70px;
  z-index: 600;
  background-color: #141d26;
  min-height: 200px;
  padding: 20px 0;
  transition: all 200ms ease-in-out;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  transform: scale(1.1) translateY(50%);
  box-shadow: 0px 16px 48px 16px rgba(0, 0, 0, 0.08),
    0px 12px 32px rgba(0, 0, 0, 0.12), 0px 8px 16px -8px rgba(0, 0, 0, 0.16);
  .switch {
    background-color: #070b11;
    height: 70px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    position: absolute;
    left: -30px;
    top: 50%;
    transform: translateY(calc(-50% + 35px));
    text-align: center;
    i {
      color: #fff;
      font-size: 25px;
    }
  }

  .lucky-money_container {
    .lucky-money {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/hongbao.png");
    }
  }
  .chat-group_container {
    .chat-group {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/QQ.png");
    }
  }
  .back-top_container {
    margin-bottom: 0 !important;
    .back-top {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/zhiding.png");
    }
  }

  .helper_container {
    .helper {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/qianbao.png");
    }
  }

  .back_container {
    .back {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/quhui2.png");
    }
  }

  .arrive_container {
    .qiandao {
      width: 24px;
      height: 24px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/qiandao.png");
    }
  }
  .new_container {
    .new {
      width: 26px;
      height: 26px;
      background-size: contain;
      background-repeat: no-repeat;
      background-image: url("~@/assets/img/baoxiang.png");
    }
  }

  .item {
    color: #fff;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;

    span {
      margin-top: 5px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
