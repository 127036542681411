<template>
  <div class="tab-list__wrapper">
    <div class="tab-list">
      <div
        v-for="item in tabList"
        :key="item[valueKey]"
        class="tab-list__item"
        :class="item[valueKey] === value && 'tab-list__item--active'"
        @click="onChange(item[valueKey], item)"
      >
        {{ item[labelKey] }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tabCatgoryBar",
  props: {
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    value: {
      type: [String, Number],
      required: true,
    },
    tabList: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang='less' scoped>
.tab-list__wrapper {
  padding: 0 16px;

  overflow: hidden;

  .tab-list {
    display: flex;
    align-items: center;
    overflow-y: auto;

    margin-bottom: -20px;
    padding-bottom: 20px;

    .tab-list__item {
      flex-shrink: 0;
      
      position: relative;
      color: var(--gary3, #889aa7);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      margin-right: 28px;

      padding: 10px 0;

      &.tab-list__item--active {
        color: var(--yellow, #f8c544);

        &.tab-list__item--active::after {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;

          content: "";
          display: block;

          margin: auto;
          width: 24px;
          height: 2px;
          background-color: #f8c544;
        }
      }
    }
  }
}
</style>