<template>
  <div>
    <div
      v-if="goodsTypeList.length > 0"
      class="main-content__info-bar__wrapper"
    >
      <div class="main-content__info-bar">
        <div
          v-for="(item, index) in goodsTypeList"
          :key="index"
          class="info-bar__item"
        >
          <div
            class="info-bar__item__pre-icon"
            :style="{
              backgroundColor: item.color,
            }"
          ></div>
          <div
            class="info-bar__item__label"
            :style="{
              color: item.color,
            }"
          >
            {{ item.skins_type_probability }}%
          </div>
        </div>
      </div>
    </div>
    <div class="goods-list-wrapper">
      <div class="goods-list">
        <el-row>
          <el-col
            :xs="12"
            :sm="12"
            :md="8"
            :lg="6"
            :xl="6"
            v-for="(item, index) in goodsList"
            :key="index"
          >
            <div class="goods-list__item">
              <div class="item__out-wrapper">
                <div class="item__out-wrapper__inner-wrapper">
                  <div class="item__out-wrapper__inner-wrapper__content">
                    <div
                      class="content__type-icon"
                      :style="{ background: item.color }"
                    ></div>
                    <div class="content__title">{{ item.exteriorName }}</div>
                    <div class="content__product-img">
                      <img class="img-cover" :src="item.img" alt="" />
                    </div>
                    <div class="text-over-one content__name">
                      {{ item.name }}
                    </div>
                    <div class="content__price-bar">
                      <PriceBar :price="item.price" center></PriceBar>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
  
  <script>
import PriceBar from "@/components/priceBar/index";

export default {
  name: "goodsList",
  components: {
    PriceBar,
  },
  props: {
    goodsTypeList: {
      type: Array,
      required: true,
    },
    goodsList: {
      type: Array,
      required: true,
    },
  },
};
</script>
  
  <style lang='less' scoped>
.main-content__info-bar__wrapper {
  overflow: hidden;

  .main-content__info-bar {
    display: flex;
    align-items: center;
    background: var(--gray1, rgba(53, 69, 81, 0.3));

    padding: 14px 16px;

    overflow-x: auto;

    padding-bottom: 20px;

    .info-bar__item {
      display: flex;
      align-items: center;
      margin-right: 10px;

      .info-bar__item__pre-icon {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin-right: 8px;
      }
      .info-bar__item__label {
        font-family: "PingFang SC";
        font-size: 12px;
        font-weight: 400;

        display: flex;
        align-items: center;
      }
    }
  }
}
.goods-list-wrapper {
  overflow: hidden;
  margin: 20px 0;

  .goods-list {

    margin-right: -15px;

    .goods-list__item {
      .item__out-wrapper {
        margin-right: 15px;
        margin-bottom: 15px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 114.63%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/room/card-bg-2.png");
            color: var(--gray2, #4f7387);

            overflow: hidden;

            display: flex;
            flex-direction: column;

            .content__type-icon {
              position: absolute;
              left: 10px;
              top: 10px;

              width: 10px;
              height: 10px;
              border-radius: 10px;
            }

            .content__title {
              height: 12%;

              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;

              color: var(--white, #fff);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;

              text-align: center;
            }

            @keyframes scale {
              0%, 100% {
                transform: scale(1);
              }
              50% {
                transform: scale(1.1);
              }
            }

            .content__product-img {
              width: 96px;
              height: 73px;
              margin: auto;
              animation: scale 2s infinite;  // 添加缩放动画
            }

            .content__name {
              margin: 10px;

              color: var(--gray4, #dde5e9);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            .content__price-bar {
              padding-bottom: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
  