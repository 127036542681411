<template>
  <DrawerPop
    slideForm="bottom"
    :visable="visableDrawer"
    :onClosed="onClosed"
    :changeVisable="(events) => (visableDrawer = !visableDrawer)"
  >
    <div class="prize-dialog__content">
      <div class="prize-dialog__content__header-bar">
        <div class="header-bar__title">{{ drawerName }}</div>
        <div class="header-bar__sub-title">包含以下奖励</div>
        <div class="header-bar__close-btn" @click="visableDrawer = false">
          <img
            class="img-cover"
            src="@/assets/img/blindBox/close-btn.png"
            alt=""
          />
        </div>
      </div>
      <div class="prize-dialog__content__card-out-wrapper">
        <div class="card-container">
          <div class="card-container__list">
            <div
              v-for="(item, index) in drawerList"
              :key="index"
              class="list__item"
            >
              <div class="list__item__img">
                <img class="img-cover" :src="item.img" />
              </div>
              <div class="text-over-one list__item__title">
                {{ item.name }}
              </div>
              <div class="list__item__footer-bar">
                <div class="footer-bar__price-icon">
                  <img class="img-cover" src="@/assets/img/home/jinbi.png" />
                </div>
                <div class="footer-bar__price">{{ item.price }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </DrawerPop>
</template>
  
<script>
import DrawerPop from "@/components/drawerPop/index";

export default {
  name: "prize-dialog",
  components: {
    DrawerPop,
  },
  props: {
    drawerName: {
      type: String,
      required: true,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    onClosed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      drawerList: [],
      visableDrawer: false,
    };
  },
  mounted() {
    this.visableDrawer = true;
    this.getSetDetails();
  },
  methods: {
    async getSetDetails() {
      this.drawerBot = true;
      let param = {
        box_id: this.id,
      };
      const res = await this.$axios.post(
        "/index/Box/boxInfo",
        this.$qs.stringify(param)
      );

      let data = res.data;
      if (data.status == 1) {
        this.drawerList = data.data.box_skins;
      }
    },
  },
};
</script>
  
  <style lang='less' scoped>
.prize-dialog__content__header-bar {
  display: flex;
  align-items: center;

  padding: 12px;
  padding-left: 20px;

  .header-bar__title {
    color: var(--yellow, #f8c544);
    font-family: "PingFang SC";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .header-bar__sub-title {
    flex: 1;

    color: var(--gary3, #889aa7);
    font-family: "PingFang SC";
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }
  .header-bar__close-btn {
    width: 36px;
    height: 36px;
  }
}

.prize-dialog__content {
  background-color: #141d26;
  padding-bottom: 40px;

  .prize-dialog__content__card-out-wrapper {
    overflow: hidden;

    .card-container {
      padding-left: 20px;
      min-height: 200px;

      .card-container__list {
        margin-bottom: -20px;
        padding-bottom: 20px;

        overflow-x: auto;
        display: flex;

        .list__item {
          flex-shrink: 0;
          margin-right: 12px;
          overflow: hidden;

          width: 180px;

          .list__item__img {
            height: 134px;
            background: linear-gradient(
              180deg,
              rgba(89, 155, 228, 0.6) 0%,
              rgba(89, 155, 228, 0) 100%
            );

            img {
              object-fit: contain;
            }
          }
          .list__item__title {
            margin: 12px 0;

            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
          .list__item__footer-bar {
            display: flex;
            align-items: center;

            .footer-bar__price-icon {
              width: 16px;
              height: 16px;
            }
            .footer-bar__price {
              color: var(--gray4, #dde5e9);
              font-family: "PingFang SC";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>
  