

<template>
  <div>
    <div class="module-title">个人中心</div>
    <div class="user-info-card__wrapper">
      <div class="user-info-card">
        <div class="card__header__wrapper">
          <div class="card__header">
            <div class="card__header__bg">
              <img
                class="img-cover"
                src="~@/assets/img/my/user-header-bg.png"
              />
            </div>

            <div class="card__header__left">
              <div
                class="card__header__left__user-img"
                @click="triggerUploadHeaderImg"
              >
                <img class="img-cover" :src="userInfo.img" alt="" />
              </div>
              <div class="card__header__left__camera-icon">
                <img
                  class="img-cover"
                  src="~@/assets/img/common/icon-camera.png"
                  alt=""
                />
              </div>
            </div>
            <div class="card__header__center">
              <div class="center__top-bar">
                <div class="text-over-one center__user-name">
                  {{ userInfo.name }}
                </div>
                <div class="center__top-bar__suffix-icon">
                  LV{{ userInfo.grade }}
                </div>
              </div>
              <div class="center__footer-bar">
                <div class="center__footer-bar__id-wrapper">
                  <div class="center__footer-bar__id-wrapper__label">
                    用户id: {{ userInfo.id }}
                  </div>
                  <div
                    class="center__footer-bar__id-wrapper__copy-icon"
                    @click="copyText(userInfo.id)"
                  >
                    <img
                      class="img-cover"
                      src="~@/assets/img/common/icon-copy.png"
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div></div>
            </div>
            <div class="card__header__right">
              <div
                class="card__header__right__edit-icon"
                @click="editName"
              >
                <img
                  class="img-cover"
                  src="~@/assets/img/common/icon-edit.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
        <div class="card__footer">
          <div class="card__footer__label">
            上次登录：{{ userInfo.last_login_info.time }}
          </div>
          <div class="card__footer__location">
            <div class="card__footer__location__icon">
              <img
                class="img-cover"
                src="~@/assets/img/common/icon-location.png"
                alt=""
              />
            </div>
            <div class="card__footer__location__label">
              {{ userInfo.last_login_info.position }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <UploadFile @triggerUpload="(e) => (uploadHeaderImg = e)"></UploadFile>
  </div>
</template>

<script>
import UploadFile from "@/components/uploadFile/index.vue";
import Utils from "@/assets/js/util.js";

export default {
  name: "userInfoCard",
  components: {
    UploadFile,
  },
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
    editName: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      file: null,
      previewUrl: null,
    };
  },
  created() {},
  methods: {
    uploadHeaderImg: () => {},
    copyText(text) {
      var input = document.createElement("input"); // js创建一个input输入框
      input.value = text; // 将需要复制的文本赋值到创建的input输入框中
      document.body.appendChild(input);
      input.select();
      document.execCommand("Copy");
      document.body.removeChild(input);
      this.$message({
        message: "复制成功",
        type: "success",
      });
    },
    openEditUserNameDialog() {},
    triggerUploadHeaderImg() {
      console.info("uploadHeaderImg", this.uploadHeaderImg);

      this.uploadHeaderImg({
        onSelectedFile: async (file) => {
          console.info("onSelectedFile", file);

          let _this = this;
          let formData = new FormData();

          formData.append("player_id", _this.$store.state.token);
          formData.append("file", file);

          const res = await this.$axios({
            url: "/index/User/editHeadImage",
            method: "post",
            dataType: "json",
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (res.data.status == 1) {
            const newHeaderImg = res.data.data;

            Utils.$emit("img", newHeaderImg);
            this.userInfo.img = newHeaderImg;
          }
        },
      });
    },
  },
};
</script>

<style lang='less' scoped>
.user-info-card__wrapper {
  display: flex;
  flex-direction: column;
  background-image: url("~@/assets/img/my/user-info-bg.png");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100% 100%;
  padding: 20px;
  height: 168px;
  

  .user-info-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .card__header__wrapper {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .card__header {
        position: relative;
        z-index: 1;

        display: flex;
        align-items: center;
        flex: 1;

        overflow: hidden;

        .card__header__bg {
          position: absolute;
          z-index: -1;
          left: 34px;
          top: 0;
          bottom: 0;
        }

        .card__header__left {
          display: flex;
          align-items: center;
          position: relative;

          .card__header__left__user-img {
            width: 68px;
            height: 68px;
            border-radius: 50%;
            overflow: hidden;

            background-color: #599be4;

            border: 1px solid var(--blue, #599be4);
          }
          .card__header__left__camera-icon {
            position: absolute;
            right: 0;
            bottom: 0;

            width: 24px;
            height: 24px;
          }
        }
        .card__header__center {
          flex: 1;
          padding-left: 10px;

          overflow: hidden;

          .center__top-bar {
            display: flex;
            align-items: center;

            margin-bottom: 10px;

            .center__user-name {
              color: var(--gray4, #dde5e9);
              font-family: "PingFang SC";
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
            .center__top-bar__suffix-icon {
              margin-left: 10px;
              margin-right: 14px;

              flex-shrink: 0;

              position: relative;
              z-index: 1;

              line-height: 14px;
              padding: 0 4px;

              color: #5f480f;
              font-family: "Alibaba PuHuiTi";
              font-size: 10px;
              font-weight: 700;

              &.center__top-bar__suffix-icon::after {
                position: absolute;
                z-index: -1;

                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                content: "";
                display: block;

                transform: skew(-20deg);

                background-color: #f8c544;
              }
            }
          }
          .center__footer-bar {
            display: flex;
            align-items: center;

            .center__footer-bar__id-wrapper {
              background: var(--black2, #141d26);
              padding: 4px 8px;

              display: flex;
              align-items: center;

              .center__footer-bar__id-wrapper__label {
                margin-right: 8px;

                color: var(--gary3, #889aa7);
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }
              .center__footer-bar__id-wrapper__copy-icon {
                width: 16px;
                height: 16px;
              }
            }
          }
        }
        .card__header__right {
          align-self: flex-start;
          flex-shrink: 0;
          .card__header__right__edit-icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    .card__footer {
      display: flex;
      align-items: center;
      width: 100%;

      .card__footer__label {
        color: var(--gary3, #889aa7);
        font-family: "PingFang SC";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .card__footer__location {
        margin-left: 10px;

        display: flex;
        align-items: center;

        .card__footer__location__icon {
          width: 16px;
          height: 16px;
        }
        .card__footer__location__label {
          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
