<template>
  <div class="room" :style="{ '--backimg': backcssVars() }">
    <div class="room-right" >
      <div class="room-right-name">
        <span
          >{{
            fightboxList[boxListIndex] ? fightboxList[boxListIndex].name : ""
          }} </span
        >包含以下奖励
      </div>
      <div class="room-right-list">
        <div class="roomlist-title">详情</div>
        <div class="roomlist">
          <ul>
            <li v-for="(item, index) in boxList[boxListIndex]" :key="index">
              <div class="roomlist-warp">
                <div class="roomlist-img">
                  <img :src="item.imageUrl" />
                </div>
                <div class="roomlist-name" :title="item.itemName">
                  {{ item.itemName }}
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="room-left">
      <div class="room-left-hint">
        <div class="roomleft-num">
          <span @click="goLucky">盲盒对战</span>
          <span>></span>
          <span>参加对战编号 {{ id }}</span>
        </div>
        <div class="room-btn-list-wrapper">
          <div class="btn-list">
            <div
              class="btn-list__item"
              v-for="(item, index) in roomBtnList"
              :key="index"
            >
              <div class="item__out-wrapper">
                <div class="item__out-wrapper__inner-wrapper">
                  <div
                    class="item__out-wrapper__inner-wrapper__content"
                    :class="`item__out-wrapper__inner-wrapper__content--${item.styleType}`"
                    @click="item.onClick(item)"
                  >
                    {{ item.label }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="room-left-box">
        <div class="roombox-warp">
          <ul>
            <li
              v-for="(item, index) in fightboxList"
              :key="index"
              :id="item.state ? 'room-li' : ''"
            >
              <div class="roombox-img" @click="selImg(index)">
                <img :src="item.img_main" />
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- <div class="room-people__wrapper">
        <div class="btn-list">
          <div class="btn-list__item" v-for="(item, index) in 4" :key="index">
            <div class="item__out-wrapper">
              <div class="item__out-wrapper__inner-wrapper">
                <div class="item__out-wrapper__inner-wrapper__content">
                  <div class="content__header-img">
                    <img class="img-cover" src="" alt="" />
                  </div>
                  <div class="content__user-name">133****8393</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <div class="room-left-people">
        <div class="roompe-warp">
          <div class="roompe-line" v-if="roomLineState"></div>
          <ul class="ul1">
            <li
              class="li1"
              v-for="(item, index) in peopleList"
              :key="index"
              :style="{ width: item.width }"
            >
              <div
                class="room-warp"
                :class="[
                  item.winState ? 'room-win-status' : 'room-fail-status',
                ]"
              >
                <div class="room1">
                  <div class="room1-img" v-if="item.end == 2">
                    <div
                      :class="
                        item.winState
                          ? 'room1-text1 room1-win'
                          : 'room1-text2 room-back'
                      "
                    >
                      <img
                        class="win-img"
                        src="../assets/img/lucky/win.png"
                        alt=""
                        v-if="item.winState"
                        srcset=""
                      />
                      <img
                        v-else
                        class="fail-img"
                        src="../assets/img/lucky/fail.png"
                        alt=""
                        srcset=""
                      />
                    </div>
                  </div>
                  <div class="room1-img" v-if="item.state && item.end == 1">
                    <img src="../assets/img/gou.png" />
                  </div>
                  <div class="room1-img" v-if="!item.state">
                    <el-button
                      v-if="!item.state"
                      @click="goParticipate(index)"
                      :disabled="disabled"
                      ><i v-if="loading" class="el-icon-loading"></i>
                      加入对战
                    </el-button>
                  </div>

                  <div class="pool" v-if="openWin">
                    <ul
                      class="pool_list"
                      :class="{
                        'pool-ul2': mode == 2,
                        'pool-ul3': mode == 3,
                        'pool-ul4': mode == 4,
                        'pool-ul5': mode == 5,
                        'pool-ul6': mode == 6,
                      }"
                    >
                      <li
                      class="list_item"
                        v-for="(itemBox, indexBox) in item.fightBox"
                        :key="indexBox"
                      >
                        <div class="pool-img">
                          <img :src="itemBox.imageUrl" />
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="room2">
                  <div class="room2-tou" v-if="item.state">
                    <img :src="item.img" />
                  </div>
                  <div class="room2-name" v-if="item.state">
                    {{ item.name }}
                  </div>
                  <span v-if="!item.state">等待玩家</span>
                </div>
                <div class="win-list">
                  <div class="win-title">
                    <img src="../assets/img/money.png" />
                    <span>{{ item.totalPrice }}</span>
                  </div>
                  <ul class="win-ul">
                    <li
                      class="win-li"
                      v-for="(item1, index1) in item.box"
                      :key="index1"
                    >
                      <div class="win-warp">
                        <div class="img">
                          <img :src="item1.img" />
                        </div>
                        <h6>
                          <img src="../assets/img/money.png" />
                          <span>{{ item1.price }}</span>
                        </h6>
                        <h6 :title="item1.name">{{ item1.name }}</h6>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <div class="room-line" v-if="lineState"></div>
          </ul>
        </div>
      </div>
      <div class="room-left-bot">
        <!-- <p>公平性验证</p>
        <p>参加活动编号 - 813227561387798528</p>
        <p>随机数 - 2968b3a4324279ba9f4f4e6d19ba2a92</p>
        <p>[关于公平性验证]</p> -->
      </div>
    </div>

    <audio controls ref="notify1" class="audio" loop="loop">
      <source src="../assets/audio/open8.mp3" />
    </audio>
    <myhomebot></myhomebot>
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";

import Utils from "./../assets/js/util.js";
import { battle } from "@/api/socket.js";

export default {
  components: { myhomebot },
  data() {
    return {
      roomBtnList: [
        {
          label: "对战规则",
          styleType: "blue",
          onClick: () => {
            this.goLuckyRule();
          },
        },
        {
          label: "对战历史",
          styleType: "blue",
          onClick: () => {
            this.goLuckyHistory();
          },
        },
        {
          label: "返回大厅",
          styleType: "yellow",
          onClick: () => {
            this.goback();
          },
        },
      ],
      add: false,
      disabled: false,
      loading: false,
      roomLineState: false,
      id: "",
      winList: [
        {
          name: 1,
        },
        {
          name: 2,
        },
        {
          name: 3,
        },
      ],
      loser: [
        {
          name: "",
          img: "",
          price: "0.01",
        },
      ],
      boxList: [],
      boxListIndex: 0,
      fightboxList: [],
      fightImg: [],
      fightImgObj: [],
      peopleList: [],
      peopleObj: {
        name: "凉凉奖励",
        width: "",
        state: true,
        price: "0.01",
        img: require("../assets/img/moneyback.png"),
        loading: false,
      },
      img: "",
      lineState: false,
      openWin: false,
      mode: null,
      openBox: [
        {
          state: false,
        },
      ],
      totalPrice: 0,
      adds: false,
    };
  },
  watch: {
    add(val) {
      if (val == false) {
        this.getRoomList1();
      }
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.getRoomList();
    this.getBack();
    // this.timer = setTimeout(this.goParticipate, 5000); // 5秒后自动进入对战
  },
  created() {
    this.initWebSocket();
  },
  destroyed() {
    //关闭
    //clearInterval(this.timer);
    this.websock.close();
  },
  methods: {
    goback() {
      this.$router.go(-1);
      // this.$router.push({path:"Lucky"})
    },
    backcssVars() {
      return `url("${this.img}")`;
    },
    //音乐 播放
    playAlarm1() {
      this.$refs.notify1.play();
    },
    //音乐 结束
    playAlarm2() {
      this.$refs.notify1.pause();
    },
    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    websocketonopen() {},

    websocketonerror() {
      this.initWebSocket();
    },

    //请求背景图片
    getBack() {
      let _this = this;
      _this.$axios.post("/index/Setting/background").then((res) => {
        if (res.data.status == 1) {
          this.img = res.data.data.img;
          if (!this.img) {
            this.img = this.img1;
          }
        }
      });
    },
    websocketonmessage(d) {
      let da = JSON.parse(d.data);
      if (da.info) {
        this.sockBattleList(da);
      }
    },

    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      //console.log("close:", e);
    },

    sockBattleList(data) {
      console.log("推送数据->:", data.info);
      if (data.info) {
        if (data.info.id == this.id) {
          if (data.info.status != 3) {
            this.getRoomList();
          }
          if (data.info.status == 3) {
            // console.log("状态3")
            this.add = false;
          }
        }
      }
    },

    //跳转
    goLucky() {
      this.$router.push({
        path: `/Lucky`,
      });
    },
    //获取房间信息
    getRoomList(over, tt) {
      if (this.add) {
        return;
      }
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/battleDetail", this.$qs.stringify(param))
        .then((res) => {
          var JSONres = JSON.parse(JSON.stringify(res));
          var aaa = JSON.parse(JSON.stringify(res.data.data));
          var playerinfo = JSON.parse(
            JSON.stringify(res.data.data.player_info)
          );
          if (over) {
            var re = {
              state: "update",
              info: res.data.data,
            };
            Utils.$emit("update", JSON.stringify(re));
          }
          var data = res.data;
          console.log("房间信息", data);
          if (data.status == "1") {
            this.fightboxList = data.data.boxInfo;

            for (let i = 0; i < this.fightboxList.length; i++) {
              this.boxList.push(this.fightboxList[i].skin_list);
            }
            this.peopleList = data.data.player_info;
            var mode = data.data.mode;
            var peopleListCopy = JSON.parse(JSON.stringify(this.peopleList)); //深拷贝

            if (mode != peopleListCopy.length) {
              for (let i = 0; i < mode - peopleListCopy.length; i++) {
                this.peopleList.push(this.peopleObj);
              }
            }
            for (let i = 0; i < this.peopleList.length; i++) {
              if (this.peopleList[i].id) {
                this.peopleList[i].state = true;
              } else {
                this.peopleList[i].state = false;
              }
              //   if (mode == 2) {
              //     this.peopleList[i].width = "48%";
              //   } else if (mode == 3) {
              //     this.peopleList[i].width = "33.33%";
              //   } else {
              //     this.peopleList[i].width = "25%";
              //   }
            }

            //对战的滚动图片数据
            for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }
            //copy double times
             for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }
             for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }
            
            console.log("fight=====",this.fightboxList.length);

            console.log(this.fightImg);

            //生成滚动的图片数组 Math.floor(30 / this.fightImg[0].length) + 1(循环次数，保证数据至少30个)
            if (this.fightImg.length == 1) {
              for (
                let i = 0;
                i < Math.floor(100 / this.fightImg[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.fightImg[0].length; j++) {
                  this.fightImgObj.push(this.fightImg[0][j]);
                  this.fightImgObj = this.fightImgObj.slice(0, 100);
                  this.fightImgObj = this.getRandomArr(this.fightImgObj, 100);
                }
              }
            } else if (this.fightImg.length != 1) {
              for (let i = 0; i < this.fightImg.length; i++) {
                for (
                  let j = 0;
                  j < Math.floor(50 / this.fightImg[i].length) + 1;
                  j++
                ) {
                  for (let x = 0; x < this.fightImg[i].length; x++) {
                    this.fightImgObj.push(this.fightImg[i][x]);
                    this.fightImgObj = this.fightImgObj.slice(0, 50 * (i + 1));
                    //没有打乱顺序
                    // this.fightImgObj = this.getRandomArr(this.fightImgObj,20 * (i+1))
                  }
                }
              }
            }
            console.log(this.fightImg);
            //把滚动数据放在各自下
            var winner_owner = JSONres.data.data.winner_owner;
            var array = [];
            if (winner_owner) {
              for (var i = 0; i < this.peopleList.length; i++) {
                var json = JSON.parse(JSON.stringify(this.fightImgObj));
                var z = 1;
                var c = i;
                for (var iv = 90; iv < json.length; iv += 90) {
                  if (winner_owner.length == 2) {
                    winner_owner[i] = {
                      id: winner_owner[i]["id"],
                      imageUrl: winner_owner[i]["img"],
                      itemName: winner_owner[i]["name"],
                      img: winner_owner[i]["img"],
                      name: winner_owner[i]["name"],
                      price: winner_owner[i]["price"],
                    };
                  } else {
                    if (winner_owner[c]) {
                      winner_owner[c] = {
                        id: winner_owner[c]["id"],
                        imageUrl: winner_owner[c]["img"],
                        itemName: winner_owner[c]["name"],
                        img: winner_owner[i]["img"],
                        name: winner_owner[i]["name"],
                        price: winner_owner[i]["price"],
                      };
                    }
                  }
                  if (iv < json.length) {
                    if (winner_owner.length == playerinfo.length) {
                      json[iv] = winner_owner[i];
                    } else {
                      if (winner_owner[c]) {
                        json[iv] = winner_owner[c];
                      }
                    }
                    c += playerinfo.length;
                    z++;
                  }
                }
                array.push(json);
              }
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].fightBox = array[i];
              }
            } else {
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].fightBox = this.fightImgObj;
              }
            }
            //对战进行中2  对战未开始1  3结束
            if (data.data.status == "3") {
              this.playAlarm2();
              var box = data.data.winner_owner;
              let numPrice = 0;
              //是否为平局
              if (box.length == 0) {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = this.peopleList[i].skin_list;
                  this.peopleList[i].end = 2;
                  this.peopleList[i].winState = true;
                  this.peopleList[i].totalPrice = 0;
                  if (this.peopleList[i].skin_list == undefined) {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  } else {
                    for (let j = 0; j < this.peopleList[i].box.length; j++) {
                      this.peopleList[i].totalPrice += Number(
                        this.peopleList[i].box[j].price
                      );
                    }
                    this.peopleList[i].totalPrice = Number(
                      this.peopleList[i].totalPrice
                    ).toFixed(2);
                  }
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              } else {
                for (let i = 0; i < this.peopleList.length; i++) {
                  if (this.peopleList[i].id == data.data.winner) {
                    this.peopleList[i].box = box;
                    this.peopleList[i].totalPrice = 0;
                    this.peopleList[i].winState = true;
                    for (let j = 0; j < box.length; j++) {
                      this.peopleList[i].totalPrice += Number(box[j].price);
                    }
                  } else {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  }
                  this.peopleList[i].totalPrice = Number(
                    this.peopleList[i].totalPrice
                  ).toFixed(2);
                  this.peopleList[i].end = 2;
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              }
              if (!tt) {
                setTimeout(() => {
                  this.getRoomList(null, "tt");
                }, 100);
              }
            } else if (data.data.status == "2") {
              //对战进行中
              this.fightResult1();
            } else {
              //对战未开始
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].end = 1;
              }
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
                this.fightboxList[i].state = false;
                this.fightboxList[0].state = true;
              }
            }
          }
        })
        .catch((reason) => {
          //this.getRoomList();
          console.log(reason);
        });
    },
    getRoomList1(tt) {
      let param = {
        battle_id: this.id,
      };
     
      this.$axios
        .post("/index/Battle/battleDetail", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          if (data.status == "1") {
            this.fightboxList = data.data.boxInfo;
            for (let i = 0; i < this.fightboxList.length; i++) {
              this.boxList.push(this.fightboxList[i].skin_list);
            }
            this.peopleList = data.data.player_info;
            var mode = data.data.mode;
            var peopleListCopy = JSON.parse(JSON.stringify(this.peopleList)); //深拷贝
            if (mode != peopleListCopy.length) {
              for (let i = 0; i < mode - peopleListCopy.length; i++) {
                this.peopleList.push(this.peopleObj);
              }
            }
            for (let i = 0; i < this.peopleList.length; i++) {
              if (this.peopleList[i].id) {
                this.peopleList[i].state = true;
              } else {
                this.peopleList[i].state = false;
              }
              //   if (mode == 2) {
              //     this.peopleList[i].width = "48%";
              //   } else if (mode == 3) {
              //     this.peopleList[i].width = "33.33%";
              //   } else {
              //     this.peopleList[i].width = "25%";
              //   }
            }

            //对战的滚动图片数据
            for (let i = 0; i < this.fightboxList.length; i++) {
              if (this.fightboxList[i].skin_list.length > 0) {
                this.fightImg.push(this.fightboxList[i].skin_list);
              }
            }

            //生成滚动的图片数组 Math.floor(30 / this.fightImg[0].length) + 1(循环次数，保证数据至少30个)
            if (this.fightImg.length == 1) {
              for (
                let i = 0;
                i < Math.floor(50 / this.fightImg[0].length) + 1;
                i++
              ) {
                for (let j = 0; j < this.fightImg[0].length; j++) {
                  this.fightImgObj.push(this.fightImg[0][j]);
                  this.fightImgObj = this.fightImgObj.slice(0, 50);
                  this.fightImgObj = this.getRandomArr(this.fightImgObj, 50);
                }
              }
            } else if (this.fightImg.length != 1) {
              for (let i = 0; i < this.fightImg.length; i++) {
                for (
                  let j = 0;
                  j < Math.floor(50 / this.fightImg[i].length) + 1;
                  j++
                ) {
                  for (let x = 0; x < this.fightImg[i].length; x++) {
                    this.fightImgObj.push(this.fightImg[i][x]);
                    this.fightImgObj = this.fightImgObj.slice(0, 50 * (i + 1));
                    //没有打乱顺序
                    // this.fightImgObj = this.getRandomArr(this.fightImgObj,20 * (i+1))
                  }
                }
              }
            }

            //把滚动数据放在各自下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].fightBox = this.fightImgObj;
            }

            //对战进行中2  对战未开始1  3结束
            if (data.data.status == "3") {
              var box = data.data.winner_owner;
              let numPrice = 0;
              //是否为平局
              if (data.data.winner_owner.length == 0) {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = this.peopleList[i].skin_list;
                  this.peopleList[i].end = 2;
                  this.peopleList[i].winState = true;
                  this.peopleList[i].totalPrice = 0;
                  if (this.peopleList[i].skin_list == undefined) {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  } else {
                    for (let j = 0; j < this.peopleList[i].box.length; j++) {
                      this.peopleList[i].totalPrice += Number(
                        this.peopleList[i].box[j].price
                      );
                    }
                    this.peopleList[i].totalPrice = Number(
                      this.peopleList[i].totalPrice
                    ).toFixed(2);
                  }
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              } else {
                for (let i = 0; i < this.peopleList.length; i++) {
                  if (this.peopleList[i].id == data.data.winner) {
                    this.peopleList[i].box = box;
                    this.peopleList[i].totalPrice = 0;
                    this.peopleList[i].winState = true;
                    for (let j = 0; j < box.length; j++) {
                      this.peopleList[i].totalPrice += Number(box[j].price);
                    }
                  } else {
                    this.peopleList[i].winState = false;
                    this.peopleList[i].box = [];
                    this.peopleList[i].box.push(this.peopleObj);
                    this.peopleList[i].totalPrice = "0.01";
                    this.peopleList[i].box[0].name = "凉凉奖励";
                  }
                  this.peopleList[i].totalPrice = Number(
                    this.peopleList[i].totalPrice
                  ).toFixed(2);
                  this.peopleList[i].end = 2;
                }
                for (let i = 0; i < this.fightboxList.length; i++) {
                  this.fightboxList[i].state = false;
                  this.fightboxList[this.fightboxList.length - 1].state = true;
                }
              }
              if (!tt) {
                this.getRoomList1("tt");
              }
            } else if (data.data.status == "2") {
              //对战进行中
              this.fightResult1();
            } else {
              //对战未开始
              for (let i = 0; i < this.peopleList.length; i++) {
                this.peopleList[i].end = 1;
              }
              for (let i = 0; i < this.fightboxList.length; i++) {
                this.totalPrice += Number(this.fightboxList[i].price);
                this.fightboxList[i].state = false;
                this.fightboxList[0].state = true;
              }
            }
          }
        })
        .catch((reason) => {
          //this.getRoomList();
          console.log(reason);
        });
    },

    //跳转至活动规则
    goLuckyRule() {
      this.$router.push({
        path: `/LuckyRule`,
      });
    },
    //跳转至活动历史
    goLuckyHistory() {
      this.$router.push({
        path: `/LuckyHistory`,
      });
    },
    //房间观看对战 请求对战结果
    fightResult1() {

      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/resultInfo", this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            // console.log(111,data)
            this.playAlarm1();
            let box = data.data.result;
            this.mode = box.length;

            console.log(this.mode)
            this.openWin = true;
            this.roomLineState = true;

            //把结果放在对应的玩家下
            for (let i = 0; i < this.peopleList.length; i++) {
              this.peopleList[i].boxImg = [];
              for (let j = 0; j < box.length; j++) {
                this.peopleList[i].boxImg.push(box[j][i]);
              }
            }

            //在滚动的图片放入相应的结果
            // let obj = JSON.parse(JSON.stringify(this.peopleList));
            // for (let i = 0; i < obj.length; i++) {
            // 	for (let j = 0; j < obj[i].boxImg.length; j++) {
            // 		obj[i].fightBox[45 * (j + 1)] = obj[i].boxImg[j];
            // 	}
            // }
            // this.peopleList = obj;

            // console.log(this.peopleList)
            //调整显示图片的地址
            for (let i = 0; i < this.peopleList.length; i++) {
              for (let j = 0; j < this.peopleList[i].fightBox.length; j++) {
                if (!this.peopleList[i].fightBox[j].imageUrl) {
                  this.peopleList[i].fightBox[j].imageUrl =
                    this.peopleList[i].fightBox[j].img;
                }
              }
            }

            // console.info("dex/Battle/resultInfo", data);


            //动画
            if (this.mode == 1) {
              console.log("开启音乐111");
              setTimeout(() => {
                for (let i = 0; i < this.peopleList.length; i++) {
                  this.peopleList[i].box = [];
                  this.peopleList[i].box.push(box[0][i]);
                  this.peopleList[i].totalPrice =
                    this.peopleList[i].box[0].price;
                }
                this.$forceUpdate();
                this.playAlarm2();
              }, 7000);
              setTimeout(() => {
                this.over();
                this.openWin = false;
                this.roomLineState = false;
              }, 8000);
            } else {
              for (let i = 0; i < this.mode; i++) {
                setTimeout(() => {
                  for (let j = 0; j < this.peopleList.length; j++) {
                    if (!this.peopleList[j].box) {
                      this.peopleList[j].box = [];
                    }
                    this.peopleList[j].box.push(box[i][j]);
                    this.peopleList[j].totalPrice = 0;
                    for (let x = 0; x < this.peopleList[j].box.length; x++) {
                      this.peopleList[j].totalPrice += Number(
                        this.peopleList[j].box[x].price
                      );
                    }
                    this.peopleList[j].totalPrice =
                      this.peopleList[j].totalPrice.toFixed(2);
                  }
                  for (let y = 0; y < this.fightboxList.length; y++) {
                    this.fightboxList[y].state = false;
                  }
                  if (i + 1 < this.mode) {
                    this.fightboxList[i + 1].state = true;
                  } else if (i + 1 == this.mode) {
                    this.fightboxList[i].state = true;
                  }
                  this.$forceUpdate();
                }, (i + 1) * 8000);
              }

              //音乐
              /*  for (let i = 0; i < this.mode; i++) {
								  setTimeout(() => {
								    this.playAlarm1();
								  }, i * 5000 );
								}
								for (let i = 0; i < this.mode; i++) {
								  setTimeout(() => {
								    this.playAlarm2();
								  }, (i + 1 ) * 5000 - 1000);
								} */

              setTimeout(() => {
                this.openWin = false;
                this.roomLineState = false;
                this.over();
              }, this.mode * 8000);
            }
          }
        });
    },

    //对局结束
    over() {
      var _this = this;
      let param = {
        battle_id: this.id,
      };
      this.$axios
        .post("/index/Battle/setBattleStatus", this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          if (res.data.status == "1") {
            // console.log("对战结束")

            //发送
            /* let sendData = {
							   state: "update",
							   battle_id: _this.id,
							 };
							 _this.websocketsend(JSON.stringify(sendData));*/
            _this.getRoomList("over");
          }
        });
    },
    // 打乱数组
    getRandomArr(arr, num) {
      var _arr = arr.concat();
      var n = _arr.length;
      var result = [];

      // 先打乱数组
      while (n-- && num--) {
        var index = Math.floor(Math.random() * n); // 随机位置
        [_arr[index], _arr[n]] = [_arr[n], _arr[index]]; // 交换数据
        result.push(_arr[n]); // 取出当前最后的值，即刚才交换过来的值
      }
      return result;
    },
    //点击参与活动
    goParticipate(index) {
      // this.loading = true;

      // console.log(index);

      // return
      this.disabled = true;
      let _this = this;
      let param = {
        battle_id: this.id,
      };
      _this.$axios
        .post("/index/Battle/addBattle", _this.$qs.stringify(param))
        .then((res) => {
          var data = res.data;
          // console.log(data);
          if (data.status == "1") {
            this.loading = false;
            //扣除余额
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("money", data.data.total_amount);
            //人数已满 开始对战
            if (data.data.battle_status == "start") {
              let box = data.data.result;
              this.mode = box.length;
              this.add = true;
              this.getRoomList();
              this.playAlarm1(); // 开启音乐
            }
          } else {
            this.disabled = false;
            this.loading = false;
            this.$message({
              message: data.msg,
              type: "warning",
            });
          }
        })
        .catch((reason) => {
          //console.log("reason" + reason);
          //this.goParticipate(index);
          console.log(reason);
        });
      // this.loading = false;
    },
    //点击盒子查看右侧图片
    selImg(index) {
      this.boxListIndex = index;
      this.fightboxList.forEach((item) => {
        item["state"] = false;
      });
      this.fightboxList[index]["state"] = true;
    },
  },
};
</script>

<style lang="less" scoped>
.win-img {
  width: 115px !important;
  height: 103.47px !important;
  margin-top: 0 !important;
}
.fail-img {
  width: 116px !important;
  height: 102px !important;
  margin-top: 0 !important;
}
.room-win-status {
  background-color: rgba(247, 253, 0, 0.09) !important;
  height: max-content;
  //   padding: 0 3px 5px 3px;
}
.room-fail-status {
  background-color: rgba(53, 69, 81, 0.4) !important;
  height: max-content;
  //   padding: 0 3px 5px 3px;
}
.room::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  //   background: var(--backimg) no-repeat center center;
  /*  z-index:1; */
  position: fixed;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-size: cover;
  -webkit-background-size: cover;
  /* 兼容Webkit内核浏览器如Chrome和Safari */
  -o-background-size: cover;
  /* 兼容Opera */
  zoom: 1;
}
.room {
  position: relative;
  overflow: hidden;
  width: 100%;
  background-size: 100% 100%;
  z-index: 2;

  .audio {
    display: none;
  }

  .room-right {
    width: 300px;
    height: calc(100% - 103px);
    position: fixed;
    top: 60px;
    right: 17px;
    background-color: #24252f;
    z-index: 999;

    .room-right-name {
      text-align: center;
      padding: 10px;
      color: #848492;
      font-size: 16px;

      span {
        color: #e9b10e;
      }
    }

    .room-right-list {
      padding: 0 10px;
      height: 100%;
      background-color: #30313f;
      overflow: hidden;
      overflow-y: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .roomlist-title {
        font-size: 16px;
        color: #c3c3e2;
        padding: 10px 0;
      }

      ul {
        margin: 0 -4px;
        display: flex;
        flex-flow: row wrap;

        li {
          width: 50%;

          .roomlist-warp {
            margin: 4px;
            background-color: #24252f;

            overflow: hidden;

            .roomlist-img {
              width: 100%;
              background-image: url("../assets/img/box-skins-blue.jpg");
              background-size: 100% 100%;

              img {
                width: 100%;
                height: auto;
              }
            }

            .roomlist-name {
              font-size: 14px;
              color: #c3c3e2;
              padding: 10px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      img {
        width: 100px;
        height: 100px;
      }
    }

    /*滚动条样式*/
    .room-right-list::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }

    .room-right-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }

    .room-right-list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }

  .room-left {
    padding: 16px 316px 0 16px;

    .room-left-hint {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .roomleft-num {
        display: flex;
        align-items: center;
        color: #848492;
        font-size: 15px !important;
        span {
          margin-right: 5px;
          &:last-child:hover {
            color: #e9b10e;
          }
        }
      }

      .roomleft-btn {
        margin-right: -5px;

        span {
          padding: 6px 15px;
          color: #fff;
          font-weight: 600;
          font-size: 14px;
          border: 1px solid #e9b10e;
          margin-right: 10px;
        }

        :hover {
          background-color: #3a3f50;
        }
      }
    }

    .room-left-box {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      //   background-color: #2b2c37;
      justify-content: center;

      overflow: hidden;

      .roombox-warp {
        overflow-x: auto;

        padding-bottom: 20px;
        margin-bottom: -20px;

        ul {
          display: flex;
          align-items: center;
          background-color: #1a1c24;

          li {
            flex-shrink: 0;

            width: 68px;
            height: 68px;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .roombox-img {
              width: 70%;
              height: 70%;

              img {
                height: 100%;
                width: 100%;
                object-fit: contain;
              }
            }
          }

          #room-li {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              //   background-image: url("../assets/new/bg_border.png");
              background-size: 100% 100%;
              background-image: url("~@/assets/img/blindBox/blind-box-bg.png");
              //   transform: rotate(180deg);
            }

            .roombox-num1 {
              width: 30px;
              height: 30px;
              line-height: 30px;
            }

            .roombox-img {
              top: -60px;
              display: flex;
              align-items: center;
              justify-content: center;

              img {
                object-fit: contain;
                // height: 85px;
                // width: 85px;
                width: 100%;
              }
            }

            .roombox-num1::before {
              box-shadow: 0px 0px 7px #e9b10e;
            }
          }
        }
      }
    }

    .room-left-people {
      .roompe-warp {
        position: relative;

        .ul1 {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(60px, 1fr));
          gap: 10px;
          .li1 {
            width: 100%;
            // max-width: 300px;
            margin: 0 auto;
            display: flex;
            .room-warp {
              width: 100%;

              .room1 {
                height: 110px;
                line-height: 110px;
                background-image: linear-gradient(#2d2d36, #483856);
                /* background-image: linear-gradient(
                  rgba(43, 44, 55, 1),
                  rgba(35, 71, 59, 1)
                );*/
                // border-top-left-radius: 5px;
                // border-top-right-radius: 5px;
                text-align: center;
                position: relative;
                overflow: hidden;

                .pool {
                  overflow: hidden;
                  position: absolute;
                  top: 0;
                  width: 100%;
                  height: 100%;
                  background-image: linear-gradient(#2d2d36, #483856);
                  background-size: 100% 100%;
                  z-index: 2;

                  .pool_list {
                    width: 100%;
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    animation: run 8s;
                    animation-timing-function: ease-out; //动画 速度一样
                    animation-iteration-count: 1; //播放几次动画
                    animation-delay: 0s; //动画运行前等待时间
                    animation-fill-mode: forwards; //动画结束 是否保持

                    -webkit-animation: run 8s;
                    -webkit-animation-timing-function: ease-out; //动画 速度一样
                    -webkit-animation-iteration-count: 1; //播放几次动画
                    -webkit-animation-delay: 0s; //动画运行前等待时间
                    -webkit-animation-fill-mode: forwards; //动画结束 是否保持

                    -moz-animation: run 8s;
                    -moz-animation-timing-function: ease-out; //动画 速度一样
                    -moz-animation-iteration-count: 1; //播放几次动画
                    -moz-animation-delay: 0s; //动画运行前等待时间
                    -moz-animation-fill-mode: forwards; //动画结束 是否保持

                    -o-animation: run 8s;
                    -o-animation-timing-function: ease-out; //动画 速度一样
                    -o-animation-iteration-count: 1; //播放几次动画
                    -o-animation-delay: 0s; //动画运行前等待时间
                    -o-animation-fill-mode: forwards; //动画结束 是否保持

                    .list_item {
                      width: 100%;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;

                      .pool-img {
                        height: 100px;

                        img {
                          object-fit: contain;
                          height: 100px;
                          width: auto;
                          margin: 0 auto;
                        }
                      }
                    }

                    @keyframes run {
                    0% {
                      transform: translateY(0);
                    }

                    100% {
                      transform: translateY(-9000px);
                    }
                  }

                  &.pool-ul2 {
                    animation: run2 16s;
                    -o-animation: run2 16s;
                    -moz-animation: run2 16s;
                    -webkit-animation: run2 16s;
                  }

                  @keyframes run2 {
                    0% {
                      // top: 0;
                      transform: translateY(0);
                    }

                    50% {
                      // top: -9000px;
                      transform: translateY(-9000px);
                    }

                    100% {
                      // top: -18000px;
                      transform: translateY(-18000px);
                    }
                  }

                  &.pool-ul3 {
                    animation: run3 24s;
                    -o-animation: run3 24s;
                    -moz-animation: run3 24s;
                    -webkit-animation: run3 24s;
                  }

                  @keyframes run3 {
                    0% {
                      // top: 0;
                      transform: translateY(0);
                    }

                    33.33% {
                      // top: -9000px;
                      transform: translateY(-9000px);
                    }

                    66.66% {
                      // top: -18000px;
                      transform: translateY(-18000px);
                    }

                    100% {
                      // top: -27000px;
                      transform: translateY(-27000px);
                    }
                  }

                  &.pool-ul4 {
                    animation: run4 32s;
                    -o-animation: run4 32s;
                    -moz-animation: run4 32s;
                    -webkit-animation: run4 32s;
                  }

                  @keyframes run4 {
                    0% {
                      // top: 0;
                      transform: translateY(0);
                    }

                    25% {
                      // top: -9000px;
                      transform: translateY(-9000px);
                    }

                    50% {
                      // top: -18000px;
                      transform: translateY(-18000px);
                    }

                    75% {
                      // top: -27000px;
                      transform: translateY(-27000px);
                    }

                    100% {
                      // top: -36000px;
                      transform: translateY(-36000px);
                    }
                  }

                  &.pool-ul5 {
                    animation: run5 40s;
                    -o-animation: run5 40s;
                    -moz-animation: run5 40s;
                    -webkit-animation: run5 40s;
                  }

                  @keyframes run5 {
                    0% {
                      // top: 0;
                      transform: translateY(0);
                    }

                    20% {
                      // top: -9000px;
                      transform: translateY(-9000px);
                    }

                    40% {
                      // top: -18000px;
                      transform: translateY(-18000px);
                    }

                    60% {
                      // top: -27000px;
                      transform: translateY(-27000px);
                    }

                    80% {
                      // top: -36000px;
                      transform: translateY(-36000px);
                    }

                    100% {
                      // top: -45000px;
                      transform: translateY(-45000px);
                    }
                  }

                  &.pool-ul6 {
                    animation: run6 48s;
                    -o-animation: run6 48s;
                    -moz-animation: run6 48s;
                    -webkit-animation: run6 48s;
                  }

                  @keyframes run6 {
                    0% {
                      // top: 0;
                      transform: translateY(0);
                    }

                    16.66% {
                      // top: -9000px;
                      transform: translateY(-9000px);
                    }

                    33.32% {
                      // top: -18000px;
                      transform: translateY(-18000px);
                    }

                    49.98% {
                      // top: -27000px;
                      transform: translateY(-27000px);
                    }

                    66.64% {
                      // top: -36000px;
                      transform: translateY(-36000px);
                    }

                    83.30% {
                      // top: -45000px;
                      transform: translateY(-45000px);
                    }

                    100% {
                      // top: -54000px;
                      transform: translateY(-54000px);
                    }
                  }
                  }

                  
                }

                .room1-img {
                  //   background-image: linear-gradient(
                  //     rgba(43, 44, 55, 1),
                  //     rgba(35, 71, 59, 1)
                  //   );
                  img {
                    margin-top: 40px;
                    width: 30px;
                    height: 30px;
                  }

                  .room1-text2 {
                    margin-top: 0px;
                    font-size: 20px;
                    color: #848492;
                  }

                  .room1-text1 {
                    margin-top: 0px;
                    font-size: 20px;
                    color: #e9b10e;
                  }
                }

                button {
                  margin-top: -50px;
                  padding: 8px 10px;
                  background-color: #e9b10e;
                  border-radius: 8px;
                  color: #1a1c24;
                  font-size: 8px;
                  font-weight: 400;
                }

                button:hover {
                  cursor: pointer;
                }
              }

              .room1-win {
                // background-image: linear-gradient(#2f2f36, #836a22);
                background-image: url("../assets/img/lucky/win_back.13766729.png");
              }

              .room-back {
                background-image: url("../assets/img/lucky/conduct.33d8e98d.png");
                // background-image: linear-gradient(#2d2d36, #483856);
              }

              .room2 {
                margin-top: 20px;
                text-align: center;

                .room2-tou {
                  img {
                    width: 50px;
                    height: 50px;
                    border-radius: 50%;
                  }
                }

                .room2-name {
                  font-size: 14px;
                  color: #c3c3e2;
                }

                span {
                  font-size: 14px;
                  color: #848492;
                  display: inline-block;
                  padding-top: 50px;
                }
              }
            }
          }

          .room-line:before {
            content: "";
            display: block;
            width: 100%;
            height: 2px;
            position: absolute;
            z-index: 8;
            top: 60px;
            left: 0;
            background-color: #e9b10e;
            box-shadow: 0px 0px 7px #e9b10e;
          }
        }
      }

      .roompe-line {
        position: absolute;
        left: 0;
        top: 60px;
        width: 100%;
        height: 3px;
        z-index: 9;
        background-color: #e9b10e;
      }

      .roompe-warp::before {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-left: 20px solid #e9b10e;
        border-bottom: 10px solid transparent;
        position: absolute;
        left: 0;
        top: 51px;
        z-index: 10;
      }

      .roompe-warp::after {
        content: "";
        display: block;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-right: 20px solid #e9b10e;
        border-bottom: 10px solid transparent;
        position: absolute;
        right: 0;
        top: 51px;
        z-index: 10;
      }
    }

    .room-left-bot {
      margin-top: 30px;
      color: #848492;
      font-size: 12px;
      text-align: center;
      margin-bottom: 20px;

      & > p:last-child {
        color: #e9b10e;
      }

      p:hover {
        text-decoration: underline;
      }
    }
  }

  //开奖列表
  .win-list {
    margin-top: 20px;

    .win-title {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        height: 24px;
        width: auto;
      }

      span {
        margin-left: 5px;
        font-size: 24px;
        color: #e9b10e;
      }
    }

    .win-ul {
      padding: 0 4px;
      margin: -3px;
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;

      .win-li {
        width: 100%;
        //min-width: 200px;

        .win-warp {
          margin: 5px 0;
          background-color: #2b2c37;

          .img {
            height: 150px;
            // border-top-left-radius: 5px;
            // border-top-right-radius: 5px;
            background-position: top center;
            background-image: url("../assets/img/box-skins-blue.jpg");
            // background-size: 100% 95%;
            padding: 0 5px;
            display: grid;
            place-items: center;
            img {
              width: 70%;
              height: auto;
              max-width: 200px;
            }
          }

          h6 {
            padding: 5px;
            display: flex;
            align-items: center;
            justify-content: center;
            // white-space: nowrap;
            // overflow: hidden;
            // text-overflow: ellipsis;
            text-align: center;
            color: #fff;
            font-weight: 400;
            width: calc(100% - 10px);
            img {
              height: 16px;
              width: auto;
            }

            span {
              margin-left: 5px;
              font-size: 16px;
              color: #e9b10e;
            }
          }
        }
      }
    }
  }
}

.room-btn-list-wrapper {
  overflow: hidden;
  margin: 20px 30px;

  .btn-list {
    display: flex;
    align-items: center;
    margin-right: -10px;

    .btn-list__item {
      width: 33%;
      .item__out-wrapper {
        margin-right: 10px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 40%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            &.item__out-wrapper__inner-wrapper__content--blue {
              background-image: url("~@/assets/img/blindBox/Group92326.png");
              color: var(--gray2, #dde5e9);
            }
            &.item__out-wrapper__inner-wrapper__content--yellow {
              background-image: url("~@/assets/img/blindBox/Group92335.png");
              color: var(--gray4, #0d141a);
            }
          }
        }
      }
    }
  }
}

// .room-people__wrapper {
//   overflow: hidden;

//   .btn-list {
//     display: flex;
//     flex-wrap: wrap;
//     align-items: center;
//     margin-right: -6px;

//     .btn-list__item {
//       width: 50%;
//       .item__out-wrapper {
//         margin-right: 6px;
//         margin-bottom: 6px;

//         .item__out-wrapper__inner-wrapper {
//           position: relative;
//           padding-bottom: 80%;

//           .item__out-wrapper__inner-wrapper__content {
//             position: absolute;
//             left: 0;
//             right: 0;
//             top: 0;
//             bottom: 0;

//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             justify-content: center;

//             background: rgba(53, 69, 81, 0.4);

//             .content__header-img {
//               width: 40px;
//               height: 40px;
//               border-radius: 50%;
//               overflow: hidden;
//             }
//             .content__user-name {
//               color: var(--gary3, #889aa7);
//               font-family: "PingFang SC";
//               font-size: 12px;
//               font-style: normal;
//               font-weight: 400;
//               line-height: normal;
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
