<template>
  <div class="oran">
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
    <div class="responsive-container oran-warp">
      <div class="oran-top">
        <div class="orantop-left">饰品商城</div>
      </div>

      <!-- <ButtonGroup
        labelKey="name"
        valueKey="id"
        :value="Number(classId)"
        :onChange="(id, item) => selOran(item.status, id)"
        :btnList="selList"
      ></ButtonGroup> -->

      <TabCatgoryBar
        v-if="classList.length > 0"
        labelKey="name"
        valueKey="id"
        :value="subClassId"
        :onChange="(id, item) => setSubClassId(item)"
        :tabList="classList"
      ></TabCatgoryBar>

      <div class="oran-sel">
        <div class="class-box">
          <div class="sel-bot">
            <div
              class="selbot-right"
              :style="{
                margin: classList.length ? '30px 0 0 0' : '0px',
              }"
            >
              <el-input
                placeholder="按名称搜索"
                v-model="searchKey"
                style="width: calc(100% - 100px); max-width: 280px"
                class="input1"
              >
                <i
                  slot="suffix"
                  class="el-input__icon el-icon-search input1-i"
                  @click="getList"
                ></i>
              </el-input>

              <div class="sort-block" @click="listSort">
                <div style="font-weight: bold">价格</div>
                <div class="sort-icon">
                  <i
                    class="iconfont icon-shangjiantou icon"
                    :class="{ 'active-icon': priceSort }"
                  ></i>
                  <i
                    class="iconfont icon-xiajiantou icon"
                    :class="{ 'active-icon': !priceSort }"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <GoodsList
        :goodsList="list"
        @refreshData="refreshData"
        :fixedBottom="$store.state.btnFixed"
      ></GoodsList>
    </div>
    <div class="more-btn">
          <span @click="moveList()">{{
            loadMoreLoading ? "加载中" : "查看更多"
          }}</span>
        </div>
    <div class="clear"></div>
    <div class="myhomebot-mag"></div>
    <myhomebot></myhomebot>
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import Utils from "@/assets/js/util.js";
import navheader from "@/components/navheader.vue";

import TabCatgoryBar from "@/components/tabCatgoryBar/index.vue";
import ButtonGroup from "@/components/buttonGroup/index";
import GoodsList from "./components/goodsList/index";

export default {
  components: {
    myhomebot,
    myinform,
    navheader,
    TabCatgoryBar,
    ButtonGroup,
    GoodsList,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      loadMoreLoading:false,
      fixedBottom: true,
      loading: false,
      buyCarState: false,
      input1: "",
      input2: "",
      totalSize: 0,
      page: 1,
      pageSize: 24,
      list: [],
      buyCart: [],
      total_num: 0,
      total_price: 0,
      priceSort: true,

      searchKey: "",
      // 品质
      pzList: [],
      // 外观
      wgList: [],
      // 品质选中
      rarity: "不限",
      // 外观选中
      exterior: "不限",
      // 主分类id
      classId: "",
      // 小分类id
      subClassId: "all",
      // 推荐
      recommend: 1,
      // 二级分类
      classList: [],
      // 一级分类
      selList: [],

      showSure: false,
    };
  },
  watch: {
    searchKey: {
      handler(value) {
        if (value.length) return;
        this.getList(this.page);
      },
    },

    // 监听一级分类选中获取二级分类
    classId: {
      handler(value) {
        if (!value) return;
        this.classList = [
          {
            name: "全部",
            id: "all",
          },
        ];
        this.subClassId = "all";

        this.$axios
          .post(
            "/index/Lucky/subclassList",
            this.$qs.stringify({ type_id: value })
          )
          .then((res) => {
            console.log(res);

            let data = res.data;

            if (data.status) {
              if (data.data) {
                this.classList.push(...data.data.list);
                this.subClassId = this.classList[0].id;
              }
            }
          });
      },
    },
  },
  mounted() {
    this.init();
    this.$nextTick(() => {
      // const scrollTop = this.$refs.scrollContainer.scrollTop;
      // console.log(scrollTop);
      window.addEventListener("scroll", this.handleScroll, { passive: false });
    })
    
  },
  methods: {
    
    init() {
      // this.getListClass();
      this.getList(this.page);
      this.getTagAndPz();
    },
    refreshData() {
      this.page = 1;
      this.init();
    },
    setSubClassId(item) {
      this.subClassId = item.id;
      this.page = 1;
      this.getList();
    },
    selOran(status, id) {
      this.recommend = status == 2 ? 1 : 2;
      this.classId = id;
      this.getList();
    },
    getListClass() {
      this.$axios.post("/index/Lucky/luckyTypeList").then((res) => {
        let data = res.data;
        if (data.status == 1) {
          this.selList = data.data;
          this.classId = this.selList[0].id;
        }
      });
    },
    getTagAndPz() {
      this.$axios
        .post("/index/Store/exterior", this.$qs.stringify({}))
        .then((res) => {
          const { exterior, rarity } = res.data.data;
          this.pzList = ["不限"].concat(rarity.map((item) => item.rarityName));
          this.wgList = ["不限"].concat(
            exterior.map((item) => item.exteriorName)
          );
        });
    },
    //分页
    currentChange(val) {
      this.getList(val);
    },
    //查看更多
    moveList() {
      // console.log(this.pageSize);
      if (this.loadMoreLoading) return;

      let _this = this;
      let param = {
        page: this.page +=1,
        pageSize: this.pageSize,
      };

      if (this.priceSort) {
        param["order"] = "asc";
      }
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }
      if (this.classId && this.classId != "all") {
        param["type_id"] = this.classId;
      }
      if (this.subClassId && this.subClassId != "all") {
        param["subclass_id"] = this.subClassId;
      }
      if (this.rarity && this.rarity != "不限") {
        param["rarity"] = this.rarity;
      }
      if (this.exterior && this.exterior != "不限") {
        param["exterior"] = this.exterior;
      }
      if (this.classId == "1") {
        param["recommend"] = 1;
        delete param.type_id;
      }

      this.loadMoreLoading = true;
      _this.$axios
        .post("index/Store/skinList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            // this.pageSize = this.pageSize + 12;
            const newList = data.data.list;

            this.list = this.list.concat(newList);
          }
        })
        .finally(() => {
          this.loadMoreLoading = false;
        });
    },
    //商城列表
    getList(val) {
      let param = {
        page: val,
        pageSize: this.pageSize,
      };
      if (this.priceSort) {
        param["order"] = "asc";
      }
      if (this.searchKey) {
        param["searchKey"] = this.searchKey;
      }
      if (this.classId && this.classId != "all") {
        param["type_id"] = this.classId;
      }
      if (this.subClassId && this.subClassId != "all") {
        param["subclass_id"] = this.subClassId;
      }
      if (this.rarity && this.rarity != "不限") {
        param["rarity"] = this.rarity;
      }
      if (this.exterior && this.exterior != "不限") {
        param["exterior"] = this.exterior;
      }
      if (this.classId == "1") {
        param["recommend"] = 1;
        delete param.type_id;
      }

      this.$axios
        .post("index/Store/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.list;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
          }
        });
    },
    //手机端
    buyCarPhone() {
      this.buyCarState = !this.buyCarState;
    },
    offBuyCar() {
      this.buyCarState = false;
    },
    //取消全部
    offAll() {
      this.buyCart = [];
      for (let i = 0; i < this.list.length; i++) {
        this.list[i].state = false;
      }
      this.buyNum();
    },
    //加一
    addBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num + 1;
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //减一
    subBuy(id) {
      for (let i = 0; i < this.buyCart.length; i++) {
        if (this.buyCart[i].id == id) {
          this.buyCart[i].num = this.buyCart[i].num - 1;
        }
        if (this.buyCart[i].num == 0) {
          this.buyCart.splice(i, 1);
          for (let j = 0; j < this.list.length; j++) {
            if (this.list[j].id == id) {
              this.list[j].state = false;
            }
          }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    buyState(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          this.list[i].state = !this.list[i].state;
          if (this.list[i].state == true) {
            this.buyCart.push(this.list[i]);
            for (let j = 0; j < this.buyCart.length; j++) {
              if (id == this.buyCart[j].id) {
                this.buyCart[j].num = 1;
              }
            }
          } else {
            for (let j = 0; j < this.buyCart.length; j++) {
              if (id == this.buyCart[j].id) {
                this.buyCart.splice(j, 1);
              }
            }
          }
        }
      }
      this.buyNum();
      this.$forceUpdate();
    },
    //购物车数量和价格
    buyNum() {
      this.total_num = 0;
      this.total_price = 0;
      for (let i = 0; i < this.buyCart.length; i++) {
        this.total_num += this.buyCart[i].num;
        this.total_price += Number(this.buyCart[i].price) * this.buyCart[i].num;
      }
      this.total_price = this.total_price.toFixed(2);
    },
    //立即购买
    goBuy() {
      this.loading = true;
      let param = {
        skin_info: this.buyCart,
      };
      this.$axios
        .post("index/Store/buynew", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.loading = false;
            this.buyCart = [];
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            this.total_num = 0;
            this.total_price = 0;
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("masonry", data.data.total_amount);
            this.$message({
              message: data.msg,
              type: "success",
            });
            this.showSure = false;
          } else {
            this.loading = false;

            let msg = res.data.msg;
            if (msg.indexOf("余额不足") != -1) {
              this.$message({ message: "余额不足，请先充值", type: "warning" });
              return;
            }
            if (msg.indexOf("商品库存不足") != -1) {
              this.$message({ message: "商品库存不足", type: "warning" });
              return;
            }
            if (msg.indexOf("请联系管理员") != -1) {
              this.showSure = false;
              this.$message({
                message: "活动用户不支持购买饰品,请联系管理员",
                type: "warning",
              });
              return;
            }
            this.showSure = false;
            this.$message({ message: "请先登录", type: "warning" });
            this.$store.commit("getLogin", true);
          }
        });
    },
    //数据排序
    listSort() {
      this.priceSort = !this.priceSort;
      this.getList();
    },
    //筛选
    screen() {
      let param = {
        page: this.page,
        pageSize: this.pageSize,
        minPrice: this.input1,
        maxPrice: this.input2,
      };
      this.$axios
        .post("index/Store/skinList", this.$qs.stringify(param))
        .then((res) => {
          let data = res.data;
          if (data.status == 1) {
            this.totalSize = data.data.total;
            this.list = data.data.list;
            this.priceSort = true;
            for (let i = 0; i < this.list.length; i++) {
              this.list[i].state = false;
            }
            for (let i = 0; i < this.buyCart.length; i++) {
              for (let j = 0; j < this.list.length; j++) {
                if (this.buyCart[i].id == this.list[j].id) {
                  this.list[j].state = true;
                }
              }
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.active {
  color: #000 !important;
  background-color: #e9b10e !important;
}
.sort-block {
  background-color: #e9b10e;
  border-radius: 5px;
  padding: 5px 10px;
  color: #333;
  display: flex;
  font-size: 15px;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;

  user-select: none;
  .sort-icon {
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    .icon {
      color: #333;
      font-size: 10px !important;
    }
    .active-icon {
      color: #fff !important;
    }
  }
}

.oran {
  // overflow: auto;
  // height: 100%;
  background-color: #1a1c24;

  .more-btn {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  span {
    color: #848492;
    font-size: 15px;
    padding: 9px 22px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #333542;
  }
  span:hover {
    background-color: #3a3f50;
  }
}

  .myhomebot-mag {
    margin-top: 120px;
  }
  .oran-warp {
    height: 100%;
    padding: 16px;
    overflow: auto;
  }
  .oran-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    .orantop-left {
      color: #c3c3e2;
      font-size: 20px;
    }
    .orantop-right {
      padding: 12px 22px;
      background-color: #333542;
      border-radius: 5px;
      color: #848492;
      font-size: 15px;
      font-weight: 600;
    }
    .orantop-right:hover {
      background-color: #3a3f50;
    }
  }
  .oran-sel {
    // background: url("~@/assets/95/linebg.png") no-repeat;
    background-size: 100% 100%;
    border-top: 1px solid #e9b10e;
    margin: 0 auto;
    position: relative;
    .sel-top:after {
      content: "";
      clear: both;
      display: block;
      height: 0;
      visibility: hidden;
    }
    .sel-top {
      border-bottom: 1px solid #e9b10e;
      ul {
        margin: 0 -1px;
        li:before {
          position: absolute;
          width: 1px;
          height: 80px;
          background: #e9b10e;
          border-radius: 1px;
          opacity: 0.1;
          content: "";
        }
        li {
          float: left;
          width: 9.11%;
          display: block;
          .seltop-warp {
            margin: 0 1px;
            display: flex;
            flex-direction: column;
            align-items: center;

            img {
              width: 60%;
              height: auto;
              max-height: 78px;
            }
            span {
              padding-bottom: 10px;
              font-size: 12px;
              color: #878787;
            }
          }
          .seltop-warp1 {
            background-color: #2b2c37;
            span {
              color: #e9b10e;
            }
          }
        }
        li:hover {
        }
      }
    }
    .class-box {
      width: initial !important;
      background-color: #2b2c37;
      padding: 26px 16px;
    }
    .sel-bot {
      display: flex;
      flex-wrap: wrap;
      & > div {
        margin: 30px 0 10px 0px;
        &:last-child {
          display: flex;
          justify-content: flex-start;
        }
      }

      .selbot-left {
        flex: 1;
        min-width: 200px;
        min-height: 60px;
        .pz-container,
        .wg-container {
          display: flex;
          align-items: center;
          font-size: 14px;
          padding: 5px 0;
          .name {
            color: #fff;
            width: 45px;
          }
          .wg-content {
            color: #848492;
            flex: 1;
            white-space: nowrap;
            overflow: auto;
            span {
              padding: 0 10px;

              &:hover {
                color: #e9b10e;
              }
            }
            .active {
              color: #e9b10e;
            }
          }
        }
      }

      .selbot-right {
        // margin-left: 10px;
        flex: 1;

        display: flex;
        align-items: center;
        justify-content: space-between;

        .span {
          margin: 0 8px;
          color: #848492;
        }
        .pirec-btn {
          margin: 0 10px;

          background-color: #333542;
          border-radius: 5px;
          color: #848492;
          font-size: 15px;
          font-weight: 600;
          padding: 10px 26px;
        }
        .pirec-btn:hover {
          background-color: #3a3f50;
        }
        .input {
          width: 120px;
          img {
            width: auto;
            height: 18px;
          }
        }
        .input /deep/ .el-input__prefix,
        .input /deep/ .el-input__suffix {
          top: 11px;
        }
        .input1 {
          width: 200px;
        }
        .input1-i:hover {
        }
      }

      .selbot-right /deep/ .el-input__inner {
        background-color: #2b2c37;
        border: 1px solid #e9b10e;
        color: #c3c3e2;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .oran-box {
    padding: 10px;
  }
  .oran .oran-warp {
    background-color: transparent;
    margin-top: 15px;
  }
}
</style>
