<template>
  <div class="btn-list-wrapper">
    <div class="btn-list">
      <el-row>
        <el-col
          :xs="6"
          :sm="6"
          :md="4"
          :lg="3"
          :xl="3"
          v-for="item in btnList"
          :key="item[valueKey]"
        >
          <div class="btn-list__item">
            <div class="item__out-wrapper">
              <div class="item__out-wrapper__inner-wrapper">
                <div
                  class="item__out-wrapper__inner-wrapper__content"
                  :class="
                    item[valueKey] === value &&
                    'item__out-wrapper__inner-wrapper__content--active'
                  "
                  @click="onChange(item[valueKey], item)"
                >
                  {{ item[labelKey] }}
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "buttonGroup",
  props: {
    labelKey: {
      type: String,
      default: "label",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    value: {
      type: [String, Number],
      required: true,
    },
    btnList: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
};
</script>

<style lang='less' scoped>
.btn-list-wrapper {
  overflow: hidden;

  .btn-list {
    margin-right: -8px;

    .btn-list__item {
      .item__out-wrapper {
        margin-right: 8px;
        margin-bottom: 16px;

        .item__out-wrapper__inner-wrapper {
          position: relative;
          padding-bottom: 40%;

          .item__out-wrapper__inner-wrapper__content {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;

            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            text-align: center;
            font-family: "PingFang SC";
            font-size: 14px;
            font-style: normal;
            font-weight: 600;

            background-image: url("~@/assets/img/com-btn-bg--blue.png");
            color: var(--gray2, #4f7387);

            text-wrap: nowrap;

            &.item__out-wrapper__inner-wrapper__content--active {
              background-image: url("~@/assets/img/com-btn-bg--yellow.png");
              color: var(--gray4, #dde5e9);
            }
          }
        }
      }
    }
  }
}
</style>
