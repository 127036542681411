<template>
  <div class="steam-link-card">
    <div class="module-title">Steam交易链接</div>
    <div class="card__wrapper">
      <div class="card">
        <div class="card__link">
          <div class="card__link__item">
            <div class="card__link__item__title">输入您的交易链接</div>
            <div class="card__link__item__content">
              <div class="text-over-one content__input-wrapper">
                <input
                  v-model="url"
                  :disabled="!isEditLink && !isEditLinkLoading"
                  class="content__input-wrapper__input"
                  type="text"
                  ref="inputLink"
                />
              </div>
              <div
                class="card__link__item__content__copy-btn"
                @click="editSteamLink"
              >
                {{
                  isEditLinkLoading
                    ? "保存中"
                    : `${isEditLink ? "保存" : "编辑"}`
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="card__get-link-bar">
          <div class="card__get-link-bar__label">(1) 获取你的Steam交易链接</div>
          <div class="card__get-link-bar__value" @click="goUrl(url1)">点击这里</div>
        </div>
        <div class="card__get-link-bar">
          <div class="card__get-link-bar__label">(2) 打不开 Steam 怎么办?</div>
          <div class="card__get-link-bar__value" @click="goUrl(url2)">点击看教程</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "steamLinkCard",
  props: {
    userInfo: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      url: this.userInfo.tradeUrl,
      disabledEditSteamLink: "",
      isEditLink: false,
      isEditLinkLoading: false,
      url1: "https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url",
      url2: "https://www.bilibili.com/video/BV15D4y1X79w/",
    };
  },
  created() {},
  methods: {
    async editSteamLink() {
      try {
        this.isEditLink = !this.isEditLink;

        if (this.isEditLinkLoading) return;

        this.isEditLinkLoading = true;

        if (this.isEditLink) {
          console.info('this.$refs.inputLink', this.$refs.inputLink)

          this.$nextTick(() => {
            this.$refs.inputLink.focus();
          })
          return;
        }

        //console.log(this.url);
        let param = {
          tradeUrl: this.url,
        };
        const res = await this.$axios.post(
          "/index/User/bindSteamnew",
          this.$qs.stringify(param)
        );

        // console.log(res.data);
        var data = res.data;
        if (data.status == "1") {
          this.$message({
            showClose: true,
            message: data.msg,
            type: "success",
          });

          this.isEditLink = false;
        } else {
          throw data.msg;
        }
      } catch (error) {
        console.error('editSteamLink  error', error)
        this.$message({
          showClose: true,
          message: error,
          type: "error",
        });
      } finally {
        this.isEditLinkLoading = false;
      }
    },
    goUrl(url) {
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.steam-link-card {

  .card__wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
    width: calc(100% - 40px);
    height: 210px;
    background-image: url("~@/assets/img/my/link-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%;

    .card {
      display: flex;
      flex-direction: column;
      width: 100%;

      .card__link {
        // margin-top: 20px;
        margin-bottom: 25px;

        .card__link__item {
          margin-bottom: 20px;

          .card__link__item__title {
            margin-bottom: 20px;

            color: var(--gray4, #dde5e9);
            font-family: "PingFang SC";
            font-size: 14px;
            font-weight: 600;
          }
          .card__link__item__content {
            display: flex;
            align-items: center;

            .content__input-wrapper {
              flex: 1;
              overflow: hidden;

              border: 0.1px solid rgba(89, 155, 228, 0.6);
              background: rgba(13, 20, 26, 0.5);

              padding: 0 14px;

              .content__input-wrapper__input {
                width: 100%;

                line-height: 44px;

                background: none;
                border: none;
                outline: none;
                border-radius: 0;

                color: var(--gray2, #4f7387);
                font-family: "PingFang SC";
                font-size: 12px;
                font-weight: 400;
              }
            }
            .card__link__item__content__copy-btn {
              width: 80px;
              line-height: 40px;

              margin-left: 14px;

              background-image: url("~@/assets/img/common/btn-bg--yellow--80-40.png");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: cover;

              color: var(--black1, #0d141a);
              text-align: center;
              font-size: 14px;
              font-weight: 600;
            }
          }
        }
      }

      .card__get-link-bar {
        display: flex;
        align-items: center;
        margin: 10px 0;

        .card__get-link-bar__label {
          color: var(--gary3, #889aa7);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .card__get-link-bar__value {
          margin-left: 10px;

          color: var(--yellow, #f8c544);
          font-family: "PingFang SC";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
</style>
