<template>
  <div
    class="responsive-container lucky"
    v-loading="pageLoading"
    element-loading-background="rgba(0, 0, 0, 0)"
  >
    <div>
      <navheader
        :showNav="showNav"
        :yidongmenu="yidongmenu"
        :openHongbao="openHongbao"
      ></navheader>
      <div class="lucky-warp">
        <div class="category-list">
          <div
            class="category-list__item category-list__item--blue"
            @click="showGameplayIntroductionDialog"
          >
            玩法介绍
          </div>
          <div
            class="category-list__item category-list__item--blue"
            @click="goLuckyHistory"
          >
            对战记录
          </div>
          <div
            class="category-list__item category-list__item--yellow"
            @click="openDrawer"
          >
            创建对战
          </div>
        </div>
        <SwiperModule :boxList="boxList"></SwiperModule>

        <div class="introduce-module">
          每局活动，玩家连续打开相同盲盒，奖品总价最高的玩家可获得全部奖品
        </div>

        <div class="rank-title" @click="$router.push('/RankingList')">
          查看排行榜
        </div>
        <div class="room-count-label">
          {{ awaitRoomCount }} 个房间等待您加入
        </div>
        <div class="pk-card-list__wrapper">
          <div class="pk-card-list">
            <el-row>
              <el-col
                :xs="24"
                :sm="24"
                :md="12"
                :lg="12"
                :xl="12"
                v-for="(item, index) in list"
                :key="index"
              >
                <PkCard :pkDetails="item" @goLuckyRoom="goLuckyRoom"></PkCard>
              </el-col>
            </el-row>
          </div>
        </div>
        <div class="more-btn">
          <span @click="moveList()">{{
            loadMoreLoading ? "加载中" : "查看更多"
          }}</span>
        </div>

  
        <div class="clear"></div>
      </div>

      <!--  右侧创建房间盒子 -->
      <el-drawer
        :visible.sync="drawer"
        direction="rtl"
        size="300px"
        :append-to-body="true"
        class="sty"
      >
        <div class="sty-warp">
          <div class="sty-one">
            <div class="sty-one-hint">设置房间模式</div>
            <div class="btn-list-wrapper">
              <div class="btn-list">
                <div
                  class="btn-list__item"
                  v-for="(item, index) in patternList"
                  :key="index"
                >
                  <div class="item__out-wrapper">
                    <div class="item__out-wrapper__inner-wrapper">
                      <div
                        class="item__out-wrapper__inner-wrapper__content"
                        :class="
                          item.val === mode &&
                          'item__out-wrapper__inner-wrapper__content--active'
                        "
                        @click="selectPattern(item.name)"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="sty-one">
            <div class="sty-one-hint">选择模式</div>
            <div class="btn-list-wrapper">
              <div class="btn-list">
                <div
                  class="btn-list__item"
                  v-for="(item, index) in modeOptions"
                  :key="index"
                >
                  <div class="item__out-wrapper">
                    <div class="item__out-wrapper__inner-wrapper">
                      <div
                        class="item__out-wrapper__inner-wrapper__content"
                        :class="
                          item.value === moshi &&
                          'item__out-wrapper__inner-wrapper__content--active'
                        "
                        @click="qieRoomType(item.value)"
                      >
                        {{ item.label }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="sty-two">
            <div class="sty-two-hint1">单击选择盲盒</div>
            <div class="sty-two-hint2">你能选择最多6 件盲盒</div>
            <div class="sty-two-list">
              <ul>
                <li
                  v-for="(item, index) in boxList"
                  :key="index"
                  @click="addBox(index, item.price)"
                >
                  <div
                    class="twolist-warp"
                    :class="item.num > 0 && 'twolist-warp--active'"
                  >
                    <div class="twolist-top">
                      <img class="img-cover" :src="item.img_main" />
                    </div>
                    <div class="twolist-bot">
                      <img src="~@/assets/img/money.png" />
                      <span>{{ item.price }}</span>
                    </div>

                    <template v-if="item.num > 0">
                      <div class="twolist-warp__check-icon">
                        <img
                          class="img-cover"
                          src="@/assets/img/blindBox/check-icon.png"
                        />
                      </div>
                      <div class="twolist-warp__check-count">
                        x{{ item.num }}
                      </div>
                    </template>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="sty-three">
            <span class="sty-three-hint">
              <span
                >已选择: <strong>{{ currentBoxNum }}</strong
                >/6</span
              >
              <span
                >价值: <strong>{{ totalPrice }}</strong></span
              >
            </span>
            <div class="sty-three-list">
              <ul>
                <li
                  v-for="(item, index) in selectList"
                  :key="index"
                  @click="remBox(item.name, item.price)"
                >
                  <div class="threelist-warp">
                    <div v-if="item.state">
                      <div class="threelist-img">
                        <img class="img-cover" :src="item.img_main" />
                      </div>
                      <div class="threelist-name">
                        <img src="~@/assets/img/money.png" />
                        <span>{{ item.price }}</span>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="sty-three-btn1">
              <el-button
                :disabled="loading2"
                class="sty-three-btn"
                @click="createRoom()"
              >
                <i v-if="loading2" class="el-icon-loading"></i>创建活动
              </el-button>
            </div>
          </div>
        </div>
      </el-drawer>
    </div>
    <GameplayIntroductionDialog
      v-if="gameplayIntroductionDialogConfig"
      v-bind="gameplayIntroductionDialogConfig"
    ></GameplayIntroductionDialog>
    <!-- 玩法介绍 -->
    <div class="wfjs-box" v-if="wfjsShow">
      <!-- 标题 -->
      <div class="wfjs-title">
        玩法介绍

        <div class="wfjs-close" @click="wfjsShow = false">X</div>
      </div>
      <div class="wfjs-con" v-html="wfjsHtml"></div>
    </div>

    <myhomebot></myhomebot>
  </div>
</template>

<script>
import myhomebot from "@/components/my_homebot.vue";
import myinform from "@/components/my_inform.vue";
import SockJS from "sockjs-client";
import navheader from "@/components/navheader.vue";
import Stomp from "stompjs";
import Utils from "@/assets/js/util.js";

import { battle } from "@/api/socket.js";
import SwiperModule from "./components/swiperModule/index";
import PkCard from "./components/pkCard/index";
import GameplayIntroductionDialog from "./components/gameplayIntroductionDialog/index";

const modeOptions = [
  {
    label: "欧皇模式",
    value: 1,
  },
  {
    label: "非酋模式",
    value: 2,
  },
];

export default {
  components: {
    myhomebot,
    myinform,
    navheader,
    SwiperModule,
    PkCard,
    GameplayIntroductionDialog,
  },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      loading2: false,
      loading1: false,
      sockBattleData: "",
      loading: true,
      websock: "",
      timer: "",
      currentBoxNum: 0,
      currentBoxTotalPrice: 0,
      totalPrice: 0,
      mode: "2",
      page: 1,
      pageSize: 20,
      boxInfo: [],
      list: [],
      star: {},
      list1: [],
      boxList: [],
      boxList2: [],
      selectList: [
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
        { state: false, name: "" },
      ],
      drawer: false, //右侧导航状态
      patternList: [
        //选择模式
        { name: "双人模式", state: true, val: 2 },
        { name: "三人模式", state: false, val: 3 },
        { name: "四人模式", state: false, val: 4 },
      ],
      peopleObj: {
        img: require("@/assets/img/jiapeople.png"),
        class: "pk-false",
        border: "1px dashed #e9b10e",
      },
      img: require("@/assets/img/15mdpi.png"),

      is_mobile: false,
      // 房间模式
      moshi: 1,
      // 玩法介绍
      wfjsShow: false,
      gameplayIntroductionDialogConfig: null,
      modeOptions,
      loadMoreLoading: false,
      pageLoading: false,
    };
  },
  watch: {
    currentBoxTotalPrice(val) {
      let _this = this;
      _this.totalPrice = val.toFixed(2);
    },
  },

  methods: {
    showGameplayIntroductionDialog() {
      console.info("1111");

      this.gameplayIntroductionDialogConfig = {
        onClosed: () => (this.gameplayIntroductionDialogConfig = null),
      };
    },
    // 切换房间模式
    qieRoomType(type) {
      this.moshi = type;
    },
    isMobile() {
      let flag =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      return flag;
    },
    //计算分类 根据盒子计算同一类的数量
    caculateBoxNum(box) {
      const result = box.reduce((total, current) => {
        if (total[current.box_id]) {
          total[current.box_id].num += 1;
        } else {
          total[current.box_id] = {
            num: 1,
            current,
          };
        }
        return total;
      }, {});
      return Object.keys(result).map((box_id) => result[box_id]);
    },

    //推送
    initWebSocket() {
      const wsuri = battle();
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
    },

    websocketonopen() {
      //let actions = { test: "12345" };
      //this.websocketsend(JSON.stringify(actions));
    },

    websocketonerror() {
      this.initWebSocket();
    },

    websocketonmessage(d) {
      // console.log("接收:",JSON.parse(d.data));
      // console.log(JSON.parse(d.data));
      this.sockBattleList(d.data);
    },

    websocketsend(Data) {
      if (this.websock.readyState === WebSocket.OPEN) {
        this.websock.send(Data);
      }
    },

    websocketclose(e) {
      // console.log("close:", e);
    },

    updateBattleList(item) {
      this.list.forEach((ele, index) => {
        if (ele.id == item.id) {
          this.list[index].class = "";
          this.$set(this.list, index, item);
          // console.log("更新：", this.list);
        }
      });
    },

    addBattleList(item) {
      if (this.list.length == 0) {
        this.list.push(item);
        return;
      }
      let insert = false;
      for (let i = 0; i < this.list.length; i++) {
        if (this.list[i].status == 3 && this.list.length >= 12) {
          item.class = "replace-room";
          this.$set(this.list, i, item);
          insert = true;
          break;
        }
      }
      let e = this.list.filter((ele, index, arr) => {
        return ele.id == item.id;
      });
      if (!insert && e.length == 0) {
        this.list.push(item);
      }
    },

    sockBattleList(d) {
      let data = JSON.parse(d);
      console.log(data);

      if (data.info == undefined) {
        return;
      }
      if (data.state == "update") {
        this.updateBattleList(data.info);
      } else if (data.state == "add") {
        this.addBattleList(data.info);
      }

      //几个人参与
      for (let i = 0; i < this.list.length; i++) {
        let play = JSON.parse(JSON.stringify(this.list[i].player_info));
        let mode = this.list[i].mode;

        for (let j = 0; j < mode - this.list[i].player_info.length; j++) {
          play.push(this.peopleObj);
        }

        this.list[i].player_info = play;
      }
      //排序
    },

    touchstart(event) {
      // console.log("touch");
      var style = document.createElement("style");
      style.innerHTML = `.ripple::after{top:${event.offsetY - 25}px;left:${
        event.offsetX - 180
      }px;}`;
      document.head.appendChild(style);
    },

    //查看更多
    moveList() {
      // console.log(this.pageSize);
      if (this.loadMoreLoading) return;

      let _this = this;
      let param = {
        page: this.page +=1,
        pageSize: this.pageSize,
      };

      this.loadMoreLoading = true;
      _this.$axios
        .post("/index/Battle/battleList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            // this.pageSize = this.pageSize + 12;
            const newList = data.data.battleList;

            this.list = this.list.concat(newList);
          }
        })
        .finally(() => {
          this.loadMoreLoading = false;
        });
    },
    //对战列表
    async getList() {
      let _this = this;
      let param = {
        page: this.page,
        pageSize: this.pageSize,
      };
      await _this.$axios
        .post("/index/Battle/battleList", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data != null) {
            this.list = data.data.battleList;
          }
        });
    },
    //盲盒选择列表
    async getBattleBoxList() {
      let _this = this;
      await _this.$axios.post("/index/Battle/battleBoxList").then((res) => {
        res.data.data.forEach((element) => {
          element.num = 0;
        });
        _this.boxList = res.data.data;
        _this.loading = true;

        let arr = [];
        for (let i = 0; i < this.boxList.length / 10; i++) {
          this.boxList2.push(arr);
        }
        for (let i = 0; i < this.boxList2.length; i++) {
          this.boxList2[i] = this.boxList.slice(i * 10, (i + 1) * 10);
        }
      });
    },
    //创建活动
    createRoom() {
      if (!this.$store.state.token) {
        this.$store.commit("getLogin", true);
        return;
      }

      let _this = this;
      _this.selectList.forEach((e) => {
        if (e.state) {
          e.num = 1;
          _this.boxInfo.push(e);
        }
      });
      if (_this.boxInfo.length == 0) {
        _this.$message({
          message: "请选择箱子",
          type: "warning",
        });
        return;
      }
      this.loading2 = true;
      // console.log(_this.boxInfo);
      let param = {
        mode: _this.mode,

        boxInfo: _this.boxInfo,
        roomtype: _this.moshi,
      };
      _this.$axios
        .post("/index/Battle/createRoom", _this.$qs.stringify(param))
        .then((res) => {
          // console.log(res);
          let data = res.data;
          // console.log(data);
          if (data.status == 1) {
            this.loading2 = false;
            this.$store.commit("getMoney", data.data.total_amount);
            Utils.$emit("money", data.data.total_amount);
            // _this.$bus.$emit("loading", true);

            //发送
            /* let sendData = {
               state: "add",
               battle_id: data.data.battle_id,
             };
             _this.websocketsend(JSON.stringify(sendData));*/

            _this.$router.push({
              path: `/LuckyRoom`,
              query: {
                id: data.data.battle_id,
              },
            });
          } else {
            //创建失败
            _this.$message({
              message: data.msg,
              type: "warning",
            });
            _this.boxInfo = [];
            let selectListCopy = [
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
              { state: false, name: "" },
            ];
            _this.selectList = selectListCopy;
            _this.currentBoxNum = 0;
            _this.totalPrice = 0;
            // console.log(this.boxList);
            for (let i = 0; i < this.boxList.length; i++) {
              this.boxList[i].num = 0;
            }
          }
        });
    },
    //点击挑战房间
    goLuckyRoom(id) {
      for (let i = 0; i < this.list.length; i++) {
        if (id == this.list[i].id) {
          if (this.list[i].status == 1) {
            if (!this.$store.state.token) {
              this.$store.commit("getLogin", true);
            } else {
              this.$router.push({
                path: `/LuckyRoom`,
                query: {
                  id: id,
                },
              });
            }
          } else {
            this.$router.push({
              path: `/LuckyRoom`,
              query: {
                id: id,
              },
            });
          }
        }
      }
    },
    //跳转至活动规则
    goLuckyRule() {
      this.$router.push({
        path: `/LuckyRule`,
      });
    },
    //跳转至活动历史
    goLuckyHistory() {
      this.$router.push({
        path: `/LuckyHistory`,
      });
    },
    //右侧创建房间
    openDrawer() {
      this.drawer = true;
    },
    //选择几人模式
    selectPattern(name) {
      for (let i = 0; i < this.patternList.length; i++) {
        if (this.patternList[i].name == name) {
          this.patternList[i].state = true;
          this.mode = this.patternList[i].val;
        } else {
          this.patternList[i].state = false;
        }
      }
      // console.log(this.mode);
    },
    //选择盒子
    addBox(index, price) {
      if (this.currentBoxNum < 6) {
        this.currentBoxNum++;
        this.currentBoxTotalPrice = this.currentBoxTotalPrice + Number(price);
      }
      var boxnum = 0;
      for (let i = 0; i < this.boxList.length; i++) {
        boxnum += this.boxList[i].num;
      }
      if (boxnum < 6) {
        this.boxList[index].num += 1;
        //console.log(this.selectList)
        for (let i = 0; i < this.selectList.length; i++) {
          if (this.selectList[i].state == false) {
            this.selectList[i].state = true;
            this.selectList[i].box_id = this.boxList[index].id;
            this.selectList[i].name = this.boxList[index].name;
            this.selectList[i].price = this.boxList[index].price;
            this.selectList[i].img_main = this.boxList[index].img_main;
            break;
          }
        }
      } else {
        this.$notify({
          title: "提示",
          message: "盲盒数量已达上限",
        });
      }
      // console.log(this.selectList);
    },
    //去掉盒子
    remBox(name, price) {
      if (name) {
        if (this.currentBoxNum > 0) {
          this.currentBoxNum--;
          this.currentBoxTotalPrice = this.currentBoxTotalPrice - Number(price);
        }
      }
      for (let i = 0; i < this.boxList.length; i++) {
        if (name == this.boxList[i].name) {
          this.boxList[i].num--;
        }
      }
      for (let i = 0; i < this.selectList.length; i++) {
        if (this.selectList[i].name == name) {
          this.selectList[i].name = "";
          this.selectList[i].state = false;
          break;
        }
      }
      this.selectList.sort((a, b) => b.state - a.state);
    },
  },
  computed: {
    awaitRoomCount() {
      return this.list.filter((item) => item.status === 1).length;
    },
  },
  async mounted() {
    try {
      this.pageLoading = true;

      let _this = this;

      await _this.getBattleBoxList();
      await _this.getList();

      this.$bus.$emit("loading", false);

      Utils.$on("update", function (data) {
        _this.sockBattleList(data);
      });
    } catch (error) {
      throw error;
    } finally {
      this.pageLoading = false;
    }
  },
  beforeDestroy: function () {},
  created() {
    this.initWebSocket();
    this.is_mobile = this.isMobile();
    window.addEventListener("resize", () => {
      this.is_mobile = this.isMobile();
    });
  },
  destroyed() {
    //clearInterval(this.timer);
    this.websock.close();
  },
};
</script>

<style lang="less" scoped>
::v-deep {
  .el-drawer__body {
    padding: 10px 0px 10px 15px;
    background-color: #141d26;
    overflow-y: auto;
  }
  .el-drawer__header {
    background-color: #1a1a24;
    margin-bottom: 0;
    padding-bottom: 20px;
  }
}
// 玩法介绍
.wfjs-box {
  width: 80%;
  height: 80%;
  position: fixed;
  left: 10%;
  top: 10%;
  background-color: #3e3f47;
  z-index: 999;
  overflow-y: auto;
  // 标题
  .wfjs-title {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #ffffff;
    justify-items: center;
    .wfjs-close {
      margin-left: auto;
      margin-right: 20px;
      font-size: 26px;
    }
  }
  .wfjs-con {
    width: 100%;
    height: auto;
  }
}
.replace-room {
  transform-style: preserve-3d;
  backface-visibility: hidden;
  -webkit-animation-name: flip;
  animation-name: flip;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
@keyframes flip {
  0% {
    transform: scale(0.75);
  }

  50% {
    transform: scale(0.95);
  }
  100% {
    transform: scale(1);
  }
}

.lucky {
  background-color: #1a1a24;
  overflow: hidden;

  .lucky-warp {
    height: 100%;
    padding: 16px;

    .category-list {
      display: flex;
      align-items: center;
      justify-content: center;

      .category-list__item {
        width: 100px;
        height: 40px;
        line-height: 40px;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;

        text-align: center;
        text-align: center;
        font-family: "PingFang SC";
        font-size: 14px;
        font-style: normal;
        font-weight: 600;

        margin: 0 6px;

        &.category-list__item--blue {
          color: #dde5e9;
          background-image: url("~@/assets/img/blindBox/Group92326.png");
        }
        &.category-list__item--yellow {
          color: #0d141a;
          background-image: url("~@/assets/img/blindBox/Group92335.png");
        }
      }
    }

    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        color: #c3c3e2;
        font-size: 24px;
      }
      .right {
        display: flex;
        align-items: center;

        .right-span {
          padding: 9px 22px;
          border-radius: 5px;
          font-size: 15px;

          font-weight: 600;
        }
        .right-span1 {
          margin-right: 10px;
          background-color: #333542;
          color: #848492;
        }
        .right-span2 {
          color: #1a1c42;
          background-color: #e9b10e;
        }
        .right-span2:hover {
          background-color: #f5c432;
        }
      }
    }

    .luc-list {
      height: 150px;
      margin-top: 20px;
      ul {
        height: 100%;
        border-radius: 5px;

        li {
          height: 100%;
          float: left;
          width: 10%;
          .list-warp {
            height: 100%;
            padding-top: 10px;
            border-right: 1px solid #1a1c24;
            .list-img {
              text-align: center;
              img {
                // height: 80px;
                width: 100px;
                height: 75px;
              }
            }
            .list-money {
              display: flex;
              align-items: center;
              justify-content: center;
              img {
                height: 15px;
                width: auto;
                margin-right: 5px;
              }
              span {
                color: #e9b10e;
              }
            }
            .list-name {
              text-align: center;
              margin-top: 5px;
              color: #c3c3e2;
              padding-bottom: 10px;
            }
          }
        }
        li:hover {
          background-color: #30313f;
        }
      }
    }

    .introduce-module {
      background: rgba(13, 20, 26, 0.5);

      padding: 12px 16px;
      color: var(--gary3, #889aa7);
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .rank-title {
      background-image: url("~@/assets/img/blindBox/rank-title-bg.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      width: 148px;
      height: 44px;
      line-height: 44px;
      text-align: center;

      color: var(--black1, #0d141a);
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;

      margin: 20px auto;
    }

    .room-count-label {
      color: var(--gray2, #4f7387);
      text-align: right;
      font-family: "PingFang SC";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .pk-card-list__wrapper {
      overflow: hidden;
      margin-top: 20px;

      .pk-card-list {
        margin-right: -10px;
      }
    }

    .luc-list /deep/ .el-carousel__indicators--outside {
      display: none;
    }

    /* .luc-list::-webkit-scrollbar {
     
      width: 2000px; 
      height: 10px;
    }
    .luc-list::-webkit-scrollbar-thumb {
     
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: #535353;
    }
    .luc-list::-webkit-scrollbar-track {
     
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      background: #ededed;
    } */

  
  }
}
.sty {
  .sty-one {
    margin: 16px;
    .sty-one-hint {
      font-size: 14px;
      padding-bottom: 5px;
      color: #dde5e9;
    }

    .btn-list-wrapper {
      overflow: hidden;

      .btn-list {
        display: flex;
        align-items: center;
        margin-right: -10px;

        .btn-list__item {
          width: 33%;
          .item__out-wrapper {
            margin-right: 10px;

            .item__out-wrapper__inner-wrapper {
              position: relative;
              padding-bottom: 44.44%;

              .item__out-wrapper__inner-wrapper__content {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                background-repeat: no-repeat;
                background-position: center center;
                background-size: cover;

                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                text-align: center;
                font-family: "PingFang SC";
                font-size: 14px;
                font-style: normal;
                font-weight: 600;

                background-image: url("~@/assets/img/blindBox/choose-btn-bg--blue.png");
                color: var(--gray2, #4f7387);

                &.item__out-wrapper__inner-wrapper__content--active {
                  background-image: url("~@/assets/img/blindBox/choose-btn-bg--yellow.png");
                  color: var(--gray4, #dde5e9);
                }
              }
            }
          }
        }
      }

      span {
        width: 33.33%;
        text-align: center;
        background-color: #e9b10e;
        font-weight: 400;
        padding: 10px 0;
        font-size: 15px;
      }
      :first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      :last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
      :hover {
      }
      .span1 {
        background-color: #1f1f26;
        color: #848492;
      }
    }
  }
  .sty-two {
    margin: 16px;
    .sty-two-hint1 {
      font-size: 14px;
      color: #dde5e9;
    }
    .sty-two-hint2 {
      font-size: 12px;
      padding-bottom: 5px;
      color: #889aa7;
    }
    .sty-two-list {
      ul {
        margin: 0 -2px;
        margin-right: 5px;
        display: flex;
        flex-flow: row wrap;
        li {
          margin-top: 5px;
          width: 33.33%;
          .twolist-warp {
            position: relative;

            height: 100%;

            border: 0.5px solid rgba(89, 155, 228, 0.6);
            background: rgba(89, 155, 228, 0.05);
            box-shadow: 0px 0px 13.276px 0px rgba(89, 155, 228, 0.3) inset;

            margin: 2px;
            position: relative;

            overflow: hidden;

            &.twolist-warp--active {
              border: 0.5px solid rgba(248, 197, 68, 0.59);
              background: rgba(248, 197, 68, 0.1);
              box-shadow: 0px 0px 13.276px 0px rgba(248, 197, 68, 0.46) inset;
            }

            .twolist-warp__check-icon {
              position: absolute;
              top: 0;
              right: 0;

              width: 24px;
              height: 24px;
            }

            .twolist-warp__check-count {
              position: absolute;
              top: 4px;
              left: 4px;

              color: #f8c544;
              font-size: 12px;
            }

            .twolist-top {
              width: 64px;
              height: 49px;
              margin: 15px auto 5px;
            }
            .twolist-bot {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: auto;
                height: 15px;
              }
              span {
                margin-left: 5px;

                color: var(--gray4, #dde5e9);
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
            .twolist-num1 {
              display: none;
            }
          }
        }
        :hover {
        }
      }
    }
    /*滚动条样式*/
    .sty-two-list::-webkit-scrollbar {
      width: 4px;
      /*height: 4px;*/
    }
    .sty-two-list::-webkit-scrollbar-thumb {
      border-radius: 10px;
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      background: rgba(0, 0, 0, 0.2);
    }
    .sty-two-list::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .sty-three {
    padding: 16px;
    .sty-three-hint {
      display: flex;
      justify-content: space-between;
      align-items: center;

      background-color: #0d141a;

      padding: 10px;
      margin-bottom: 10px;

      span {
        color: #889aa7;
        font-size: 14px;

        strong {
          color: #f8c544;
          font-weight: 200;
        }
      }
    }
    .sty-three-list {
      ul {
        margin: 0 -2px;
        display: flex;
        flex-flow: row wrap;
        li {
          width: 33.33%;
          .threelist-warp {
            height: 100px;
            margin: 2px;

            border: 0.5px solid rgba(89, 155, 228, 0.6);
            background: rgba(89, 155, 228, 0.05);
            box-shadow: 0px 0px 13.276px 0px rgba(89, 155, 228, 0.3) inset;

            .threelist-img {
              width: 64px;
              height: 49px;
              margin: 15px auto 5px;
            }
            .threelist-name {
              display: flex;
              justify-content: center;
              align-items: center;
              img {
                width: auto;
                height: 15px;
              }
              span {
                margin-left: 5px;
                color: #e9b10e;
              }
            }
          }
        }
        :hover {
        }
      }
    }
    .sty-three-btn1 {
      display: flex;
      justify-content: center;
    }
    .sty-three-btn {
      text-align: center;
      padding: 10px 25px;
      border-radius: 5px;
      background-color: #e9b10e;
      font-size: 14px;
      margin-top: 30px;
      color: #1a1c24;
      font-weight: 600;
      border-color: #e9b10e;
    }
    .sty-three-btn:hover {
      background-color: #f5c432;
    }
  }
}

.more-btn {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  span {
    color: #848492;
    font-size: 15px;
    padding: 9px 22px;
    border-radius: 5px;
    font-weight: 600;
    background-color: #333542;
  }
  span:hover {
    background-color: #3a3f50;
  }
}

.sty {
  /deep/ .el-drawer__wrapper {
    top: 60px;
  }
  /deep/ .el-drawer__header {
    display: none;
  }
  /deep/ .el-drawer__body {
    background-color: #141d26;
  }
}

.ripple {
  position: relative;
  //隐藏溢出的径向渐变背景
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.ripple:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
  //设置径向渐变
  background-image: radial-gradient(
    circle,
    rgb(163, 162, 162) 10%,
    transparent 10.01%
  );
  background-repeat: no-repeat;
  background-position: 50%;
  transform: scale(20, 20);
  opacity: 0;
  transition: transform 0.3s, opacity 0.5s;
}

.ripple:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  //设置初始状态
  transition: 0s;
}

.lucky /deep/ .el-drawer__open .el-drawer.rtl {
  overflow-y: auto;
}
</style>
