<template>
  <div class="responsive-container spread">
    <navheader
      :showNav="showNav"
      :yidongmenu="yidongmenu"
      :openHongbao="openHongbao"
    ></navheader>
    <div class="spread-warp">
      <div class="roomleft-num">取回记录</div>

      <div class="hist-list">
        <el-tabs type="border-card" @tab-click="onChange" v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.8)">
          <el-tab-pane label="取回记录">
            <div class="bot">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="order_time" label="日期">
                </el-table-column>
                <el-table-column prop="plat_order" label="订单号">
                </el-table-column>
                <el-table-column prop="mobile" label="用户"> </el-table-column>
                <el-table-column prop="name" label="装备名称">
                </el-table-column>
                <el-table-column prop="price" label="饰品价值">
                </el-table-column>
                <el-table-column prop="state" label="取回结果">
                </el-table-column>
                <el-table-column prop="receive_time" label="收货时间">
                </el-table-column>
              </el-table>
            </div>
            
          </el-tab-pane>

          <el-tab-pane label="系统通知">
            <div class="bot">
              <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="title" label="通知类型">
                </el-table-column>
                <el-table-column prop="content" label="内容">
                </el-table-column>
                <el-table-column prop="create_time" label="时间"> </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="page_row" v-if="tableData.length > 0">
        <el-pagination
              background
              layout="prev, pager, next"
              :pager-count="5"
              @size-change="sizeChange"
              @current-change="currentChange"
              :page-size="Pager.pageSize"
              :current-page="Pager.page"
              :total="Pager.totalSize"
            ></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { parse } from "qs";
import navheader from "@/components/navheader.vue";
export default {
  components: { navheader },
  props: ["yidongmenu", "showNav", "openHongbao"],
  data() {
    return {
      totalSize: 0,
      page: 1,
      pageSize: 10,
      tableData: [],
      totalSize: 0,
      pageSize: 10,
      tableData1: [],
      Pager: {
        page: 1,
        pageSize: 10,
        totalSize: 0,
      },
      loading: false,
      activeInde:0,
    };
  },
  mounted() {
    this.getskinToSteam();
  },
  methods: {

    getNoticeLists(){

      let param = {
        page: this.Pager.page,
        pageSize: this.Pager.pageSize,
      };
      this.loading = true;
      this.tableData = [];
      this.$axios.post("/index/user/noticeLists",this.$qs.stringify(param)).then((res)=>{
        // console.log(res);
        this.loading = false;
        var data = res.data;
        if (data.status == "1" && data.data) {
          this.Pager.totalSize = data.data.total;
          this.tableData = data.data.list;
        }

      })
    },
    onChange(event){
      this.Pager.page = 1;

      this.activeInde = event.index;
      if(event.index == 0) {
        this.getskinToSteam();
      }else{
        this.getNoticeLists();
      }
    },
    sizeChange(size){
      this.Pager.pageSize = size;
    },
    currentChange(page){
      this.Pager.page = page;
      if(this.activeInde == 0) {
        this.getskinToSteam();
      }else{
        this.getNoticeLists();
      }
    },
    getskinToSteam() {
      let param = {
        page: this.Pager.page,
        pageSize: this.Pager.pageSize,
      };
      this.loading = true;
      this.tableData = [];
      this.$axios
        .post("/index/Lucky/getskinToSteam", this.$qs.stringify(param))
        .then((res) => {
          this.loading = false;
          //  console.log(res.data);
          var data = res.data;
          if (data.status == "1" && data.data) {
            this.Pager.totalSize = data.data.total;
            this.tableData = data.data.list;
          }
        });
    },
    //分页 所有记录
    // currentChange(val) {
    //   this.getskinToSteam(val);
    // },

    goLucky() {
      this.$router.push({
        path: `/Ornament`,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.spread {
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #1a1c24;

  .spread-warp {
    padding: 16px;
  }
  .roomleft-num {
    color: #c3c3e2;
    font-size: 20px;
  }

  //表格
  .hist-list /deep/ .el-table th,
  .hist-list /deep/ .el-table tr {
    background-color: #2b2c37;
  }
  .hist-list /deep/ .el-table td,
  .hist-list /deep/ .el-table th.is-leaf {
    border-bottom: 1px solid #444659;
  }
  .hist-list /deep/ .el-table::before {
    height: 0;
  }
  .hist-list /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: #212e3e !important;
  }
  /*.list /deep/ .cell {
    padding: 0;
    height: 60px;
    line-height: 60px;
  }*/
  .hist-list /deep/ .el-table__empty-block {
    background-color: #2b2c37;
  }
  .page_row{
      display: flex;
      width: 100%;
      padding: 20px 0px;
      justify-content: center;
    }
  //tabs
  //tabs
  .hist-list {
    margin-top: 20px;

    
  }
  .hist-list /deep/ .el-tabs--border-card {
    //overflow: hidden;
    background-color: #2b2c37;
    border: none;
    // border-radius: 5px;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header {
    border: none;
    background-color: #1a1c24;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    background-color: #2b2c37;
    border: none;
  }
  .hist-list /deep/ .el-tabs--border-card > .el-tabs__header .el-tabs__item {
    background-color: #24252f;
    border: none;
  }
  .hist-list
    /deep/
    .el-tabs--border-card
    > .el-tabs__header
    .el-tabs__item.is-active {
    color: #e9b10e;
  }
}
.payinfoBox {
  /deep/ .el-dialog__title {
    color: #808085;
  }
  /deep/ h3 {
    color: #eaeaea;
    //font-weight: normal;
    margin-bottom: 16px;
    font-size: 12px;
  }
  /deep/ .el-checkbox {
    color: #17b4ed;
  }
  /deep/ .btn-area {
    text-align: center;
    padding: 16px 0 8px 0;
  }
}
</style>