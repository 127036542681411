<template>
  <div class="pk-card__wrapper__out-wrapper">
    <div class="pk-card__wrapper" @click="$emit('goLuckyRoom', pkDetails.id)">
      <div class="pk-card">
        <div class="pk-card__title">
          {{ currentRoomTypeConfig ? currentRoomTypeConfig.label : "" }}
        </div>
        <div class="pk-card__content">
          <div class="pk-card__content__header-bar">
            <div class="header-bar__status">
              <div
                class="header-bar__status__pre-icon"
                :style="{ backgroundColor: pkSchedule.color }"
              ></div>
              <div class="header-bar__status__label">
                {{ pkSchedule.label }}
              </div>
            </div>
            <div class="header-bar__round-label">
              {{ `${pkDetails.boxInfo.length} 回合` }}
            </div>
          </div>
          <div class="body">
            <div class="body__left">
              <div class="body__left__bg-img">
                <img
                  class="img-cover"
                  src="~@/assets/img/blindBox/pk--left.png"
                  alt=""
                />
              </div>
              <div class="body__left__play-list">
                <div
                  v-for="(player, playerIndex) in playersSorted.leftPlayerList"
                  :key="playerIndex"
                  class="play-list__item__header-img"
                  :class="{
                    'play-list__item__header-img--widthout-border':
                      player.$isPlaceholderPlayer,
                  }"
                >
                  <img class="img-cover" :src="player.img" alt="" />
                </div>
              </div>
            </div>
            <div class="body__center">
              <img
                class="img-cover"
                src="~@/assets/img/blindBox/pk-icon.png"
                alt=""
              />
              <div
                v-if="playersSorted.centerPlayer"
                class="body__center__player"
              >
                <img
                  class="img-cover"
                  :src="playersSorted.centerPlayer.img"
                  alt=""
                />
              </div>
            </div>
            <div class="body__right">
              <div class="body__right__bg-img">
                <img
                  class="img-cover"
                  src="~@/assets/img/blindBox/pk--right.png"
                  alt=""
                />
              </div>
              <div class="body__right__play-list">
                <div
                  v-for="(player, playerIndex) in playersSorted.rightPlayerList"
                  :key="playerIndex"
                  class="play-list__item__header-img"
                  :class="{
                    'play-list__item__header-img--widthout-border':
                      player.$isPlaceholderPlayer,
                  }"
                >
                  <img class="img-cover" :src="player.img" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="pk-card__content__footer-bar">
            <div class="pk-card__content__footer-bar__left">
              <div
                v-for="(box, boxIndex) in caculateBoxNum"
                :key="boxIndex"
                class="box"
              >
                <img class="img-cover" :src="box.current.img_main" alt="" />
                <div class="box__count">{{ box.num }}</div>
              </div>
            </div>
            <div class="pk-card__content__footer-bar__right">
              <div class="pk-card__content__footer-bar__right__money-icon">
                <img
                  class="img-cover"
                  src="~@/assets/img/home/jinbi.png"
                  alt=""
                />
              </div>
              <div class="pk-card__content__footer-bar__right__price">
                {{ roomPrice }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import currency from "currency.js";
import defaultPlayerHeaderImg from "@/assets/img/blindBox/member.png";

const scheduleOptions = [
  {
    label: "等待中",
    color: "#e9b10e",
  },
  {
    label: "进行中",
    color: "#67c23a",
  },
  {
    label: "已结束",
    color: "#999",
  },
];

const roomTypeOptions = [
  {
    label: "欧皇",
    value: 1,
  },
  {
    label: "非酋",
    value: 2,
  },
];

const PK_MODES = {
  model1: 2, // 双人对战
  model2: 3, // 3人对战
  model3: 4, // 4人对战
};

export default {
  name: "pkCard",
  props: {
    pkDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
  computed: {
    pkSchedule() {
      console.info("this.pkDetails", this.pkDetails);
      return scheduleOptions[this.pkDetails.status - 1];
    },
    playersSorted() {
      const totalPlayerCount = this.pkDetails.mode;
      const joinedPlayerList = this.pkDetails.player_info;

      // 先补全玩家数量
      const totalPlayerList = new Array(totalPlayerCount)
        .fill(",")
        .map((item, index) => {
          const joinedPlayer = joinedPlayerList[index];

          if (joinedPlayer) return joinedPlayer;

          return {
            img: defaultPlayerHeaderImg,
            $isPlaceholderPlayer: true,
          };
        });

      // 是否是3个玩家
      const isThreePlayerJoin = totalPlayerCount === PK_MODES.model2;

      // 是三个玩家，就把第二个放在中间的玩家位置
      const centerPlayer = isThreePlayerJoin
        ? totalPlayerList.splice(1, 1)[0]
        : null;
      const leftPlayerList = totalPlayerList.splice(
        0,
        totalPlayerList.length / 2
      );

      return {
        centerPlayer,
        leftPlayerList,
        rightPlayerList: totalPlayerList,
      };
    },
    caculateBoxNum() {
      const result = this.pkDetails.boxInfo.reduce((total, current) => {
        if (total[current.box_id]) {
          total[current.box_id].num += 1;
        } else {
          total[current.box_id] = {
            num: 1,
            current,
          };
        }
        return total;
      }, {});
      return Object.keys(result).map((box_id) => result[box_id]);
    },
    currentRoomTypeConfig() {
      const currentRoomTypeConfig = roomTypeOptions.find(
        (item) => item.value === this.pkDetails.roomtype
      );

      return currentRoomTypeConfig;
    },
    roomPrice() {
      const boxInfo = this.pkDetails.boxInfo;

      const { price } =
        boxInfo.length > 0
          ? boxInfo.reduce(
              (a, b) => {
                return {
                  price: a.price.add(b.price),
                };
              },
              {
                price: currency(0),
              }
            )
          : {
              price: currency(0),
            };

      return price;
    },
  },
};
</script>

<style lang='less' scoped>
.pk-card__wrapper__out-wrapper {
  margin-bottom: 10px;
  margin-right: 10px;

  .pk-card__wrapper {
    position: relative;
    padding-bottom: 200/343 * 100%;
    overflow: hidden;

    .pk-card {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;

      background-image: url("~@/assets/img/blindBox/item-bg.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      display: flex;
      flex-direction: column;

      .pk-card__title {
        display: flex;
        align-items: center;
        justify-content: center;

        height: 15%;
        text-align: center;

        color: var(--white, #fff);
        font-family: YouSheBiaoTiHei;
        font-size: 19px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .pk-card__content {
        flex: 1;
        display: flex;
        flex-direction: column;

        padding: 16px;

        .pk-card__content__header-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .header-bar__status {
            display: flex;
            align-items: center;

            .header-bar__status__pre-icon {
              // 这里添加预图标样式
              width: 8px;
              height: 8px;
              background-color: #f8c544;
              border-radius: 50%;
              margin-right: 4px;
            }
            .header-bar__status__label {
              color: var(--yellow, #f8c544);
              text-align: center;
              font-family: "PingFang SC";
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }
          }
          .header-bar__round-label {
            color: var(--gray4, #dde5e9);
            text-align: center;
            font-family: "PingFang SC";
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .body {
          flex: 1;

          display: flex;
          align-items: center;
          justify-content: center;
          .body__left {
            flex: 1;
            flex-shrink: 0;

            position: relative;
            z-index: 1;

            display: flex;
            align-items: center;
            justify-content: flex-end;

            @keyframes slideleft {
              0% {
                transform: translateX(-50%);
              }
              100% {
                transform: translateX(-20%);
              }
            }
            .body__left__bg-img {
              position: absolute;
              z-index: -1;
              top: -10px;
              animation: slideleft 1s infinite;
              width: 114px;
              height: 37px;
            }

            .body__left__play-list {
              display: flex;
              align-items: center;
              @keyframes scale {
                0%, 100% {
                  transform: scale(1);
                }
                50% {
                  transform: scale(1.3);
                }
              }
              .play-list__item__header-img {
                margin-left: 6px;

                width: 40px;
                height: 40px;
                flex-shrink: 0;

                border-radius: 50%;
                overflow: hidden;

                border: 1px solid var(--blue, #599be4);
                background-color: #4f7387;
                animation: scale 2s infinite;
                &.play-list__item__header-img--widthout-border {
                  border: none;
                }
                // 这里添加左侧头部图片样式
              }
            }
          }
          @keyframes moveUpDown {
            0%, 100% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px);
            }
          }
          .body__center {
            position: relative;

            width: 31px;
            height: 42px;
            margin: 0 10px;
            animation: moveUpDown 2s infinite;
            .body__center__player {
              position: absolute;
              left: 50%;
              right: 0;
              bottom: 0;

              margin: auto;

              width: 40px;
              height: 40px;

              border-radius: 50%;
              border: 1px solid var(--blue, #599be4);
              background-color: #4f7387;
              transform: translate3d(-50%, 100%, 0);

              overflow: hidden;
            }
          }
          .body__right {
            position: relative;
            z-index: 1;
            flex: 1;
            flex-shrink: 0;

            @keyframes slide {
              0% {
                transform: translateX(50%);
              }
              100% {
                transform: translateX(20%);
              }
            }

            .body__right__bg-img {
              position: absolute;
              z-index: -1;
              bottom: -10px;
              width: 114px;
              height: 37px;
              animation: slide 1s infinite;
            }

            .body__right__play-list {
            }

            .body__right__play-list {
              display: flex;
              align-items: center;
              @keyframes scale {
                0%, 100% {
                  transform: scale(1);
                }
                50% {
                  transform: scale(1.3);
                }
              }
              .play-list__item__header-img {
                margin-right: 6px;

                width: 40px;
                height: 40px;
                flex-shrink: 0;

                border-radius: 50%;
                overflow: hidden;

                border: 1px solid var(--blue, #599be4);
                background-color: #4f7387;
                animation: scale 2s infinite;
                &.play-list__item__header-img--widthout-border {
                  border: none;
                }
                // 这里添加左侧头部图片样式
              }
            }
          }
        }
        .pk-card__content__footer-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .pk-card__content__footer-bar__left {
            display: flex;
            align-items: center;

            .box {
              position: relative;
              width: 46px;
              height: 46px;
              margin-right: 10px;

              .box__count {
                position: absolute;
                right: 0;
                top: 0;

                width: 16px;
                height: 16px;
                border-radius: 50%;
                line-height: 16px;
                text-align: center;

                background-color: #f8c544;

                color: var(--black1, #0d141a);
                font-family: "PingFang SC";
                font-size: 12px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
              }
            }
          }
          .pk-card__content__footer-bar__right {
            display: flex;
            align-items: center;

            .pk-card__content__footer-bar__right__money-icon {
              width: 16px;
              height: 16px;
            }
            .pk-card__content__footer-bar__right__price {
              margin-left: 4px;

              color: var(--gray4, #dde5e9);
              font-family: "PingFang SC";
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
</style>
