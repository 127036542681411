<template>
  <ModuleWrapper
    :values="roomDataRefact"
    title="参与人数"
    :titleSubInfoList="titleSubInfoList"
  >
    <div class="player-list">
      <el-row>
        <el-col
          :xs="4"
          :sm="4"
          :md="4"
          :lg="2"
          :xl="2"
          v-for="(item, index) in roomDataRefact.player_list"
          :key="index"
        >
          <div class="player-list__item">
            <div class="player-list__item__img">
              <img class="img-cover" :src="item.img" alt="" />
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </ModuleWrapper>
</template>

<script>
import ModuleWrapper from "../moduleWrapper/index.vue";

const titleSubInfoList = [
  {
    label: "共计",
    prop: "$playerCount",
    state: "人",
  },
];

export default {
  name: "playerModule",
  components: {
    ModuleWrapper,
  },
  props: {
    roomData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      titleSubInfoList,
    };
  },
  created() {},
  methods: {},
  computed: {
    roomDataRefact() {
      return {
        ...this.roomData,
        $playerCount: this.roomData.player_list.length,
      };
    },
  },
};
</script>

<style lang='less' scoped>
.player-list {
  .player-list__item {
    margin-bottom: 10px;

    .player-list__item__img {
      margin: auto;

      width: 48px;
      height: 48px;
      border-radius: 48px;
      overflow: hidden;
    }
  }
}
</style>
