<template>
  <transition name="fade" @after-leave="onClosed()">
    <div v-if="showDialog" class="game-play-dialog">
      <!-- // 特殊操作：禁止页面滚透 -->
      <div class="game-play-dialog__mask" @touchstart.prevent.stop=""></div>
      <div class="dialog__body">
        <div class="dialog__body__close-btn" @click="hideDialog"></div>

        <div class="dialog__body__title">玩法介绍</div>
        <div class="dialog__content">
          <div class="dialog__content__main">
            <div class="main-text">
              创建活动时，可以选择拼箱人数（2/3/4人），一个场次最多可以选择 6个宝箱
            </div>
            <div class="main-title">欧皇模式</div>
            <div class="main-text">
              1）多个玩家拼箱，开出最丰厚饰品的玩家获得优先分配。
            </div>
            <div class="main-text">
              2）当一场活动开出最丰厚饰品的玩家有多名时，将平分应当分配到的饰品将被回购为碎片发放到对应账户余额。
            </div>
            <div class="main-title">非酋模式</div>
            <div class="main-text">
              1）多个玩家拼箱，开出饰品总和最低的玩家获得优先分配。
            </div>
            <div class="main-text">
              2）当一场活动开出开出饰品总和最低的玩家有多名时，将平分应当分配到的饰品将被回购为碎片发放到对应账户余额。
            </div>
            <div class="main-title">2V2组队玩法</div>
            <div class="main-text">
              拼箱房间创建后，创建者默认选择的是的第一个位置，在拼箱开始前，其他玩家加入可自行选择空位。
              两队成员开箱所得饰品总和的进行比较，开出饰品总和较高的一方则获取相对应位置的饰品的优先分配。
              (由左到右一致为1号、2号、3号、4号，1号位对应3号位，2号位对应4号位)。
            </div>
            <div class="main-text">
              当出现平局时，玩家各自获得各自开出的饰品。
              房间一但创建，不可取消，如在10分钟内未有用户加入，则房间自动解散，归还房间对应币值。
            </div>
            <div class="main-text">
              活动参与费用即本场活动开启的宝箱碎片总和支付费用后，即视为加入房间，中途退出房间，并不会退还宝箱或参与费。
              优先分配会分配碎片较高的饰品，其他参与玩家会随机获得一件随机饰品。
            </div>
            <div class="main-text">
              每场活动的结果受到(创建时间，用户ID，用户位置和开始时间等)多方面因素影响，为完全随机产生的结果，游戏完全公平可证。
              可以在「我的-我的背包」中，查看获取的饰品饰品可以立即、兑换或取回;另外，请仔细查阅我们的充值规则，保持理性消费噢!
            </div>
          </div>
        </div>
        <div class="dialog__body__footer">
          <div class="dialog__body__footer__btn" @click="hideDialog">
            创建对战
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "gameplayIntroductionDialog",
  props: {
    onClosed: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      showDialog: false,
    };
  },
  created() {},
  mounted() {
    this.showDialog = true
  },
  methods: {
    hideDialog() {
      this.showDialog = false;
    },
  },
};
</script>

<style lang='less' scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 500ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.game-play-dialog {
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.7);


  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .game-play-dialog__mask{
    position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  }

  .dialog__body {
    position: relative;

    width: 315px;
    height: 507px;

    background-image: url("~@/assets/img/blindBox/describe-bg.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    display: flex;
    flex-direction: column;

    padding-bottom: 32px;

    .dialog__body__close-btn {
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      width: 36px;
      height: 36px;

      background-image: url("~@/assets/img/blindBox/close-btn.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      display: flex;
      flex-direction: column;
    }
    .dialog__body__title {
      padding: 20px 0;
      text-align: center;

      color: var(--gray4, #dde5e9);
      font-family: "Alibaba PuHuiTi";
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .dialog__content {
      flex-shrink: 0;

      flex: 1;
      // border: 1px solid red;
      overflow: hidden;
      margin: 0 20px;
      margin-bottom: 20px;

      .dialog__content__main {
        padding-right: 20px;
        margin-right: -20px;

        overflow-y: auto;
        height: 100%;

        .main-title {
          margin-bottom: 20px;

          color: var(--yellow, #f8c544);
          font-family: "PingFang SC";
          font-size: 13px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }

        .main-text {
          margin-bottom: 20px;

          color: var(--gray4, #dde5e9);
          font-family: "PingFang SC";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
    .dialog__body__footer {
      width: 148px;
      height: 44px;
      margin: auto;

      background-image: url("~@/assets/img/blindBox/rank-title-bg.png");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      color: var(--black1, #0d141a);
      text-align: center;
      font-family: "PingFang SC";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 44px;
    }
  }
}
</style>
